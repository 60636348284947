import React, { useContext, useEffect, useState } from 'react';

import { CloseOutlined, PlusCircleTwoTone } from '@ant-design/icons';
import {
    Button as AntdButton,
    Card,
    Divider,
    Drawer,
    Form,
    Input as AntdInput,
    Popconfirm,
    Space,
    Tooltip,
    Typography,
} from 'antd';
import { Link, navigate } from 'gatsby';
import moment from 'moment';
import LocalizedStrings from 'react-localization';
import store from 'store';
import styled from 'styled-components';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess } from '../utility';

const strings = new LocalizedStrings({
    en: {
        clientAdded: 'Client has been added',
        clientDeleted: 'Client has been deleted.',
        clientUpdated: 'Client info has been updated',
        filter: 'Filter clients by name, id and admin email',
        addClient: 'Add New Client',
        edit: 'Edit',
        view: 'View',
        add: 'Add',
        delete: 'Delete',
        save: 'Save',
        enterClientName: 'Please enter client name',
        enterClientDescription: 'Please enter client description',
        enterFirstName: 'Please enter admin first name',
        enterLastName: 'Please enter admin last name',
        enterEmail: 'Please enter admin email',
        enterPassword: 'Please enter admin password',
        adminEmail: 'Admin Email',
        adminLastName: 'Admin Last Name',
        adminFirstName: 'Admin First Name',
        adminPassword: 'Admin Password',
        description: 'Description',
        name: 'Name',
        editClient: 'Edit Client',
        id: 'ID',
        createdAt: 'Created At',
        cancel: 'Cancel',
        yes: 'Yes',
        no: 'No',
        sure: 'Are you sure you want to delete?',
    },
    de: {
        clientAdded: 'Kunde wurde hinzugefügt',
        clientDeleted: 'Kunde wurde gelöscht',
        clientUpdated: 'Kundeninformation wurde aktualisiert',
        filter: 'Kunden nach Namen, ID oder Admin-E-Mail-Adresse filtern',
        addClient: 'Neuen Kunden hinzufügen',
        edit: 'Bearbeiten',
        view: 'Ansehen',
        add: 'Hinzufügen',
        delete: 'Löschen',
        save: 'Speichern',
        enterClientName: 'Bitte Kundennamen eingeben',
        enterClientDescription: 'Bitte Kundenbeschreibung eingeben',
        enterFirstName: 'Bitte Vornamen des Admins eingeben',
        enterLastName: 'Bitte Nachnamen des Admins eingeben',
        enterEmail: 'Bitte E-Mail-Adresse des Admins eingeben',
        enterPassword: 'Bitte Passwort des Admins eingeben',
        adminEmail: 'E-Mail-Adresse Admin',
        adminLastName: 'Nachname Admin',
        adminFirstName: 'Vorname Admin',
        adminPassword: 'Passwort Admin',
        description: 'Beschreibung',
        name: 'Name',
        editClient: 'Kunden bearbeiten',
        id: 'ID',
        createdAt: 'Zeit der Schöpfung',
        cancel: 'Abbrechen',
        yes: 'Ja',
        no: 'Nein',
        sure: 'Sind Sie sicher, dass Sie löschen möchten?',
    },
});

const Input = styled(AntdInput)`
    height: 3rem;
`;

const Button = styled(AntdButton)`
    height: 3rem;
`;

export const Clients = () => {
    const context = useContext(GlobalContext);
    const [visibleAddClientDrawer, setVisibleAddClientDrawer] = useState(false);
    const [visibleEditClientDrawer, setVisibleEditClientDrawer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState('');
    const [addForm] = Form.useForm();
    const [editForm] = Form.useForm();

    useEffect(() => {
        const loggedInUser = store.get('user') as Users.User;
        if (loggedInUser?.roles?.includes('staff')) navigate('/event-list?clientId=' + loggedInUser.client?._id);
        setLoading(true);
        apiRequester
            .getClients()
            .then(clients => context.setClients(clients.length ? clients : []))
            .then(() => setLoading(false))
            .catch(handleError);
    }, []);

    const showAddClientDrawer = () => {
        setVisibleAddClientDrawer(true);
    };

    const onCloseAddClientDrawer = () => {
        setVisibleAddClientDrawer(false);
    };

    const showEditClientDrawer = (client: Clients.Client) => {
        editForm.setFieldsValue(client);
        setVisibleEditClientDrawer(true);
    };

    const onCloseEditClientDrawer = () => {
        setVisibleEditClientDrawer(false);
    };

    const onFinishAdding = async (client: {
        name: string;
        description?: string;
        adminFirstName: string;
        adminLastName: string;
        adminEmailId: string;
    }) => {
        try {
            setLoading(true);
            const response = await apiRequester.addClient(client);
            await context.refreshClients();
            setLoading(false);
            addForm.resetFields();
            onCloseAddClientDrawer();
            handleSuccess(strings.clientAdded!);
        } catch (err) {
            handleError(err);
            setLoading(false);
        }
    };

    const deleteClient = async (clientId: string) => {
        try {
            setLoading(true);
            await apiRequester.deleteClient(clientId!);
            await context.refreshClients();
            editForm.resetFields();
            handleSuccess(strings.clientDeleted!);
            setLoading(false);
        } catch (err) {
            handleError(err);
        }
    };

    const onFinishEditing = async (client: { _id: string; name: string; description?: string }) => {
        try {
            setLoading(true);
            const response = await apiRequester.editClient(client._id, {
                name: client.name,
                description: client.description,
            });
            await context.refreshClients();
            setLoading(false);
            editForm.resetFields();
            onCloseEditClientDrawer();
            handleSuccess(strings.clientUpdated!);
        } catch (err) {
            handleError(err);
            setLoading(false);
        }
    };

    const filterClientData = (clients: Clients.Client[]) => {
        if (!filter) return clients;
        else
            return clients.filter(client => {
                if (
                    client.name?.toLowerCase().includes(filter) ||
                    client._id?.toLowerCase().includes(filter) ||
                    client.admin?.emailId?.toLowerCase().includes(filter)
                )
                    return true;
                else return false;
            });
    };

    return (
        <>
            <div>
                <div style={{ padding: '0rem 4rem 0 4rem' }}>
                    <div style={{ display: 'flex' }}>
                        <Input
                            placeholder={strings.filter}
                            style={{
                                marginBottom: '1.5rem',
                                height: '3rem',
                                marginTop: '-1.5rem',
                                marginRight: '1.5rem',
                            }}
                            onChange={e => setFilter(e.target.value)}
                        />
                        <Button
                            type="dashed"
                            icon={<PlusCircleTwoTone />}
                            onClick={showAddClientDrawer}
                            style={{ height: '3rem', marginTop: '-1.5rem' }}
                        >
                            {strings.addClient}{' '}
                        </Button>
                    </div>
                    {loading && (
                        <div style={{ display: 'flex' }}>
                            <Card style={{ width: '50%' }} loading={true}></Card>
                        </div>
                    )}
                    {!loading && (
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                            }}
                        >
                            {filterClientData(context.clients).map((client, clientIndex) => {
                                return (
                                    <Card
                                        title={
                                            <Tooltip placement="right" title={`${strings.id}: ${client?._id}`}>
                                                {client.name}
                                            </Tooltip>
                                        }
                                        key={`client-${client._id}`}
                                        extra={[
                                            <AntdButton
                                                key="btn-1"
                                                type="link"
                                                onClick={() => showEditClientDrawer(client)}
                                            >
                                                {strings.edit}
                                            </AntdButton>,
                                            <AntdButton key="btn-2" type="primary">
                                                <Link to={`/event-list?clientId=${client._id}`}>{strings.view}</Link>
                                            </AntdButton>,
                                        ]}
                                        style={{ width: 'calc(50% - 0.75rem)', marginBottom: '1.5rem' }}
                                    >
                                        <Form>
                                            {/* <Form.Item
                                                label="ID"
                                                labelCol={{ span: 12 }}
                                                labelAlign="left"
                                                style={{ textAlign: 'right' }}
                                                colon={false}
                                            >
                                                {client._id}
                                            </Form.Item> */}
                                            <Form.Item
                                                label={strings.adminEmail}
                                                labelCol={{ span: 12 }}
                                                labelAlign="left"
                                                style={{ textAlign: 'right' }}
                                                colon={false}
                                            >
                                                {client.admin?.emailId}
                                            </Form.Item>
                                            <Form.Item
                                                label={strings.createdAt}
                                                labelCol={{ span: 12 }}
                                                labelAlign="left"
                                                style={{ textAlign: 'right' }}
                                                colon={false}
                                            >
                                                {moment(client.createdAt).fromNow()}
                                            </Form.Item>
                                            <Form.Item
                                                label={strings.description}
                                                labelCol={{ span: 12 }}
                                                labelAlign="left"
                                                style={{ textAlign: 'right' }}
                                                colon={false}
                                            >
                                                {client.description}
                                            </Form.Item>
                                        </Form>
                                    </Card>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
            <Drawer
                title={strings.addClient}
                placement="right"
                closable={false}
                onClose={onCloseAddClientDrawer}
                visible={visibleAddClientDrawer}
                width="620px"
                footer={
                    <Space>
                        <Button onClick={onCloseAddClientDrawer}>Cancel</Button>{' '}
                        <Button type="primary" loading={loading} onClick={addForm.submit}>
                            {strings.add}
                        </Button>
                    </Space>
                }
                footerStyle={{ textAlign: 'right', marginRight: '8px' }}
                closeIcon={<CloseOutlined />}
            >
                <Form form={addForm} onFinish={onFinishAdding}>
                    <Form.Item
                        label={strings.name}
                        name="name"
                        labelCol={{ span: 6 }}
                        rules={[{ required: true, message: strings.enterClientName }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label={strings.description}
                        labelCol={{ span: 6 }}
                        rules={[{ message: strings.description }]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Divider />
                    <Form.Item
                        label={strings.adminFirstName}
                        name="adminFirstName"
                        labelCol={{ span: 6 }}
                        rules={[{ required: true, message: strings.enterFirstName }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={strings.adminLastName}
                        name="adminLastName"
                        labelCol={{ span: 6 }}
                        rules={[{ required: true, message: strings.enterLastName }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={strings.adminEmail}
                        name="adminEmailId"
                        labelCol={{ span: 6 }}
                        rules={[{ required: true, message: strings.enterEmail }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={strings.adminPassword}
                        name="adminPassword"
                        labelCol={{ span: 6 }}
                        rules={[{ required: true, message: strings.enterPassword }]}
                    >
                        <Input.Password style={{ height: '3rem' }} />
                    </Form.Item>
                </Form>
            </Drawer>

            <Drawer
                title={strings.editClient}
                placement="right"
                closable={false}
                onClose={onCloseEditClientDrawer}
                visible={visibleEditClientDrawer}
                width="620px"
                footer={
                    <>
                        <Popconfirm
                            placement="topLeft"
                            title={strings.sure}
                            onConfirm={() => deleteClient(editForm.getFieldValue('_id'))}
                            okText={strings.yes}
                            cancelText={strings.no}
                        >
                            <Button danger style={{ marginLeft: '8px' }}>
                                {strings.delete}
                            </Button>
                        </Popconfirm>
                        <Space style={{ marginRight: '8px' }}>
                            <Button onClick={onCloseEditClientDrawer}>{strings.cancel}</Button>{' '}
                            <Button type="primary" htmlType="submit" loading={loading} onClick={editForm.submit}>
                                {strings.save}
                            </Button>
                        </Space>
                    </>
                }
                footerStyle={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
                closeIcon={<CloseOutlined />}
            >
                <Form form={editForm} onFinish={onFinishEditing}>
                    <Form.Item labelCol={{ span: 6 }} label="ID" name="_id" rules={[{ required: true }]} hidden={false}>
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                        label={strings.name}
                        name="name"
                        labelCol={{ span: 6 }}
                        rules={[{ required: true, message: strings.enterClientName }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label={strings.description}
                        labelCol={{ span: 6 }}
                        rules={[{ message: strings.enterClientDescription }]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Divider />
                    <Form.Item
                        name={['admin', 'firstName']}
                        label={strings.adminFirstName}
                        labelCol={{ span: 6 }}
                        rules={[{ message: strings.enterFirstName }]}
                    >
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                        name={['admin', 'lastName']}
                        label={strings.adminLastName}
                        labelCol={{ span: 6 }}
                        rules={[{ message: strings.enterLastName }]}
                    >
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                        name={['admin', 'emailId']}
                        label={strings.adminEmail}
                        labelCol={{ span: 6 }}
                        rules={[{ message: strings.enterEmail }]}
                    >
                        <Input disabled={true} />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

export default Clients;
