import React, { useEffect, useState } from 'react';

import { Button, Card, Col, Descriptions, Drawer, Form, Input, Row, Select, Space, Table, Typography } from 'antd';
import moment from 'moment';

import { apiRequester, handleError, handleSuccess } from '../utility';

const { Option } = Select;

export const ExternalFormModule = ({
    clientId,
    eventId,
    moduleId,
}: {
    clientId: string;
    eventId: string;
    moduleId: string;
}) => {
    const [loading, setLoading] = useState(false);
    const [forms, setForms] = useState<Modules.ExtFormModuleResponse[]>([]);

    const refreshResponses = async () => {
        try {
            setLoading(true);
            const responses = await apiRequester.getExtFormModulesResponses({ clientId, eventId, moduleId });
            setForms(responses);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        refreshResponses();
    }, [moduleId]);

    return (
        <>
            {forms.map(form => (
                <Card
                    style={{ width: '100%', marginBottom: '1.5rem' }}
                    key={form._id}
                    title={`Response ID: ${form._id}`}
                    size="small"
                >
                    <Descriptions bordered>
                        <Descriptions.Item label="Name">
                            {form.user?.firstName} {form.user?.lastName}
                        </Descriptions.Item>
                        <Descriptions.Item label="Email">{form.user?.emailId}</Descriptions.Item>
                        <Descriptions.Item label="Filled At">
                            {moment(form.createdAt).format('MMMM Do YYYY, h:mm a')}
                        </Descriptions.Item>
                    </Descriptions>
                    <div style={{ marginTop: '1.5rem' }}>
                        {form.responses?.map(response => (
                            <Row gutter={[24, 12]} key={response._id}>
                                <Col xs={24}>
                                    <Typography.Text strong>{response.question}</Typography.Text>
                                </Col>
                                <Col xs={24}>
                                    {response.answer_arr && response.answer_arr.length ? (
                                        <ul>
                                            {response.answer_arr.map((ans: string) => (
                                                <li key={`${response.questionId}-index`}>{ans}</li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <></>
                                    )}
                                    {response.answer_obj && (
                                        <ul>
                                            {Object.keys(response.answer_obj).map((key: string, index: number) => (
                                                <li key={`${response.questionId}-index`}>
                                                    {key}: {response.answer_obj![key]}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </Col>
                            </Row>
                        ))}
                    </div>
                </Card>
            ))}
        </>
    );
};

export default ExternalFormModule;
