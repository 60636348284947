export enum NOTIFIER_MESSAGE_IDS {
    VIDEO_CALL = 'VIDEO_CALL',
    CHAT = 'CHAT',
    CHAT_UPDATED = 'CHAT_UPDATED',
    LIVE_STREAMING = 'LIVE_STREAMING',
    LIVE_STREAMING_SPEAKER_REQUEST = 'LIVE_STREAMING_SPEAKER_REQUEST',
}

export enum VIDEO_CALL_ACTIONS {
    NEW_REQUEST_FROM_OPERATOR_TO_VISITOR = 'NEW_REQUEST_FROM_OPERATOR_TO_VISITOR',
    NEW_REQUEST_FROM_VISITOR_TO_OPERATOR = 'NEW_REQUEST_FROM_VISITOR_TO_OPERATOR',
    OPERATOR_JOINED = 'OPERATOR_JOINED',
    OPERATOR_LEFT = 'OPERATOR_LEFT',
    OPERATOR_REJECTED = 'OPERATOR_REJECTED',
    OPERATOR_ACCEPTED = 'OPERATOR_ACCEPTED',
    OPERATOR_ENDED_FOR_ALL = 'OPERATOR_ENDED_FOR_ALL',
    VISITOR_ACCEPTED = 'VISITOR_ACCEPTED',
    VISITOR_REJECTED = 'VISITOR_REJECTED',
    VISITOR_ENDED = 'VISITOR_ENDED',
}

export enum CHAT_ACTIONS {
    CHAT_UPDATED = 'CHAT_UPDATED',
    NEW_MESSAGE = 'NEW_MESSAGE',
}

export enum LIVE_STREAM_ACTIONS {
    QUESTION_APPROVED = 'QUESTION_APPROVED',
    NEW_QUESTION = 'NEW_QUESTION',
}

export enum LIVE_STREAM_SPEAKER_ACTIONS {
    NEW_LIVE_STREAMING_SPEAKER_REQUEST = 'NEW_LIVE_STREAMING_SPEAKER_REQUEST',
    LIVE_STREAMING_SPEAKER_REQUEST_ACCEPT = 'LIVE_STREAMING_SPEAKER_REQUEST_ACCEPT',
    LIVE_STREAMING_SPEAKER_REQUEST_REJECT = 'LIVE_STREAMING_SPEAKER_REQUEST_REJECT',
    LIVE_STREAMING_SPEAKER_REQUEST_DELETE = 'LIVE_STREAMING_SPEAKER_REQUEST_DELETE',
}

export const generateNotifierMessage = {
    videoRequest: ({
        booth,
        requester,
        respondent,
        action,
    }: {
        booth: {
            id: string;
            name?: string;
        };
        requester?: {
            id: string;
            firstName?: string;
            lastName?: string;
        };
        respondent?: {
            id: string;
            firstName?: string;
            lastName?: string;
        };
        action: VIDEO_CALL_ACTIONS;
    }) => {
        return {
            id: NOTIFIER_MESSAGE_IDS.VIDEO_CALL,
            booth,
            requester,
            respondent,
            action,
        };
    },
    chat: ({
        booth,
        action,
        visitor,
    }: {
        booth?: {
            id: string;
            name?: string;
        };
        action?: CHAT_ACTIONS;
        visitor?: { id: string };
    }) => {
        return {
            id: NOTIFIER_MESSAGE_IDS.CHAT,
            booth,
            action,
            visitor,
        };
    },
    liveStreamQuestion: ({
        booth,
        action,
    }: {
        booth?: {
            id: string;
            name?: string;
        };
        action?: LIVE_STREAM_ACTIONS;
    }) => {
        return {
            id: NOTIFIER_MESSAGE_IDS.LIVE_STREAMING,
            booth,
            action,
        };
    },
    newLiveStreamSpeakerRequest: ({
        module,
        booth,
        action,
    }: {
        module: {
            id: string;
            name: string;
        };
        booth: {
            id: string;
            name: string;
        };
        action?: LIVE_STREAM_SPEAKER_ACTIONS;
    }) => {
        return {
            id: NOTIFIER_MESSAGE_IDS.LIVE_STREAMING_SPEAKER_REQUEST,
            booth,
            module,
            action,
        };
    },
};
