import React, { useContext, useEffect, useState } from 'react';

import {
    CheckOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    CloseOutlined,
    PlayCircleOutlined,
    TeamOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Dropdown, Menu, Row, Tag, Typography, Form, Select, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import { usePubNub } from 'pubnub-react';
import LocalizedStrings from 'react-localization';
import store from 'store';

import { GlobalContext } from '../context/GlobalContextProvider';
import { handleError, getResourceId, apiRequester, handleSuccess } from '../utility';
import { generateNotifierMessage, VIDEO_CALL_ACTIONS } from '../utility/NotifierMessages';

const strings = new LocalizedStrings({
    en: {
        roomCreated: 'Video room has been created',
        userAdded: 'User has been added to the room',
        privateMeeting: 'Start Private Meeting Room',
        privateBroadcast: 'Start Private Broadcast Room',
        groupPrivateMeeting: 'Start Group Meeting Room',
        groupBroadcast: 'Start Group Broadcast Room',
        existingGroup: 'Add to existing group',
        callRejected: 'Video call request has been rejected',
        callEnded: 'Video call has been ended',
        callAccepted: 'Video call has been accepted',
        accept: 'Accept',
        reject: 'Reject',
        cancel: 'Cancel',
        operator: 'Operator',
        visitor: 'Visitor',
        add: 'Add',
        scheduled: 'Scheduled',
        quickStart: 'Quick Start',
        roomStarting: 'Your video room is starting',
    },
    de: {
        roomCreated: 'Der Videoanruf wurde erstellt.',
        userAdded: 'Benutzer wurde dem Raum hinzugefügt',
        privateMeeting: 'Privaten Meetingraum erstellen',
        privateBroadcast: 'Start Private Broadcast Room',
        groupPrivateMeeting: 'Gruppen-Meetingraum erstellen',
        groupBroadcast: 'Broadcast starten',
        existingGroup: 'Zu einer bestehenden Gruppe hinzufügen',
        callRejected: 'Videoanruf-Anfrage wurde abgelehnt',
        callEnded: 'Videoanruf wurde beendet',
        callAccepted: 'Videoanruf wurde akzeptiert',
        accept: 'Akzeptieren',
        reject: 'Ablehnen',
        cancel: 'Abbrechen',
        operator: 'Benutzer',
        visitor: 'Besucher',
        add: 'Hinzufügen',
        scheduled: 'Geplant',
        quickStart: 'Quick Start',
        roomStarting: 'Ihr Videoanruf beginnt',
    },
});

export const VideoCallRequestCard = ({
    rooms,
    item,
    refreshVideoCallRequestList,
}: {
    rooms: Modules.VideoRoom[];
    item: Modules.VideoCallRequest;
    refreshVideoCallRequestList: () => void;
}) => {
    const context = useContext(GlobalContext);
    const { _id: requestId, booth, module, visitor } = item;
    const visitorId = getResourceId(visitor);
    const client = booth?.client;
    const event = booth?.event;
    const boothId = getResourceId(booth) || context.activeBooth?._id?.toString();
    const eventId = getResourceId(event) || context.activeEvent?._id?.toString();
    const moduleId = getResourceId(module);
    const clientId = getResourceId(client);
    const [loading, setLoading] = useState(false);
    const boothVisitorsChannel = `booth-visitors.${boothId}`;
    const boothOperatorsChannel = `booth-operators.${boothId}`;
    const pubnub = usePubNub();
    let loggedInUser: Users.User;
    const [addToRoom, setAddToRoom] = useState(false);
    const [addToScheduleModal, setAddToScheduleModal] = useState(false);
    const [addToRoomForm] = useForm();

    useEffect(() => {
        loggedInUser = store.get('user');
    }, []);

    const createVideoCallRoom = async ({
        isGroup = false,
        isBroadcast = false,
    }: {
        isGroup: boolean;
        isBroadcast: boolean;
    }) => {
        try {
            setLoading(true);
            await apiRequester.createVideoRoom({
                clientId: clientId!,
                eventId: eventId!,
                boothId: boothId!,
                moduleId: moduleId!,
                requestId: requestId!,
                isBroadcast,
                isGroup,
            });
            await refreshVideoCallRequestList();
            await pubnub.publish({
                channel: `users.${visitorId}`,
                message: generateNotifierMessage.videoRequest({
                    booth: {
                        id: boothId!,
                        name: context.activeBooth?.name,
                    },
                    requester: {
                        id: context.user?._id!,
                        firstName: context.user?.firstName,
                        lastName: context.user?.lastName,
                    },
                    respondent: {
                        id: visitor?._id!,
                    },
                    action: VIDEO_CALL_ACTIONS.NEW_REQUEST_FROM_OPERATOR_TO_VISITOR,
                }),
            });
            await pubnub.publish({
                channel: boothOperatorsChannel,
                message: generateNotifierMessage.videoRequest({
                    booth: {
                        id: boothId!,
                        name: context.activeBooth?.name,
                    },
                    requester: {
                        id: context.user?._id!,
                        firstName: context.user?.firstName,
                        lastName: context.user?.lastName,
                    },
                    respondent: {
                        id: visitor?._id!,
                    },
                    action: VIDEO_CALL_ACTIONS.NEW_REQUEST_FROM_OPERATOR_TO_VISITOR,
                }),
            });
            handleSuccess(strings.roomCreated!);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const addVisitorToRoom = async ({ roomId }: { roomId: string }) => {
        try {
            setLoading(true);
            await apiRequester.addVisitorToRoom({
                clientId: clientId!,
                eventId: eventId!,
                boothId: boothId!,
                moduleId: moduleId!,
                requestId: requestId!,
                roomId: roomId,
            });
            await refreshVideoCallRequestList();
            setAddToRoom(false);
            addToRoomForm.resetFields();
            await pubnub.publish({
                channel: `users.${visitorId}`,
                message: generateNotifierMessage.videoRequest({
                    booth: {
                        id: boothId!,
                        name: context.activeBooth?.name,
                    },
                    requester: {
                        id: context.user?._id!,
                        firstName: context.user?.firstName,
                        lastName: context.user?.lastName,
                    },
                    respondent: {
                        id: visitor?._id!,
                    },
                    action: VIDEO_CALL_ACTIONS.NEW_REQUEST_FROM_OPERATOR_TO_VISITOR,
                }),
            });
            await pubnub.publish({
                channel: boothOperatorsChannel,
                message: generateNotifierMessage.videoRequest({
                    booth: {
                        id: boothId!,
                        name: context.activeBooth?.name,
                    },
                    requester: {
                        id: context.user?._id!,
                        firstName: context.user?.firstName,
                        lastName: context.user?.lastName,
                    },
                    respondent: {
                        id: visitor?._id!,
                    },
                    action: VIDEO_CALL_ACTIONS.NEW_REQUEST_FROM_OPERATOR_TO_VISITOR,
                }),
            });
            handleSuccess(strings.userAdded!);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const one2oneMenu = (
        <Menu>
            <Menu.Item onClick={() => createVideoCallRoom({ isGroup: false, isBroadcast: false })} disabled={loading}>
                {strings.privateMeeting}
            </Menu.Item>
        </Menu>
    );

    const one2ManyMenu = (
        <Menu>
            <Menu.Item onClick={() => createVideoCallRoom({ isGroup: true, isBroadcast: false })} disabled={loading}>
                {strings.groupPrivateMeeting}
            </Menu.Item>
            <Menu.Item
                onClick={() => setAddToRoom(true)}
                testing-id="add-to-existing-group-btn"
                disabled={loading}
                requester-email={visitor?.emailId}
            >
                {strings.existingGroup}
            </Menu.Item>
        </Menu>
    );

    const rejectVideoCallRequest = async () => {
        try {
            setLoading(true);
            await apiRequester.rejectVideoCallRequest({
                clientId: clientId!,
                eventId: eventId!,
                boothId: boothId!,
                moduleId: moduleId!,
                requestId: requestId!,
            });
            await refreshVideoCallRequestList();
            await pubnub.publish({
                channel: `users.${visitorId}`,
                message: generateNotifierMessage.videoRequest({
                    booth: {
                        id: boothId!,
                        name: context.activeBooth?.name,
                    },
                    respondent: {
                        id: context.user?._id!,
                        firstName: context.user?.firstName,
                        lastName: context.user?.lastName,
                    },
                    action: VIDEO_CALL_ACTIONS.OPERATOR_REJECTED,
                }),
            });
            await pubnub.publish({
                channel: boothOperatorsChannel,
                message: generateNotifierMessage.videoRequest({
                    booth: {
                        id: boothId!,
                        name: context.activeBooth?.name,
                    },
                    requester: {
                        id: visitor?._id!,
                        firstName: visitor?.firstName,
                        lastName: visitor?.lastName,
                    },
                    respondent: {
                        id: loggedInUser?._id!,
                        firstName: loggedInUser?.firstName,
                        lastName: loggedInUser?.lastName,
                    },
                    action: VIDEO_CALL_ACTIONS.OPERATOR_REJECTED,
                }),
            });
            handleSuccess(strings.callRejected!);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const endVideoCallRequest = async () => {
        try {
            setLoading(true);
            await apiRequester.endVideoCallRequest({
                clientId: clientId!,
                eventId: eventId!,
                boothId: boothId!,
                moduleId: moduleId!,
                requestId: requestId!,
            });
            await refreshVideoCallRequestList();
            await pubnub.publish({
                channel: `users.${visitorId}`,
                message: generateNotifierMessage.videoRequest({
                    booth: {
                        id: boothId!,
                        name: context.activeBooth?.name,
                    },
                    requester: {
                        id: visitor?._id!,
                        firstName: visitor?.firstName,
                        lastName: visitor?.lastName,
                    },
                    respondent: {
                        id: loggedInUser?._id!,
                        firstName: loggedInUser?.firstName,
                        lastName: loggedInUser?.lastName,
                    },
                    action: VIDEO_CALL_ACTIONS.OPERATOR_LEFT,
                }),
            });
            await pubnub.publish({
                channel: boothOperatorsChannel,
                message: generateNotifierMessage.videoRequest({
                    booth: {
                        id: boothId!,
                        name: context.activeBooth?.name,
                    },
                    requester: {
                        id: visitor?._id!,
                        firstName: visitor?.firstName,
                        lastName: visitor?.lastName,
                    },
                    respondent: {
                        id: loggedInUser?._id!,
                        firstName: loggedInUser?.firstName,
                        lastName: loggedInUser?.lastName,
                    },
                    action: VIDEO_CALL_ACTIONS.OPERATOR_LEFT,
                }),
            });
            handleSuccess(strings.callEnded!);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const acceptVideoCallRequest = async () => {
        try {
            setLoading(true);
            await apiRequester.acceptVideoCallRequest({
                clientId: clientId!,
                eventId: eventId!,
                boothId: boothId!,
                moduleId: moduleId!,
                requestId: requestId!,
            });
            await refreshVideoCallRequestList();
            await pubnub.publish({
                channel: `users.${visitorId}`,
                message: generateNotifierMessage.videoRequest({
                    booth: {
                        id: boothId!,
                        name: context.activeBooth?.name,
                    },
                    respondent: {
                        id: context.user?._id!,
                        firstName: context.user?.firstName,
                        lastName: context.user?.lastName,
                    },
                    action: VIDEO_CALL_ACTIONS.OPERATOR_ACCEPTED,
                }),
            });
            await pubnub.publish({
                channel: boothOperatorsChannel,
                message: generateNotifierMessage.videoRequest({
                    booth: {
                        id: boothId!,
                        name: context.activeBooth?.name,
                    },
                    requester: {
                        id: visitor?._id!,
                        firstName: visitor?.firstName,
                        lastName: visitor?.lastName,
                    },
                    respondent: {
                        id: loggedInUser?._id!,
                        firstName: loggedInUser?.firstName,
                        lastName: loggedInUser?.lastName,
                    },
                    action: VIDEO_CALL_ACTIONS.OPERATOR_ACCEPTED,
                }),
            });
            handleSuccess(strings.callAccepted!);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const quickStartVideoCallRequest = async () => {
        try {
            setLoading(true);

            // Accept request
            await apiRequester.acceptVideoCallRequest({
                clientId: clientId!,
                eventId: eventId!,
                boothId: boothId!,
                moduleId: moduleId!,
                requestId: requestId!,
            });

            // Create room
            let room = await apiRequester.createVideoRoom({
                clientId: clientId!,
                eventId: eventId!,
                boothId: boothId!,
                moduleId: moduleId!,
                requestId: requestId!,
                isBroadcast: false,
                isGroup: false,
            });

            // Request access to room
            const roomId = room._id;
            await apiRequester.joinVideoRoom({
                clientId: clientId!,
                eventId: eventId!,
                boothId: boothId!,
                moduleId: moduleId!,
                roomId: roomId!,
            });

            const rooms = await apiRequester.viewVideoRooms({
                clientId: clientId!,
                eventId: eventId!,
                boothId: boothId!,
                moduleId: moduleId!,
            });
            room = rooms.find(r => r._id === roomId)!;

            // Join room
            context.setActiveVideoRoom(room);

            // Refresh list
            await refreshVideoCallRequestList();

            // Notify users
            await pubnub.publish({
                channel: `users.${visitorId}`,
                message: generateNotifierMessage.videoRequest({
                    booth: {
                        id: boothId!,
                        name: context.activeBooth?.name,
                    },
                    respondent: {
                        id: context.user?._id!,
                        firstName: context.user?.firstName,
                        lastName: context.user?.lastName,
                    },
                    action: VIDEO_CALL_ACTIONS.OPERATOR_ACCEPTED,
                }),
            });
            await pubnub.publish({
                channel: boothOperatorsChannel,
                message: generateNotifierMessage.videoRequest({
                    booth: {
                        id: boothId!,
                        name: context.activeBooth?.name,
                    },
                    requester: {
                        id: visitor?._id!,
                        firstName: visitor?.firstName,
                        lastName: visitor?.lastName,
                    },
                    respondent: {
                        id: loggedInUser?._id!,
                        firstName: loggedInUser?.firstName,
                        lastName: loggedInUser?.lastName,
                    },
                    action: VIDEO_CALL_ACTIONS.OPERATOR_ACCEPTED,
                }),
            });
            handleSuccess(strings.callAccepted!);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const getActionBar = () => {
        switch (item.status) {
            case 'requested':
                return item.requester === 'operator'
                    ? [
                          <Button
                              key={`end-call-btn-${requestId}`}
                              type="link"
                              danger
                              onClick={endVideoCallRequest}
                              loading={loading}
                              size="small"
                          >
                              {strings.cancel}
                          </Button>,
                      ]
                    : [
                          <Button
                              key={`reject-call-btn-${requestId}`}
                              type="link"
                              danger
                              onClick={rejectVideoCallRequest}
                              loading={loading}
                              size="small"
                              icon={<CloseOutlined />}
                          >
                              {strings.reject}
                          </Button>,
                          <Button
                              key={`accept-call-btn-${requestId}`}
                              type="link"
                              onClick={acceptVideoCallRequest}
                              loading={loading}
                              size="small"
                              icon={<CheckOutlined />}
                              testing-id="accept-video-call-btn"
                              requester-email={visitor?.emailId}
                              is-loading={JSON.stringify(loading)}
                          >
                              {strings.accept}
                          </Button>,
                          <Button
                              key={`quick-start-call-btn-${requestId}`}
                              type="link"
                              onClick={quickStartVideoCallRequest}
                              loading={loading}
                              size="small"
                              icon={<PlayCircleOutlined />}
                          >
                              {strings.quickStart}
                          </Button>,
                      ];
            case 'rejected':
            case 'ended':
                return [<>-</>];
            default:
                return [
                    <Dropdown overlay={one2oneMenu} key={`one2oneMenu`}>
                        <UserOutlined />
                    </Dropdown>,
                    <Dropdown overlay={one2ManyMenu} key={`one2ManyMenu`} trigger={['click']}>
                        <TeamOutlined
                            name="video-call-group-functions-btn"
                            testing-id="video-call-group-functions-btn"
                            requester-email={visitor?.emailId}
                        />
                    </Dropdown>,
                    // <ClockCircleOutlined key={`scheduleRequest`} onClick={() => setAddToScheduleModal(true)} />,
                    <CloseOutlined onClick={endVideoCallRequest} key={`endVideoCallRequest`} />,
                ];
        }
    };

    return (
        <>
            <Card
                key={`request-${requestId}`}
                actions={loading ? [] : getActionBar()}
                title={moment(item.createdAt).fromNow()}
                extra={<Tag>{item.status?.toUpperCase()}</Tag>}
                size="small"
            >
                <Row gutter={16} style={{ textAlign: 'center' }}>
                    <Col xs={12}>
                        <Card title="From" size="small" style={{ width: '100%' }} bordered={false}>
                            {item.requester === 'operator' ? (
                                <>
                                    <Typography.Text>
                                        {typeof item.operator !== 'string'
                                            ? item.operator?.firstName
                                                ? item.operator?.firstName + ' ' + item.operator?.lastName
                                                : 'Anonymous User'
                                            : ''}
                                    </Typography.Text>
                                    <br />
                                    <Tag>{strings.operator}</Tag>
                                </>
                            ) : (
                                <>
                                    <Typography.Text>
                                        {typeof item.visitor !== 'string'
                                            ? item.visitor?.firstName
                                                ? item.visitor?.firstName + ' ' + item.visitor?.lastName
                                                : 'Anonymous User'
                                            : ''}
                                    </Typography.Text>
                                    <br />
                                    <Tag>{strings.visitor}</Tag>
                                </>
                            )}
                        </Card>
                    </Col>
                    {item.requester === 'operator' ? (
                        <Col xs={12}>
                            <Card title="To" size="small" bordered={false}>
                                <Typography.Text>
                                    {typeof item.visitor !== 'string'
                                        ? item.visitor?.firstName
                                            ? item.visitor?.firstName + ' ' + item.visitor?.lastName
                                            : 'Anonymous User'
                                        : ''}
                                </Typography.Text>
                                <br />
                                <Tag>{strings.visitor}</Tag>
                            </Card>
                        </Col>
                    ) : (
                        <></>
                    )}
                </Row>
            </Card>
            <Modal
                title="Select existing room to add visitor"
                visible={addToRoom}
                onCancel={() => setAddToRoom(false)}
                destroyOnClose={true}
                footer={
                    <Button
                        type="primary"
                        onClick={() => {
                            addToRoomForm.submit();
                        }}
                        loading={loading}
                        testing-id="add-visitor-to-room-submit-btn"
                    >
                        {strings.add}
                    </Button>
                }
            >
                <Form form={addToRoomForm} onFinish={addVisitorToRoom}>
                    <Form.Item name="roomId" rules={[{ required: true }]}>
                        <Select placeholder="Select room">
                            {rooms.map(room => (
                                <Select.Option key={room?._id!} value={room?._id!}>
                                    <span room-name={room?.name}>
                                        {room?.name} {room?.isScheduled && <Tag color="orange">Scheduled</Tag>}
                                    </span>
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Add to scheduled room"
                visible={addToScheduleModal}
                onCancel={() => setAddToScheduleModal(false)}
                footer={
                    <Button type="primary" loading={loading}>
                        {strings.add}
                    </Button>
                }
            >
                <Form form={addToRoomForm}>
                    <Form.Item name="roomId" rules={[{ required: true }]}>
                        <Select placeholder="Select room">
                            {rooms.map(room => (
                                <Select.Option key={room?._id!} value={room?._id!}>
                                    {room?.name} {room?.isScheduled && <Tag color="orange">{strings.scheduled}</Tag>}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default VideoCallRequestCard;
