import React, { useContext, useEffect, useState } from 'react';

import { ArrowDownOutlined, ArrowUpOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, List, Popconfirm, Popover, Select, Space, Switch } from 'antd';
import LocalizedStrings from 'react-localization';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess } from '../utility';
import { MultiLevelSelectOptionInput } from './MultiLevelSelectOptionInput';

const strings = new LocalizedStrings({
    en: {
        deleted: 'User profile field has been deleted',
        updated: 'User profile field has been updated',
        question: 'Question',
        missingQuestion: 'Missing question',
        type: 'Type',
        missingType: 'Missing type',
        input: 'Text Input',
        textArea: 'Paragraph',
        dropDown: 'Dropdown',
        picture: 'Picture',
        options: 'Options',
        atRegistration: 'Ask at Registration',
        missingRegistrationValue: 'Missing value for show on registration page',
        showOnProfile: 'Show on User Profile',
        missingShowOnProfile: 'Missing value for show on user profile',
        required: 'Mandatory',
        missingRequired: 'Missing value for required',
        actions: 'Actions',
        newField: 'New Field',
        fieldCreated: 'New user profile field has been created',
        addField: 'Add Field',
        checkbox: 'Check Box',
        boothTree: 'Booth Tree',
        ranking: 'Ranking',
        phone: 'Phone',
        countryCity: 'Country City',
        yes: 'Yes',
        no: 'No',
        sure: 'Are you sure you want to delete?',
    },
    de: {
        deleted: 'Benutzerprofil wurde gelöscht',
        updated: 'Benutzerprofil wurde aktualisiert',
        question: 'Frage',
        missingQuestion: 'Frage fehlt',
        type: 'Typ',
        missingType: 'Typ fehlt',
        input: 'Texteingabe',
        textArea: 'Absatz',
        dropDown: 'Dropdown',
        picture: 'Bild',
        options: 'Optionen',
        atRegistration: 'Bei der Registrierung fragen',
        missingRegistrationValue: 'Fehlender Wert für das Event auf der Registrierungsseite',
        showOnProfile: 'Beim Benutzerprofil anzeigen',
        missingShowOnProfile: 'Fehlender Wert für das Event im Benutzerprofil',
        required: 'Verpflichtend',
        missingRequired: 'Fehlende Angabe im Pflichtfeld',
        actions: 'Aktionen',
        newField: 'Neues Feld',
        fieldCreated: 'Neues Benutzerprofil wurde angelegt',
        addField: 'Feld hinzufügen',
        checkbox: 'Check Box',
        boothTree: 'Booth Tree',
        ranking: 'Rangfolge',
        phone: 'Telefon',
        countryCity: 'Land Stadt',
        yes: 'Ja',
        no: 'Nein',
        sure: 'Sind Sie sicher, dass Sie löschen möchten?',
    },
});

const FieldForm = ({ field, refreshFields }: { field: Users.ExtraField; refreshFields: () => Promise<void> }) => {
    const context = useContext(GlobalContext);
    const [editForm] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const eventId = context.activeEvent?._id;
    const clientId = context.activeEvent?.client?._id;
    const [dropDownOptions, setDropDownOptions] = useState<any>([]);

    useEffect(() => {
        editForm.setFieldsValue(field);
    }, [field]);

    const deleteExtraUserProfileField = async () => {
        try {
            setLoading(true);
            const fieldId = editForm.getFieldValue('_id');
            await apiRequester.deleteUserExtraProfileField(clientId!, eventId!, fieldId!);
            await context.refreshActiveEvent(context.activeEvent?.client?._id!, context.activeEvent?._id!);
            handleSuccess(strings.deleted!);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const updateExtraUserProfileField = async () => {
        try {
            setLoading(true);
            const formValues = { ...editForm.getFieldsValue(), data: { optionTree: dropDownOptions } };
            await apiRequester.updateUserExtraProfileField(
                clientId!,
                eventId!,
                editForm.getFieldValue('_id'),
                formValues,
            );
            await context.refreshActiveEvent(context.activeEvent?.client?._id!, context.activeEvent?._id!);
            handleSuccess(strings.updated!);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form form={editForm}>
            <Form.Item
                name="_id"
                rules={[
                    {
                        required: true,
                        message: strings.missingType,
                    },
                ]}
                hidden={true}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={strings.question}
                name="question"
                rules={[
                    {
                        required: true,
                        message: strings.missingQuestion,
                    },
                ]}
                labelCol={{ span: 4 }}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={strings.type}
                name="type"
                rules={[
                    {
                        required: true,
                        message: strings.missingType,
                    },
                ]}
                labelCol={{ span: 4 }}
            >
                <Select
                    onChange={(value: string) => {
                        editForm.setFieldsValue({
                            type: value,
                        });
                    }}
                >
                    <Select.Option key="input" value="input">
                        {strings.input}
                    </Select.Option>
                    <Select.Option key="textarea" value="textarea">
                        {strings.textArea}
                    </Select.Option>
                    <Select.Option key="select" value="select">
                        {strings.dropDown}
                    </Select.Option>
                    <Select.Option key="picture" value="picture">
                        {strings.picture}
                    </Select.Option>
                    <Select.Option key="checkbox" value="checkbox">
                        {strings.checkbox}
                    </Select.Option>
                    <Select.Option key="booth-tree" value="booth-tree">
                        {strings.boothTree}
                    </Select.Option>
                    <Select.Option key="phone" value="phone">
                        {strings.phone}
                    </Select.Option>
                    <Select.Option key="country-city" value="country-city">
                        {strings.countryCity}
                    </Select.Option>
                </Select>
            </Form.Item>
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => {
                    return prevValues.options !== curValues.type;
                }}
            >
                {({ getFieldValue }) => {
                    return (
                        <>
                            {['select'].includes(getFieldValue('type')) && (
                                <Form.Item labelCol={{ span: 4 }} label={strings.options} required={true}>
                                    <MultiLevelSelectOptionInput
                                        setDropDownOptions={setDropDownOptions}
                                        defaultOptionTree={getFieldValue('data')}
                                    />
                                </Form.Item>
                            )}

                            {/* {['select'].includes(getFieldValue('type')) && (
                                <Form.Item labelCol={{ span: 4 }} label={strings.options} required={true}>
                                    <Form.List name={['data', 'options']}>
                                        {(fields, { add, remove }, { errors }) => (
                                            <Space wrap={true} align="baseline">
                                                {fields.map(field => {
                                                    return (
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'title']}
                                                            fieldKey={[field.fieldKey, 'title']}
                                                            key={`field-key-${field.name}`}
                                                        >
                                                            <Input
                                                                suffix={
                                                                    <DeleteOutlined
                                                                        onClick={() => remove(field.name)}
                                                                    />
                                                                }
                                                            />
                                                        </Form.Item>
                                                    );
                                                })}
                                                <Button
                                                    onClick={() => add()}
                                                    type="dashed"
                                                    shape="circle"
                                                    size="small"
                                                    icon={<PlusOutlined />}
                                                />
                                            </Space>
                                        )}
                                    </Form.List>
                                </Form.Item>
                            )} */}
                        </>
                    );
                }}
            </Form.Item>
            <Form.Item
                label={strings.atRegistration}
                name={['showWhileRegistration']}
                fieldKey={['showWhileRegistration']}
                key={`showWhileRegistration`}
                rules={[
                    {
                        required: true,
                        message: strings.missingRegistrationValue,
                    },
                ]}
                labelCol={{ span: 4 }}
                valuePropName="checked"
                initialValue={false}
            >
                <Switch />
            </Form.Item>
            <Form.Item
                label={strings.showOnProfile}
                name={['showOnProfile']}
                fieldKey={['showOnProfile']}
                key={`showOnProfile`}
                rules={[
                    {
                        required: true,
                        message: strings.missingShowOnProfile,
                    },
                ]}
                labelCol={{ span: 4 }}
                valuePropName="checked"
                initialValue={false}
            >
                <Switch />
            </Form.Item>
            <Form.Item
                label={strings.required}
                name={['required']}
                fieldKey={['required']}
                key={`required`}
                rules={[
                    {
                        required: true,
                        message: strings.missingRequired,
                    },
                ]}
                labelCol={{ span: 4 }}
                valuePropName="checked"
                initialValue={false}
            >
                <Switch />
            </Form.Item>
            <Form.Item label={strings.actions} labelCol={{ span: 4 }}>
                <Space>
                    <Popconfirm
                        placement="topLeft"
                        title={strings.sure}
                        onConfirm={deleteExtraUserProfileField}
                        okText={strings.yes}
                        cancelText={strings.no}
                    >
                        <Button shape="circle" danger loading={loading} icon={<DeleteOutlined />} />
                    </Popconfirm>
                    <Button
                        shape="circle"
                        onClick={updateExtraUserProfileField}
                        loading={loading}
                        icon={<SaveOutlined />}
                    />
                </Space>
            </Form.Item>
            <Form.Item label={strings.ranking} labelCol={{ span: 4 }} name="ranking">
                <Input type="number" />
            </Form.Item>
        </Form>
    );
};

export const ExtraUserProfileFields = () => {
    const context = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const [extraUserProfileFields, setExtraUserProfileFields] = useState<Users.ExtraField[]>([]);
    const eventId = context.activeEvent?._id;
    const clientId = context.activeEvent?.client?._id;

    const refreshFields = async () => {
        if (clientId && eventId) {
            const fields = await apiRequester.getUserExtraProfileFields(clientId, eventId);
            setExtraUserProfileFields(fields.reverse());
        }
    };

    useEffect(() => {
        refreshFields().catch(handleError);
    }, [context.activeEvent]);

    const addExtraUserProfileField = async () => {
        try {
            setLoading(true);
            await apiRequester.createUserExtraProfileField(clientId!, eventId!, {
                question: strings.newField,
                type: 'input',
                required: false,
                showWhileRegistration: true,
                showOnProfile: true,
            });
            await refreshFields();
            setLoading(false);
            handleSuccess(strings.fieldCreated!);
        } catch (err) {
            setLoading(false);
            handleError(err);
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ textAlign: 'right', marginBottom: '1.5rem' }}>
                <Button type="primary" icon={<PlusOutlined />} onClick={addExtraUserProfileField} loading={loading}>
                    {strings.addField}
                </Button>
            </div>
            <List
                grid={{ column: 1 }}
                dataSource={extraUserProfileFields}
                renderItem={(item, index) => (
                    <List.Item>
                        <Card style={{ marginBottom: '1.5rem' }} key={`extra-field-${item._id}`}>
                            <FieldForm field={item} refreshFields={refreshFields} />
                        </Card>
                    </List.Item>
                )}
            />
        </div>
    );
};

export default ExtraUserProfileFields;
