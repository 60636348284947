import React, { useContext, useEffect, useState } from 'react';

import { PlusCircleOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { Button, Card, Tag, Table, Divider, Typography, Tooltip } from 'antd';
import moment from 'moment';
import LocalizedStrings from 'react-localization';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError } from '../utility';
import NewLiveStreamSpeakRequestModal from './NewLiveStreamSpeakRequestModal';

const strings = new LocalizedStrings({
    en: {
        yourRequests: 'Your Requests',
        videoCallOngoing: 'A video call is ongoing, please end it before joining this stream.',
        booth: 'Booth',
        module: 'Module',
        startTime: 'Start Time',
        endTime: 'End Time',
        requestedOn: 'Requested On',
        status: 'Status',
        actions: 'Actions',
        join: 'Join',
        newRequest: 'New Request',
        files: 'Files',
        download: 'Download',
    },
    de: {
        yourRequests: 'Ihre Anfragen',
        videoCallOngoing:
            'Es findet bereits ein Videoanruf statt. Bitte beenden Sie diesen, bevor Sie diesem Stream beitreten.',
        booth: 'Verkaufsstand',
        module: 'Modul',
        startTime: 'Startzeit',
        endTime: 'Endzeit',
        requestedOn: 'Angefordert am',
        status: 'Status',
        actions: 'Aktionen',
        join: 'Verbinden',
        newRequest: 'Neue Anfrage',
        files: 'Dateien',
        download: 'Herunterladen',
    },
});

export const LiveStreamRequests = ({ clientId, eventId }: { clientId: string; eventId: string }) => {
    const [loading, setLoading] = useState(false);
    const [showNewRequestModal, setShowNewRequestModal] = useState(false);
    const [requests, setRequests] = useState<Modules.VideoCallRequest[]>([]);
    const context = useContext(GlobalContext);

    const getRequestsOfOperator = async () => {
        try {
            setLoading(true);
            const requests = await apiRequester.getLiveStreamSpeakRequestsOfOperator({ clientId, eventId });
            setRequests(requests);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getRequestsOfOperator();
    }, [clientId, eventId]);

    const joinLiveStream = (request: Modules.VideoCallRequest) => {
        try {
            const activeRoom = context.activeVideoRoom;
            const liveStreamingRoom = request?.room;
            if (activeRoom) throw new Error(strings.videoCallOngoing);
            else if (liveStreamingRoom) context.setActiveVideoRoom(liveStreamingRoom);
        } catch (err) {
            handleError(err);
        }
    };

    const speakerRequestTableColumns = [
        // {
        //     title: 'ID',
        //     dataIndex: '_id',
        //     key: '_id',
        // },
        {
            title: strings.booth,
            dataIndex: 'booth',
            key: 'booth',
            render: (item: Booths.Booth) => item?.name || '',
        },
        {
            title: strings.module,
            dataIndex: 'module',
            key: 'module',
            render: (item: Modules.Module) => item?.name || 'Live Streaming',
        },
        {
            title: strings.startTime,
            dataIndex: 'fromTime',
            key: 'fromTime',
            render: (time: string) => {
                return moment(time).format('LLL');
            },
        },
        {
            title: strings.endTime,
            dataIndex: 'toTime',
            key: 'toTime',
            render: (time: string) => {
                return moment(time).format('LLL');
            },
        },
        {
            title: strings.requestedOn,
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (time: string) => {
                return moment(time).format('LLL');
            },
        },
        {
            title: strings.status,
            dataIndex: 'status',
            key: 'status',
            render: (status: string, request: Modules.VideoCallRequest) => (
                <>
                    <Tag color={status === 'accepted' ? 'green' : status === 'rejected' ? 'red' : 'blue'}>
                        {status.toUpperCase()}
                    </Tag>
                    {status === 'rejected' && (
                        <Tooltip title={request.rejectReason}>
                            <QuestionCircleFilled />
                        </Tooltip>
                    )}
                </>
            ),
            // TODO: add tags colors
        },
        {
            title: strings.actions,
            key: 'actions',
            render: (_: any, request: Modules.VideoCallRequest) => (
                <Button
                    type="primary"
                    size="small"
                    disabled={request.status !== 'accepted'}
                    onClick={() => joinLiveStream(request)}
                >
                    {strings.join}
                </Button>
            ),
        },
    ];

    return (
        <>
            <Card
                title={strings.yourRequests}
                style={{ marginBottom: '1.5rem' }}
                extra={
                    <Button icon={<PlusCircleOutlined />} type="primary" onClick={() => setShowNewRequestModal(true)}>
                        {strings.newRequest}
                    </Button>
                }
            >
                <Table
                    pagination={false}
                    dataSource={requests}
                    bordered={false}
                    columns={speakerRequestTableColumns}
                    style={{ width: '100%' }}
                    rowKey={request => request._id!}
                    expandable={{
                        expandedRowRender: record => (
                            <>
                                <p style={{ margin: 0 }}>{record.description}</p>
                                <Divider />
                                <Typography.Title level={5}>{strings.files}</Typography.Title>
                                {record.files?.map(file => (
                                    <p key={file._id!}>
                                        <Button download href={file.link} type="link">
                                            {strings.download}
                                        </Button>
                                        {file.name}{' '}
                                    </p>
                                ))}
                            </>
                        ),
                    }}
                />
            </Card>
            <NewLiveStreamSpeakRequestModal
                clientId={clientId}
                eventId={eventId}
                showModal={showNewRequestModal}
                setShowModal={setShowNewRequestModal}
                refreshRequests={getRequestsOfOperator}
            />
        </>
    );
};

export default LiveStreamRequests;
