import { useContext, useEffect, useRef, useState } from 'react';

import { GlobalContext } from '../context/GlobalContextProvider';

export const useBoothCommunication = () => {
    const context = useContext(GlobalContext);
    const { channels, setChannels, setOldChannels } = context;

    const addListener = context.pubnub?.addListener;
    const removeListener = context.pubnub?.removeListener;
    const hereNow = context.pubnub?.hereNow;
    const objects = context.pubnub?.objects;
    const publish = context.pubnub?.publish!;

    const addChannels = (channelsToAdd: string[]) => {
        setChannels(channels => {
            const newChannels = [...new Set([...channels, ...channelsToAdd])];
            if (newChannels.length === channels.length) return channels;
            else return newChannels;
        });
    };

    const removeChannels = (channelsToRemove: string[]) => {
        setChannels(channels => {
            const newChannels = channels.filter(c => !channelsToRemove.includes(c));
            if (newChannels.length === channels.length) {
                return channels;
            } else {
                setOldChannels(channelsToRemove);
                return newChannels;
            }
        });
    };

    return {
        addListener,
        removeListener,
        channels,
        addChannels,
        removeChannels,
        hereNow,
        objects,
        publish,
    };
};
