import React, { ReactNode } from 'react';

import { Layout as AntdLayout, Typography } from 'antd';
import LocalizedStrings from 'react-localization';
import styled from 'styled-components';

import Menu from './Menu';

const { Header, Content, Footer } = AntdLayout;
const { Paragraph, Title } = Typography;

const strings = new LocalizedStrings({
    en: {
        by: 'by',
    },
    de: { by: 'von' },
});

const Hero = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1853db;
    flex-direction: column;
    padding: 8rem 0;
`;

export const Layout = ({
    children,
    title,
    subTitle,
    showHero = true,
}: {
    children: ReactNode;
    title?: string;
    subTitle?: string;
    showHero?: boolean;
}) => {
    return (
        <AntdLayout className="layout" style={{ minHeight: '100vh' }}>
            <Header style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
                <div style={{ float: 'left', width: '120px', color: 'white' }}>
                    <strong>VIRTOOALLY</strong>
                </div>
                <Menu />
            </Header>
            <Content style={{ flexGrow: 1 }}>
                <div>
                    {showHero && (
                        <Hero>
                            <Title style={{ marginBottom: 0, color: 'white' }}>{title}</Title>
                            <Paragraph style={{ color: 'white' }}>{subTitle}</Paragraph>
                        </Hero>
                    )}

                    {children}
                </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
                Virtooally {strings.by}{' '}
                <a href="https://www.exponents.com/" target="_blank" rel="noreferrer">
                    Exponents Insta USA Inc.
                </a>
            </Footer>
        </AntdLayout>
    );
};

export default Layout;
