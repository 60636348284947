import React, { useContext, useEffect, useState } from 'react';

import { DeleteOutlined, PlusOutlined, SaveOutlined, UserAddOutlined } from '@ant-design/icons';
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Modal,
    Row,
    Switch,
    Typography,
    DatePicker,
    Select,
    Table,
    Divider,
    Tag,
    List,
    Popover,
    Space,
    Drawer,
    Popconfirm,
} from 'antd';
import moment, { Moment } from 'moment';
import Pubnub from 'pubnub';
import LocalizedStrings from 'react-localization';
import store from 'store';

import { GlobalContext } from '../context/GlobalContextProvider';
import { useBoothCommunication } from '../hooks';
import { apiRequester, handleError, handleSuccess } from '../utility';
import LiveStreamingQuestions from './LiveStreamingQuestions';

const strings = new LocalizedStrings({
    en: {
        streamURL: 'Stream URL',
        videoCallOngoing: 'A video call is ongoing, please end it before joining this stream.',
        joinedSuccessfully: 'Your join request was processed successfully',
        speakerAdded: 'Speaker has been added to the live stream',
        speakers: 'Speakers',
        speakerDeleted: 'Speaker has been removed from the live stream',
        callForPapers: 'Call for Papers',
        speakerAccepted: 'Speaker has been accepted',
        speakerRejected: 'Speaker has been rejected',
        download: 'Download',
        addSpeaker: 'Add Speaker',
        join: 'Join',
        requestAccess: 'Request Access',
        accept: 'Accept',
        reject: 'Reject',
        delete: 'Delete',
        files: 'Files',
        sponsor: 'Sponsor',
        pleaseSelectSponsor: 'Please select a sponsor',
        selectSponsor: 'Select a sponsor',
        duration: 'Duration',
        selectSpeakerDuration: 'Please select speaker duration',
        name: 'Name',
        startTime: 'Start Time',
        endTime: 'End Time',
        status: 'Status',
        actions: 'Actions',
        remove: 'Remove',
        recordings: 'Recordings',
        videos: 'Videos',
        addVideo: 'Add Video',
        add: 'Add',
        videoAdded: 'Video has been added',
        videoDeleted: 'Video has been deleted',
        yes: 'Yes',
        no: 'No',
        sure: 'Are you sure you want to delete?',
    },
    de: {
        streamURL: 'Stream URL',
        videoCallOngoing:
            'Es findet bereits ein Videoanruf statt. Bitte beenden Sie diesen, bevor Sie diesem Stream beitreten.',
        joinedSuccessfully: 'Ihre Teilnahmeanfrage war erfolgreich.',
        speakerAdded: 'Sprecher wurde zum Livestream hinzugefügt',
        speakers: 'Sprecher',
        speakerDeleted: 'Sprecher wurde aus dem Livestream entfernt',
        callForPapers: 'Call for Papers',
        speakerAccepted: 'Sprecher wurde akzeptiert',
        speakerRejected: 'Sprecher wurde abgelehnt',
        download: 'Herunterladen',
        addSpeaker: 'Lautsprecher hinzufügen',
        join: 'Verbinden',
        requestAccess: 'Anfrage Zugang',
        accept: 'Annehmen',
        reject: 'Ablehnen',
        delete: 'Löschen',
        files: 'Dateien',
        sponsor: 'Sponsor',
        pleaseSelectSponsor: 'Bitte wählen Sie einen Sponsor',
        selectSponsor: 'Wähle einen Sponsor',
        duration: 'Dauer',
        selectSpeakerDuration: 'Bitte wählen Sie die Sprecherdauer',
        name: 'Name',
        startTime: 'Startzeit',
        endTime: 'Endzeit',
        status: 'Status',
        actions: 'Aktionen',
        remove: 'Entfernen',
        recordings: 'Aufnahmen',
        videos: 'Videos',
        addVideo: 'Videos hinzufügen',
        add: 'Addieren',
        videoAdded: 'Video wurde hinzugefügt',
        videoDeleted: 'Video wurde gelöscht',
        yes: 'Ja',
        no: 'Nein',
        sure: 'Sind Sie sicher, dass Sie löschen möchten?',
    },
});

const { RangePicker } = DatePicker;
const { Option } = Select;

export const LiveStreaming = ({
    clientId,
    eventId,
    boothId,
    moduleId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
}) => {
    const loggedInUser = store.get('user') as Users.User;
    const context = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const [module, setModule] = useState<Modules.Module<Modules.LiveStreamingData>>();
    const [showAddSpeakerModal, setShowAddSpeakerModal] = useState<boolean>(false);
    const [addSpeakerForm] = Form.useForm();
    const [sponsors, setSponsors] = useState<Users.User[]>([]);
    const [speakers, setSpeakers] = useState<Modules.LiveStreamingSpeaker[]>([]);
    const [requests, setRequests] = useState<Modules.VideoCallRequest[]>([]);
    const [recordings, setRecordings] = useState<Modules.DailyCoRecordings[]>([]);
    const [videos, setVideos] = useState<Modules.VideoModuleVideo[]>([]);
    const [addDrawer, setAddDrawer] = useState(false);
    const [addForm] = Form.useForm();
    const boothOperatorsChannel = `booth-operators.${boothId}`;
    const { addListener, removeListener } = useBoothCommunication();

    const showModal = () => {
        try {
            const activeRoom = context.activeVideoRoom;
            const liveStreamingRoom = module?.moduleData?.room;
            if (activeRoom) throw new Error(strings.videoCallOngoing);
            else if (liveStreamingRoom) context.setActiveVideoRoom(liveStreamingRoom);
        } catch (err) {
            handleError(err);
        }
    };

    const refreshStreamData = async () => {
        const data = await apiRequester.getBoothModule<Modules.LiveStreamingData>(clientId, eventId, boothId, moduleId);
        setModule(data);
    };

    const refreshRecordings = async () => {
        const recordings = await apiRequester.getLiveStreamRecordings({ clientId, eventId, boothId, moduleId });
        setRecordings(recordings);
    };

    const refreshVideos = async () => {
        const videos = await apiRequester.getVideosOfVideoModule({ clientId, eventId, boothId, moduleId });
        setVideos(videos);
    };

    const refreshSpeakers = async () => {
        const speakers = await apiRequester.getLiveStreamSpeakers({ clientId, eventId, boothId, moduleId });
        setSpeakers(speakers);
    };

    const refreshRequests = async () => {
        const requests = await apiRequester.getLiveStreamSpeakRequests({ clientId, eventId, boothId, moduleId });
        setRequests(requests);
    };

    const messageListener = (messageEvent: Pubnub.MessageEvent) => {
        const { message, channel } = messageEvent;
        const { id } = message;
        if (channel === boothOperatorsChannel) {
            if (id === 'LIVE_STREAMING_SPEAKER_REQUEST') refreshRequests();
        }
    };

    const listeners = {
        message: messageListener,
    };

    useEffect(() => {
        setLoading(true);
        refreshStreamData()
            .then(() => {
                const promises = [];
                promises.push(refreshSpeakers());
                promises.push(refreshRecordings());
                promises.push(refreshVideos());
                !context.user?.roles?.includes('sponsor') && promises.push(refreshRequests());
                return Promise.all(promises);
            })
            .then(() => context.refreshClientUsers(clientId, eventId))
            .then(users => {
                const sponsors = users.filter(user => user.roles?.includes('sponsor'));
                setSponsors(sponsors);
            })
            .catch(handleError)
            .finally(() => setLoading(false));
        addListener && addListener(listeners);
        return () => {
            removeListener && removeListener(listeners);
        };
    }, []);

    const isParticipant = () => {
        const userId = loggedInUser?._id;
        const liveStreamingRoom = module?.moduleData?.room;
        const participants = liveStreamingRoom?.participants;

        if (userId) {
            const userIsParticipant = participants?.find(participant => participant.user?._id === userId);
            if (userIsParticipant) return true;
        }

        return false;
    };

    const requestToJoinVideoRoom = async () => {
        try {
            setLoading(true);
            const liveStreamingRoom = module?.moduleData?.room;
            await apiRequester.joinVideoRoom({
                clientId: clientId!,
                eventId: eventId!,
                boothId: boothId!,
                moduleId: moduleId!,
                roomId: liveStreamingRoom?._id!,
            });
            await refreshStreamData();
            handleSuccess(strings.joinedSuccessfully!);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const addSpeaker = async ({ userId, duration }: { userId: string; duration: [Moment, Moment] }) => {
        try {
            setLoading(true);
            const fromTime = duration[0].subtract(10, 'minutes').toISOString();
            const toTime = duration[1].toISOString();
            await apiRequester.addSpeakerToLiveStream({
                clientId,
                eventId,
                boothId,
                moduleId,
                userId,
                fromTime,
                toTime,
            });
            handleSuccess(strings.speakerAdded!);
            setShowAddSpeakerModal(false);
            await Promise.all([refreshSpeakers(), refreshRequests()]);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const deleteSpeaker = async ({ speakerId }: { speakerId: string }) => {
        try {
            setLoading(true);
            await apiRequester.deleteLiveStreamSpeaker({
                clientId,
                eventId,
                boothId,
                moduleId,
                speakerId,
            });
            handleSuccess(strings.speakerDeleted!);
            await Promise.all([refreshSpeakers(), refreshRequests()]);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const acceptSpeakerRequest = async ({ requestId }: { requestId: string }) => {
        try {
            setLoading(true);
            await apiRequester.acceptLiveStreamSpeaker({
                clientId,
                eventId,
                boothId,
                moduleId,
                requestId,
            });
            handleSuccess(strings.speakerAccepted!);
            await Promise.all([refreshSpeakers(), refreshRequests()]);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const rejectSpeakerRequest = async ({ requestId, reason }: { requestId: string; reason: string }) => {
        try {
            setLoading(true);
            await apiRequester.rejectLiveStreamSpeaker({
                clientId,
                eventId,
                boothId,
                moduleId,
                requestId,
                reason,
            });
            handleSuccess(strings.speakerRejected!);
            await Promise.all([refreshSpeakers(), refreshRequests()]);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const deleteSpeakerRequest = async ({ requestId }: { requestId: string }) => {
        try {
            setLoading(true);
            await apiRequester.deleteLiveStreamSpeakerRequest({
                clientId,
                eventId,
                boothId,
                moduleId,
                requestId,
            });
            handleSuccess(strings.speakerDeleted!);
            await Promise.all([refreshSpeakers(), refreshRequests()]);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const speakerTableColumns = [
        // {
        //     title: 'ID',
        //     dataIndex: '_id',
        //     key: '_id',
        // },
        {
            title: strings.name,
            dataIndex: 'speaker',
            key: 'speaker.name',
            render: (item: any, row: Modules.LiveStreamingSpeaker) => {
                return typeof row.speaker !== 'string'
                    ? (row.speaker?.firstName || '') + ' ' + (row.speaker?.lastName || '')
                    : '';
            },
        },
        {
            title: strings.startTime,
            dataIndex: 'fromTime',
            key: 'fromTime',
            render: (time: string) => {
                return moment(time).format('LLL');
            },
        },
        {
            title: strings.endTime,
            dataIndex: 'toTime',
            key: 'toTime',
            render: (time: string) => {
                return moment(time).format('LLL');
            },
        },
        {
            title: strings.actions,
            dataIndex: 'actions',
            key: 'actions',
            render: (time: string, row: Modules.LiveStreamingSpeaker) => {
                return !context.user?.roles?.includes('sponsor') ? (
                    <Popconfirm
                        placement="topLeft"
                        title={strings.sure}
                        onConfirm={() => deleteSpeaker({ speakerId: row._id! })}
                        okText={strings.yes}
                        cancelText={strings.no}
                    >
                        <Button type="link" danger>
                            {strings.remove}
                        </Button>
                    </Popconfirm>
                ) : (
                    <span></span>
                );
            },
        },
    ];

    const RequestRejectForm = ({ requestId }: { requestId: string }) => {
        const [form] = Form.useForm();
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            form.setFieldsValue({ requestId });
        });

        const onFinish = async ({ requestId, reason }: { requestId: string; reason: string }) => {
            try {
                setLoading(true);
                await rejectSpeakerRequest({ requestId, reason });
            } catch (err) {
                handleError(err);
            } finally {
                setLoading(false);
            }
        };

        return (
            <Form
                form={form}
                size="small"
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                onFinish={onFinish}
            >
                <Form.Item name="requestId" hidden>
                    <Input />
                </Form.Item>
                <Form.Item name="reason" style={{ marginBottom: '1rem' }} required={true}>
                    <Input.TextArea placeholder="Reason" rows={3} cols={50} />
                </Form.Item>
                <Form.Item style={{ marginBottom: 0, width: '100%' }}>
                    <Button size="small" type="primary" danger onClick={form.submit} block loading={loading}>
                        Reject
                    </Button>
                </Form.Item>
            </Form>
        );
    };

    const speakerRequestTableColumns = [
        // {
        //     title: 'ID',
        //     dataIndex: '_id',
        //     key: '_id',
        // },
        {
            title: strings.name,
            dataIndex: 'speaker',
            key: 'operator',
            render: (item: any, row: Modules.VideoCallRequest) => {
                return typeof row.operator !== 'string'
                    ? (row.operator?.firstName || '') + ' ' + (row.operator?.lastName || '')
                    : '';
            },
        },
        {
            title: strings.startTime,
            dataIndex: 'fromTime',
            key: 'fromTime',
            render: (time: string) => {
                return moment(time).format('LLL');
            },
        },
        {
            title: strings.endTime,
            dataIndex: 'toTime',
            key: 'toTime',
            render: (time: string) => {
                return moment(time).format('LLL');
            },
        },
        {
            title: strings.status,
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => <Tag>{status}</Tag>,
            // TODO: add tags colors
        },
        {
            title: strings.actions,
            dataIndex: 'actions',
            key: 'actions',
            render: (time: string, row: Modules.VideoCallRequest) => {
                return !context.user?.roles?.includes('sponsor') ? (
                    <>
                        {row.status === 'requested' && (
                            <>
                                <Button type="link" onClick={() => acceptSpeakerRequest({ requestId: row._id! })}>
                                    {strings.accept}
                                </Button>
                                <Popover
                                    title={null}
                                    content={<RequestRejectForm requestId={row._id!} />}
                                    trigger="click"
                                    overlayStyle={{
                                        maxWidth: '80%',
                                    }}
                                >
                                    <Button type="link" danger>
                                        {strings.reject}
                                    </Button>
                                </Popover>
                            </>
                        )}
                        {row.status === 'accepted' ||
                            (row.status === 'rejected' && (
                                <>
                                    <Popconfirm
                                        placement="topLeft"
                                        title={strings.sure}
                                        onConfirm={() => deleteSpeakerRequest({ requestId: row._id! })}
                                        okText={strings.yes}
                                        cancelText={strings.no}
                                    >
                                        <Button type="link" danger>
                                            {strings.delete}
                                        </Button>
                                    </Popconfirm>
                                </>
                            ))}
                    </>
                ) : (
                    <span></span>
                );
            },
        },
    ];

    const addVideo = async ({ link }: { link: string }) => {
        try {
            setLoading(true);
            await apiRequester.addVideoToVideoModule({
                clientId,
                eventId,
                boothId,
                moduleId,
                link,
            });
            setAddDrawer(false);
            handleSuccess(strings.videoAdded!);
            await refreshVideos();
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Card
                title={strings.speakers}
                extra={
                    <>
                        {!context.user?.roles?.includes('sponsor') && (
                            <Button
                                type="link"
                                icon={<UserAddOutlined />}
                                onClick={() => setShowAddSpeakerModal(true)}
                                loading={loading}
                            >
                                {strings.addSpeaker}
                            </Button>
                        )}
                        {isParticipant() ? (
                            <Button
                                type="primary"
                                onClick={showModal}
                                loading={loading}
                                testing-loading={JSON.stringify(loading)}
                                testing-id="join-live-stream"
                            >
                                {strings.join}
                            </Button>
                        ) : (
                            !context.user?.roles?.includes('sponsor') && (
                                <Button
                                    type="link"
                                    onClick={requestToJoinVideoRoom}
                                    loading={loading}
                                    testing-loading={loading}
                                    testing-id="request-live-stream-access"
                                >
                                    {strings.requestAccess}
                                </Button>
                            )
                        )}
                    </>
                }
                style={{ marginBottom: '1.5rem' }}
            >
                <div testing-id="loading" testing-loading={JSON.stringify(loading)}></div>
                <Table
                    pagination={false}
                    dataSource={speakers}
                    bordered={false}
                    columns={speakerTableColumns}
                    style={{ width: '100%' }}
                    rowKey={request => request._id!}
                />

                {/* {context.user?.roles?.includes('super-admin') && (
                    <div style={{ marginTop: '1.5rem' }}>
                        Live link:{' '}
                        <Typography.Text copyable>{module?.moduleData?.room?.channel?.playbackUrl}</Typography.Text>
                    </div>
                )} */}
            </Card>

            {!context.user?.roles?.includes('sponsor') && (
                <Card title={strings.callForPapers} style={{ marginBottom: '1.5rem' }}>
                    <Table
                        pagination={false}
                        dataSource={requests}
                        bordered={false}
                        columns={speakerRequestTableColumns}
                        style={{ width: '100%' }}
                        expandable={{
                            expandedRowRender: record => (
                                <>
                                    <p style={{ margin: 0 }}>{record.description}</p>
                                    <Divider />
                                    <Typography.Title level={5}>{strings.files}</Typography.Title>
                                    {record.files?.map(file => (
                                        <p key={file._id!}>
                                            <Button download href={file.link} type="link">
                                                {strings.download}
                                            </Button>
                                            {file.name}{' '}
                                        </p>
                                    ))}
                                </>
                            ),
                        }}
                    />
                </Card>
            )}

            <LiveStreamingQuestions
                clientId={clientId}
                eventId={eventId}
                boothId={boothId}
                moduleId={moduleId}
                roomId={module?.moduleData?.room?._id!}
            />

            {/* <Card
                title={strings.videos}
                style={{ marginBottom: '1.5rem' }}
                extra={
                    <Button type="primary" onClick={() => setAddDrawer(true)} icon={<PlusOutlined />} size="small" />
                }
            >
                <List
                    dataSource={videos}
                    renderItem={video => (
                        <List.Item
                            extra={
                                <Button
                                    danger
                                    icon={<DeleteOutlined />}
                                    size="small"
                                    onClick={() => deleteVideo(video?._id!)}
                                />
                            }
                        >
                            {video?.link}
                        </List.Item>
                    )}
                />
            </Card> */}

            <Card title={strings.recordings}>
                <List
                    dataSource={recordings}
                    renderItem={recording => (
                        <List.Item>
                            <Tag>Start time</Tag> {moment(new Date(recording.start_ts * 1000)).format('LLL')}{' '}
                            <Button
                                href={`https://api.daily.co/v1/recordings/${recording.share_token}/download`}
                                download
                                type="link"
                                target="_blank"
                            >
                                {strings.download}
                            </Button>
                        </List.Item>
                    )}
                />
            </Card>

            <Modal
                visible={showAddSpeakerModal}
                title={strings.addSpeaker}
                onCancel={() => setShowAddSpeakerModal(false)}
                footer={
                    <Button type="primary" onClick={addSpeakerForm.submit} loading={loading}>
                        {strings.addSpeaker}
                    </Button>
                }
            >
                <Form form={addSpeakerForm} onFinish={addSpeaker}>
                    <Form.Item
                        name="userId"
                        label={strings.sponsor}
                        rules={[{ required: true, message: strings.pleaseSelectSponsor }]}
                    >
                        <Select placeholder={strings.selectSponsor} allowClear>
                            {sponsors.map(sponsor => (
                                <Option key={sponsor._id} value={sponsor._id!}>
                                    {sponsor.firstName || ''} {sponsor.lastName || ''}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="duration"
                        label={strings.duration}
                        rules={[{ required: true, message: strings.selectSpeakerDuration }]}
                    >
                        <RangePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" />
                    </Form.Item>
                </Form>
            </Modal>

            <Drawer
                closable={true}
                visible={addDrawer}
                footer={
                    <Button type="primary" onClick={addForm.submit} loading={loading}>
                        {strings.add}
                    </Button>
                }
                onClose={() => setAddDrawer(false)}
                title={strings.addVideo}
                width="620px"
                footerStyle={{ textAlign: 'right' }}
            >
                <Form form={addForm} onFinish={addVideo}>
                    <Form.Item name="link" label="Link">
                        <Input />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

export default LiveStreaming;
