import React, { useEffect, useState } from 'react';

import { DownloadOutlined } from '@ant-design/icons';
import { Row, Col, Button, DatePicker, Space, Card, Typography } from 'antd';
import download from 'downloadjs';
import { Parser } from 'json2csv';
import moment, { Moment } from 'moment-timezone';
import LocalizedStrings from 'react-localization';

import { apiRequester, handleError } from '../utility';
import { OnlineVisitorCountHistory, OnlineVisitorCountSummary, VideoCallSummary } from './Analytics';

const strings = new LocalizedStrings({
    en: {
        downloadActions: 'Download Visitor Actions',
    },
    de: {
        downloadActions: '',
    },
});
const { RangePicker } = DatePicker;

export const BoothAnalytics = ({
    clientId,
    eventId,
    boothId,
}: {
    clientId: string;
    eventId: string;
    boothId?: string;
}) => {
    const [onlineVisitorCountSummary, setOnlineVisitorCountSummary] = useState<
        EventsAnalytics.OnlineVisitorCountSummary
    >({});
    const [videoCallSummary, setVideoCallSummary] = useState<EventsAnalytics.VideoCallSummary>({});
    const [onlineVisitorCountHistory, setOnlineVisitorCountHistory] = useState<
        EventsAnalytics.OnlineVisitorCountHistory
    >([]);
    const [visitorCountHistoryDates, setVisitorCountHistoryDates] = useState<[Moment, Moment]>([
        moment().subtract('15', 'days'),
        moment(),
    ]);
    const [visitorActionHistoryDates, setVisitorActionHistoryDates] = useState<[Moment, Moment]>([
        moment().subtract('15', 'days'),
        moment(),
    ]);
    const [loading, setLoading] = useState(false);

    const refreshData = async () => {
        try {
            const [onlineVisitorCountSummary, videoCallSummary, onlineVisitorCountHistory] = await Promise.all([
                apiRequester.getOnlineVisitorCount({ clientId, eventId }),
                apiRequester.getVideoCallSummary({ clientId, eventId, boothId }),
                apiRequester.getOnlineVisitorCountHistory({
                    clientId,
                    eventId,
                    boothId: boothId!,
                    startDate: visitorCountHistoryDates[0].toISOString(),
                    endDate: visitorCountHistoryDates[1].toISOString(),
                    timeZone: moment.tz.guess(),
                }),
            ]);
            setOnlineVisitorCountSummary(onlineVisitorCountSummary);
            setVideoCallSummary(videoCallSummary);
            setOnlineVisitorCountHistory(onlineVisitorCountHistory);
        } catch (err) {
            handleError(err);
        }
    };

    useEffect(() => {
        refreshData();

        const refreshDataInterval = setInterval(refreshData, 30000);

        return () => {
            clearInterval(refreshDataInterval);
        };
    }, [clientId, eventId, boothId, visitorCountHistoryDates]);

    const downloadBoothVisitorActions = async () => {
        try {
            setLoading(true);
            const visitorActions = await apiRequester.findAllActionsOfBoothVisitors({
                clientId,
                eventId,
                boothId: boothId!,
                startDate: visitorActionHistoryDates[0].startOf('day').toISOString(),
                endDate: visitorActionHistoryDates[1].endOf('day').toISOString(),
            });
            const fields = [
                'Time',
                'Action',
                'User ID',
                'User First Name',
                'User Last Name',
                'User Email',
                'User Company',
                'User Designation',
            ];
            const opts = { fields };
            const parser = new Parser(opts);
            const csv = parser.parse(
                visitorActions.map(action => ({
                    Time: moment(action.createdAt).toString(),
                    Action: action.action,
                    'User ID': typeof action.user === 'string' ? action.user : action.user?._id! || '',
                    'User First Name': typeof action.user === 'string' ? action.user : action.user?.firstName || '',
                    'User Last Name': typeof action.user === 'string' ? action.user : action.user?.lastName || '',
                    'User Email': typeof action.user === 'string' ? action.user : action.user?.emailId || '',
                    'User Company': typeof action.user === 'string' ? action.user : action.user?.company || '',
                    'User Designation': typeof action.user === 'string' ? action.user : action.user?.designation || '',
                })),
            );
            download(csv, `visitor-actions-booth-${boothId}.csv`, 'text/csv');
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
                <Col xs={24}>
                    <Card size="small">
                        <Space style={{ float: 'right' }}>
                            <RangePicker
                                format="DD-MM-YYYY"
                                value={visitorActionHistoryDates}
                                onChange={setVisitorActionHistoryDates as any}
                                onOk={setVisitorActionHistoryDates as any}
                            />
                            <Button icon={<DownloadOutlined />} onClick={downloadBoothVisitorActions} loading={loading}>
                                {strings.downloadActions}
                            </Button>
                        </Space>
                    </Card>
                </Col>
            </Row>
            <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
                <Col xs={24}>
                    <OnlineVisitorCountSummary
                        onlineVisitorCountSummary={onlineVisitorCountSummary}
                        boothId={boothId}
                    />
                </Col>
            </Row>
            <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
                <Col xs={12}>
                    <VideoCallSummary videoCallSummary={videoCallSummary} boothId={boothId} />
                </Col>
                <Col xs={12}>
                    <OnlineVisitorCountHistory
                        onlineVisitorCountHistory={onlineVisitorCountHistory}
                        dates={visitorCountHistoryDates}
                        changeDates={setVisitorCountHistoryDates}
                    />
                </Col>
            </Row>
        </>
    );
};

export default BoothAnalytics;
