import React, { useEffect, useState } from 'react';

import { CarryOutFilled, CarryOutOutlined, DeleteOutlined, FormOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Input, Popover, Row, Space, Tree } from 'antd';
import get from 'get-value';
import set from 'set-value';

/* eslint-disable @typescript-eslint/no-use-before-define */

export const MultiLevelSelectOptionInput = ({
    defaultOptionTree,
    setDropDownOptions,
}: {
    defaultOptionTree: any;
    setDropDownOptions: React.Dispatch<any>;
}) => {
    const optionNameInput = (key?: string) => {
        const [newOption, setNewOption] = useState<string>();
        return (
            <Space>
                <Input
                    id="option-input"
                    size="small"
                    onChange={e => {
                        setNewOption(e.target.value);
                    }}
                    value={newOption}
                />
                <Button type="primary" size="small" onClick={() => onClick(key, newOption, setNewOption)}>
                    Add
                </Button>
            </Space>
        );
    };

    const [treeData, setTreeData] = useState<any>([
        {
            title: (
                <Popover title={false} trigger="hover" content={optionNameInput}>
                    New
                </Popover>
            ),
            addKey: '',
            key: '.new',
            children: [],
            type: 'adder',
        },
    ]);

    const getResultRecursively = (treeData: any): any => {
        const nodeCount = treeData.length;
        const result = [];
        for (let i = 0; i < nodeCount; i++) {
            const node = treeData[i];
            if (node.type === 'adder') {
                result.push({ type: 'adder', key: node.key, addKey: node.addKey });
            } else {
                result.push({
                    value: node.value,
                    children: node.children && node.children.length ? getResultRecursively(node.children) : [],
                    key: node.key,
                });
            }
        }
        return result;
    };

    const updateResult = (treeData: any) => {
        const result = getResultRecursively(treeData);
        setDropDownOptions(result);
    };

    const recursivelyGenerateData = (data: any) => {
        const result: any = [];
        data.map((item: any) => {
            result.push({
                title:
                    item.type !== 'adder' ? (
                        <>
                            {item.value} <DeleteOutlined onClick={() => onRemove(item.key)} />
                        </>
                    ) : (
                        <Popover title={false} trigger="hover" content={() => optionNameInput(item.addKey)}>
                            New
                        </Popover>
                    ),
                value: item.value,
                key: item.key,
                type: item.type,
                addKey: item.addKey,
                children: item.children && item.children.length && recursivelyGenerateData(item.children),
            });
        });
        return result;
    };

    const generateTreeFromData = () => {
        const data = defaultOptionTree?.optionTree;
        if (data && data.length) {
            const generatedData = recursivelyGenerateData(data);
            updateResult(generatedData);
            setTreeData(generatedData);
        }
    };

    useEffect(() => {
        generateTreeFromData();
    }, [defaultOptionTree]);

    const onRemove = (key: string) => {
        setTreeData((treeData: any) => {
            let newTreeData = Object.assign([], treeData);
            const parentKey = key.split('.').slice(0, -1).join('.');
            const existingChildren = parentKey ? get(newTreeData, parentKey) : newTreeData;
            const copyOfChildren = Object.assign([], existingChildren);
            const newChildrenList = copyOfChildren.filter((children: any) => children.key !== key);
            parentKey ? set(newTreeData, parentKey, newChildrenList) : (newTreeData = newChildrenList);
            updateResult(newTreeData);
            return newTreeData;
        });
    };

    const onClick = (
        key?: string,
        name?: string,
        setNewOption?: React.Dispatch<React.SetStateAction<string | undefined>>,
    ) => {
        name &&
            setTreeData((treeData: any) => {
                let newTreeData = Object.assign([], treeData);
                const existingChildren = key ? get(newTreeData, key) : newTreeData;
                const copyOfChildren = Object.assign([], existingChildren);
                key
                    ? set(newTreeData, key, [
                          ...copyOfChildren,
                          {
                              title: (
                                  <span>
                                      {name}
                                      <DeleteOutlined
                                          onClick={() => onRemove(`${key}.${copyOfChildren.length}`)}
                                      />{' '}
                                  </span>
                              ),
                              key: `${key}.${copyOfChildren.length}`,
                              value: name,
                              children: [
                                  {
                                      title: (
                                          <Popover
                                              title={false}
                                              trigger="hover"
                                              content={() =>
                                                  optionNameInput(`${key}.${copyOfChildren.length}.children`)
                                              }
                                          >
                                              New
                                          </Popover>
                                      ),
                                      addKey: `${key}.${copyOfChildren.length}.children`,
                                      key: `${key}.${copyOfChildren.length}.new`,
                                      type: 'adder',
                                  },
                              ],
                          },
                      ])
                    : (newTreeData = [
                          ...copyOfChildren,
                          {
                              title: (
                                  <span>
                                      {name}
                                      <DeleteOutlined onClick={() => onRemove(`${copyOfChildren.length}`)} />{' '}
                                  </span>
                              ),
                              key: `${copyOfChildren.length}`,
                              value: name,
                              children: [
                                  {
                                      title: (
                                          <Popover
                                              title={false}
                                              trigger="hover"
                                              content={() => optionNameInput(`${copyOfChildren.length}.children`)}
                                          >
                                              New
                                          </Popover>
                                      ),
                                      addKey: `${copyOfChildren.length}.children`,
                                      type: 'adder',
                                      key: `${copyOfChildren.length}.new`,
                                  },
                              ],
                          },
                      ]);
                updateResult(newTreeData);
                return newTreeData;
            });
        setNewOption && setNewOption(undefined);
    };

    return <Tree treeData={treeData} showLine={true} showIcon={false} defaultExpandAll={true} />;
};

/* eslint-disable @typescript-eslint/no-use-before-define */
