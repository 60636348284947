import React, { useContext, useEffect, useState } from 'react';

import {
    ClockCircleOutlined,
    CloseCircleOutlined,
    CloseOutlined,
    PlusCircleOutlined,
    TeamOutlined,
    UserAddOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Dropdown, Menu, Row, Tag, Typography, List, Space, Popover, Modal } from 'antd';
import Search from 'antd/lib/input/Search';
import SubMenu from 'antd/lib/menu/SubMenu';
import moment from 'moment';
import { usePubNub } from 'pubnub-react';
import LocalizedStrings from 'react-localization';
import store from 'store';

import { GlobalContext } from '../context/GlobalContextProvider';
import { handleError, getResourceId, apiRequester, handleSuccess } from '../utility';
import { generateNotifierMessage, VIDEO_CALL_ACTIONS } from '../utility/NotifierMessages';

const strings = new LocalizedStrings({
    en: {
        requestProcessed: 'Your join request was processed successfully',
        roomEnded: 'Video room has been ended for all',
        roomStarting: 'Your video room is starting',
        scheduled: 'Scheduled',
        group: 'Group',
        private: 'Private',
        broadcast: 'Broadcast',
        meeting: 'Meeting',
        end: 'End',
        join: 'Join',
        yetStart: 'Yet to start',
        requestAccess: 'Request Access',
        createdBy: 'Created by',
        startsAt: 'Starts at',
        endsAt: 'Ends at',
        visitorsCanBook: 'Visitors can book',
        visitorCount: 'Visitor count',
        visitors: 'Visitors',
        of: 'of',
        left: 'Left',
        enterEmail: 'Enter Visitor Email',
        recordings: 'Recordings',
        operators: 'Operators',
        ended: 'Ended',
    },
    de: {
        requestProcessed: 'Ihre Teilnahmeanfrage war erfolgreich',
        roomEnded: 'Der Videoanruf wurde für alle beendet',
        roomStarting: 'Ihr Videoanruf beginnt',
        scheduled: 'Geplant',
        group: 'Gruppe',
        private: 'Privat',
        broadcast: 'Broadcast',
        meeting: 'Meeting',
        end: 'Beenden',
        join: 'Beitreten',
        yetStart: 'Beginnt demnächst',
        requestAccess: 'Zutritt anfragen',
        createdBy: 'Erstellt von',
        startsAt: 'Beginnt um',
        endsAt: 'Endet um',
        visitorsCanBook: 'Buchbar durch Besucher',
        visitorCount: 'Besucheranzahl',
        visitors: 'Besucher ',
        of: 'von',
        left: 'verlassen',
        recordings: 'Aufnahmen',
        operators: 'Moderators',
        ended: 'Beendet',
    },
});

export const VideoRoomCard = ({
    room,
    clientId,
    eventId,
    boothId,
    moduleId,
    refreshVideoCallRequestList,
    ended,
}: {
    room: Modules.VideoRoom;
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    refreshVideoCallRequestList: () => void;
    ended?: boolean;
}) => {
    const context = useContext(GlobalContext);
    const { _id: roomId, participants, booth } = room;
    const [loading, setLoading] = useState(false);
    const [isMember, setIsMember] = useState(false);
    const pubnub = usePubNub();
    const boothOperatorsChannel = `booth-operators.${boothId}`;
    const boothVisitorsChannel = `booth-visitors.${boothId}`;
    const [status, setStatus] = useState<'yet-to-start' | 'ongoing' | 'ended'>();
    const [showRecordingModal, setShowRecordingModal] = useState(false);

    const ViewRecordingsModal = () => {
        const [loading, setLoading] = useState(false);
        const [recordings, setRecordings] = useState<Modules.DailyCoRecordings[]>([]);

        const refreshRecordings = async () => {
            try {
                setLoading(true);
                const recordings = await apiRequester.getLiveStreamRecordingsOfRoom({
                    clientId,
                    eventId,
                    boothId,
                    moduleId,
                    roomId: room._id!,
                });
                setRecordings(recordings);
            } catch (err) {
                handleError(err);
            } finally {
                setLoading(false);
            }
        };

        useEffect(() => {
            showRecordingModal && refreshRecordings();
        }, [showRecordingModal]);

        return (
            <Modal
                visible={showRecordingModal}
                onCancel={() => setShowRecordingModal(false)}
                title={strings.recordings}
                footer={null}
                destroyOnClose={true}
            >
                <List
                    loading={loading}
                    dataSource={recordings}
                    renderItem={recording => (
                        <List.Item>
                            <Tag>Start time</Tag> {moment(new Date(recording.start_ts * 1000)).format('LLL')}{' '}
                            <Button
                                href={`https://api.daily.co/v1/recordings/${recording.share_token}/download`}
                                download
                                type="link"
                                target="_blank"
                            >
                                Download
                            </Button>
                        </List.Item>
                    )}
                />
            </Modal>
        );
    };

    useEffect(() => {
        const loggedInUser = store.get('user');
        const operatorIsMember = room.participants?.find(participant => participant.user?._id === loggedInUser?._id)
            ? true
            : false;
        setIsMember(operatorIsMember);
        const isScheduled = room.isScheduled;

        if (isScheduled) {
            // Configure yet to start
            const currentTime = new Date().getTime();
            const startTime = new Date(room?.startTime || '').getTime() - 300000;
            const endTime = new Date(room?.endTime || '').getTime();

            const timeDifference = startTime - currentTime;
            const oneHourInMilliSeconds = 60 * 60 * 1000;

            const yetToStart = currentTime < startTime;
            const ongoing = currentTime >= startTime && currentTime < endTime;
            setStatus(yetToStart ? 'yet-to-start' : ongoing ? 'ongoing' : 'ended');

            if (timeDifference > 0 && timeDifference < oneHourInMilliSeconds) {
                const timeout = setTimeout(() => {
                    console.log('executed: ' + timeDifference);
                    setStatus('ongoing');
                }, timeDifference);
                return () => clearTimeout(timeout);
            }
        } else {
            setStatus('ongoing');
        }
    }, [room]);

    const joinVideoRoom = async () => {
        try {
            setLoading(true);
            await apiRequester.joinVideoRoom({
                clientId: clientId!,
                eventId: eventId!,
                boothId: boothId!,
                moduleId: moduleId!,
                roomId: roomId!,
            });
            await refreshVideoCallRequestList();
            handleSuccess(strings.requestProcessed!);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const endVideoRoom = async () => {
        try {
            setLoading(true);
            await apiRequester.endVideoRoom({
                clientId: clientId!,
                eventId: eventId!,
                boothId: boothId!,
                moduleId: moduleId!,
                roomId: roomId!,
            });
            await refreshVideoCallRequestList();
            await pubnub.publish({
                channel: boothVisitorsChannel,
                message: generateNotifierMessage.videoRequest({
                    booth: {
                        id: boothId,
                        name: context.activeBooth?.name,
                    },
                    action: VIDEO_CALL_ACTIONS.OPERATOR_ENDED_FOR_ALL,
                }),
            });
            await pubnub.publish({
                channel: boothOperatorsChannel,
                message: generateNotifierMessage.videoRequest({
                    booth: {
                        id: boothId,
                        name: context.activeBooth?.name,
                    },
                    action: VIDEO_CALL_ACTIONS.OPERATOR_ENDED_FOR_ALL,
                }),
            });
            handleSuccess(strings.roomEnded!);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const joinRoom = () => {
        context.setActiveVideoRoom(room);
        handleSuccess(strings.roomStarting!);
    };

    const addVisitorToRoomDirectlyByEmail = async (email: string) => {
        try {
            setLoading(true);
            await apiRequester.addVisitorDirectlyToVideoRoomById({
                clientId,
                eventId,
                boothId,
                moduleId,
                roomId: room._id!,
                email,
            });
            await refreshVideoCallRequestList();
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const UserEmail = () => {
        return (
            <Search
                placeholder={strings.enterEmail}
                enterButton={<PlusCircleOutlined />}
                onSearch={addVisitorToRoomDirectlyByEmail}
                loading={loading}
            />
        );
    };

    const AddVisitorToRoomByEmail = () => {
        return (
            <Space>
                <Typography.Text strong>{strings.visitors}</Typography.Text>
                <Popover placement="top" content={<UserEmail />} trigger="hover">
                    <Button shape="circle" icon={<UserAddOutlined />} type="link" />
                </Popover>
            </Space>
        );
    };

    // const yetToStart = () => {
    //     const currentTime = new Date().getTime();
    //     const startTime = new Date(room?.startTime || '').getTime();
    //     const endTime = new Date(room?.endTime || '').getTime();
    //     if (!room.isScheduled) return false;
    //     else if (startTime <= currentTime && currentTime < endTime) return false;
    //     else return true;
    // };

    return (
        <Card
            key={`room-${room._id}`}
            title={room.name}
            size="small"
            extra={[
                room.isScheduled ? (
                    <Tag key={`scheduled-group-${room._id}`} color="orange">
                        {strings.scheduled}
                    </Tag>
                ) : undefined,
                room.isGroup ? (
                    <Tag key={`tag-group-${room._id}`}>{strings.group}</Tag>
                ) : (
                    <Tag key={`tag-private-${room._id}`}>{strings.private}</Tag>
                ),
                room.isBroadcast ? (
                    <Tag key={`tag-broadcast-${room._id}`}>{strings.broadcast}</Tag>
                ) : (
                    <Tag key={`tag-meeting-${room._id}`}>{strings.meeting}</Tag>
                ),
            ]}
            actions={
                ended
                    ? undefined
                    : [
                          <Button
                              size="small"
                              danger
                              type="link"
                              loading={loading}
                              disabled={loading}
                              onClick={endVideoRoom}
                              key={`end-room-${room._id}`}
                              testing-id={`end-video-room-${room.name}`}
                          >
                              {strings.end}
                          </Button>,
                          isMember ? (
                              <div>
                                  <Button
                                      size="small"
                                      type="link"
                                      onClick={joinRoom}
                                      key={`join-room-${room._id}`}
                                      disabled={loading || status === 'yet-to-start' || status === 'ended'}
                                  >
                                      {strings.join}
                                  </Button>

                                  {status === 'yet-to-start' && <Tag>{strings.yetStart}</Tag>}
                                  {status === 'ended' && <Tag>{strings.ended}</Tag>}
                              </div>
                          ) : (
                              <Button
                                  size="small"
                                  type="link"
                                  onClick={joinVideoRoom}
                                  loading={loading}
                                  disabled={loading}
                                  key={`request-access-${room._id}`}
                              >
                                  {strings.requestAccess}
                              </Button>
                          ),
                      ]
            }
        >
            <>
                <Row style={{ width: '100%' }}>
                    <Col xs={8}>{strings.createdBy} </Col>
                    <Col xs={12}>
                        <Typography.Text type="secondary">
                            {room.creator?.firstName} {room.creator?.lastName} about {moment(room.createdAt).fromNow()}
                        </Typography.Text>
                    </Col>
                </Row>
                {room.isScheduled && (
                    <>
                        <Row style={{ width: '100%' }}>
                            <Col xs={8}>{strings.startsAt} </Col>
                            <Col xs={12}>
                                <Typography.Text type="secondary">
                                    {moment(room.startTime).format('llll')}
                                </Typography.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8}>{strings.endsAt} </Col>
                            <Col xs={12}>
                                <Typography.Text type="secondary">
                                    {moment(room.endTime).format('llll')}
                                </Typography.Text>
                            </Col>
                        </Row>
                        <Row style={{ width: '100%' }}>
                            <Col xs={8}>{strings.visitorsCanBook}</Col>
                            <Col xs={12}>
                                <Typography.Text type="secondary">
                                    {room.canBeScheduledByVisitor ? 'Yes' : 'No'}
                                </Typography.Text>
                            </Col>
                        </Row>
                        <Row style={{ width: '100%' }}>
                            <Col xs={8}>{strings.visitorCount}</Col>
                            <Col xs={12}>
                                <Typography.Text type="secondary">
                                    {
                                        room.participants?.filter(participant =>
                                            participant.user?.roles?.includes('visitor'),
                                        ).length
                                    }{' '}
                                    {strings.of} {room.maxVisitorCount ? room.maxVisitorCount : 'Unlimited'}
                                </Typography.Text>
                            </Col>
                        </Row>
                    </>
                )}
            </>
            <List
                header={
                    room.isScheduled && !ended ? (
                        <AddVisitorToRoomByEmail />
                    ) : (
                        <Typography.Text strong>{strings.visitors}</Typography.Text>
                    )
                }
                dataSource={room.participants?.filter(participant => participant.user?.roles?.includes('visitor'))}
                size="small"
                bordered={false}
                renderItem={participant => (
                    <List.Item
                        extra={participant.request?.status === 'ended' ? <Tag color="red">{strings.left}</Tag> : <></>}
                    >
                        <Typography.Text>
                            {participant.user?.firstName
                                ? participant.user?.firstName + ' ' + participant.user?.lastName
                                : 'Anonymous User'}
                        </Typography.Text>
                    </List.Item>
                )}
            />
            <List
                header={<Typography.Text strong>{strings.operators}</Typography.Text>}
                dataSource={room.participants?.filter(participant => !participant.user?.roles?.includes('visitor'))}
                size="small"
                bordered={false}
                renderItem={participant => (
                    <List.Item
                        extra={participant.request?.status === 'ended' ? <Tag color="red">{strings.left}</Tag> : <></>}
                    >
                        <Typography.Text>
                            {participant.user?.firstName
                                ? participant.user?.firstName + ' ' + participant.user?.lastName
                                : 'Anonymous User'}
                        </Typography.Text>
                    </List.Item>
                )}
            />
            <ViewRecordingsModal />
        </Card>
    );
};

export default VideoRoomCard;
