import React, { useEffect, useState } from 'react';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Drawer, List, Row, Space, Typography, Form, Input, Tooltip, Popconfirm } from 'antd';
import LocalizedStrings from 'react-localization';

import { apiRequester, handleError, handleSuccess } from '../utility';

const strings = new LocalizedStrings({
    en: {
        addVideo: 'Add Video',
        add: 'Add',
        videoAdded: 'Video has been added',
        videoDeleted: 'Video has been deleted',
        yes: 'Yes',
        no: 'No',
        sure: 'Are you sure you want to delete?',
    },
    de: { yes: 'Ja', no: 'Nein', sure: 'Sind Sie sicher, dass Sie löschen möchten?' },
});

export const VideosModule = ({
    clientId,
    eventId,
    boothId,
    moduleId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
}) => {
    const [videos, setVideos] = useState<Modules.VideoModuleVideo[]>([]);
    const [loading, setLoading] = useState(false);
    const [addDrawer, setAddDrawer] = useState(false);
    const [addForm] = Form.useForm();

    const refreshVideos = async () => {
        try {
            setLoading(true);
            const videos = await apiRequester.getVideosOfVideoModule({ clientId, eventId, boothId, moduleId });
            setVideos(videos);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        refreshVideos();
    }, [clientId, eventId, boothId, moduleId]);

    const addVideo = async ({ link }: { link: string }) => {
        try {
            setLoading(true);
            await apiRequester.addVideoToVideoModule({
                clientId,
                eventId,
                boothId,
                moduleId,
                link,
            });
            setAddDrawer(false);
            handleSuccess(strings.videoAdded!);
            await refreshVideos();
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const deleteVideo = async (videoId: string) => {
        try {
            setLoading(true);
            await apiRequester.deleteVideoOfVideoModule({
                clientId,
                eventId,
                boothId,
                moduleId,
                videoId,
            });
            setAddDrawer(false);
            handleSuccess(strings.videoDeleted!);
            await refreshVideos();
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Row gutter={[24, 24]}>
                <Col xs={24} style={{ textAlign: 'right' }}>
                    <Button loading={loading} type="primary" icon={<PlusOutlined />} onClick={() => setAddDrawer(true)}>
                        {strings.addVideo}
                    </Button>
                </Col>
                {videos.map((video, index) => {
                    return (
                        <Col key={video._id} xs={24}>
                            <Card size="small">
                                <Space size="large">
                                    <Tooltip title="Video Index">
                                        <Button shape="circle" size="small" disabled>
                                            {index + 1}
                                        </Button>
                                    </Tooltip>
                                    <Popconfirm
                                        placement="topLeft"
                                        title={strings.sure}
                                        onConfirm={() => deleteVideo(video._id!)}
                                        okText={strings.yes}
                                        cancelText={strings.no}
                                    >
                                        <Button icon={<DeleteOutlined />} danger shape="circle" size="small" />
                                    </Popconfirm>
                                    <Tooltip title="Video ID">
                                        <Typography.Text type="secondary" code>
                                            {video._id}
                                        </Typography.Text>
                                    </Tooltip>
                                    <Button type="link" href={video.link}>
                                        {video.link}
                                    </Button>
                                </Space>
                            </Card>
                        </Col>
                    );
                })}
            </Row>
            <Drawer
                closable={true}
                visible={addDrawer}
                footer={
                    <Button type="primary" onClick={addForm.submit} loading={loading}>
                        {strings.add}
                    </Button>
                }
                onClose={() => setAddDrawer(false)}
                title={strings.addVideo}
                width="620px"
                footerStyle={{ textAlign: 'right' }}
            >
                <Form form={addForm} onFinish={addVideo}>
                    <Form.Item name="link" label="Link">
                        <Input />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

export default VideosModule;
