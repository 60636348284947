import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import {
    AreaChartOutlined,
    CloseOutlined,
    DropboxOutlined,
    FileAddOutlined,
    FormOutlined,
    LinkOutlined,
    MinusCircleOutlined,
    PlayCircleOutlined,
    PlaySquareOutlined,
    PlusOutlined,
    UserOutlined,
    VideoCameraOutlined,
    WechatOutlined,
} from '@ant-design/icons';
import {
    Badge,
    Button,
    Checkbox,
    Divider,
    Drawer,
    Form,
    Input,
    Menu,
    PageHeader,
    Popconfirm,
    Select,
    Space,
    Tooltip,
    Typography,
} from 'antd';
import { navigate } from 'gatsby';
import { debounce } from 'lodash';
import Pubnub from 'pubnub';
import { usePubNub } from 'pubnub-react';
import LocalizedStrings from 'react-localization';
import store from 'store';
import UIfx from 'uifx';

import { InviteToBoothModal, LinkModule, ProductsModule } from '.';
import notificationSound from '../../static/chat-notification.mp3';
import { GlobalContext } from '../context/GlobalContextProvider';
import { useBoothCommunication, useChatList } from '../hooks';
import { apiRequester, handleError, handleSuccess, showNotification } from '../utility';
import { CHAT_ACTIONS, NOTIFIER_MESSAGE_IDS, VIDEO_CALL_ACTIONS } from '../utility/NotifierMessages';
import BoothAnalytics from './BoothAnalytics';
import ChatModule from './ChatModule';
import FileDownloadsModule from './FileDownloadsModule';
import FormModule from './FormModule';
import LiveStreaming from './LiveStreaming';
import UserList from './UserList';
import VideoCallModule from './VideoCallModule';
import VideosModule from './VideosModule';

let sound: UIfx;
if (typeof window !== 'undefined') sound = new UIfx(notificationSound);

const { Paragraph, Title } = Typography;
const { SubMenu } = Menu;
const { Option } = Select;
const strings = new LocalizedStrings({
    en: {
        moduleAdded: 'module has been added',
        moduleDeleted: 'Module has been deleted',
        chat: 'Chat',
        videoCall: 'Video Call',
        id: 'ID',
        fileDownloads: 'File Downloads',
        addModule: 'Add Module',
        selectBooth: 'Please select a booth module form the list above',
        boothUpdated: 'Booth info has been updated',
        boothDeleted: 'Booth has been deleted',
        deleteModule: 'Delete Module',
        editBooth: 'Edit Booth',
        add: 'Add',
        module: 'Module',
        cancel: 'Cancel',
        delete: 'Delete',
        fileDownloadsDescription:
            'This booth module allows you to manage files that are available to the visitors for download in a specific booth. The files can be updated in real time.',
        videoCallDescription:
            'This allows video call between the visitor and the event team where calls can be triggered from both ends.',
        chatDescription: 'This module allows booth operators to interact with booth visitors via realtime web chat.',
        save: 'Save',
        name: 'Name',
        enterClientName: 'Please enter client name',
        enterClientDescription: 'Please enter client description',
        sponsors: 'Sponsors',
        staffs: 'Staffs',
        category: 'Category',
        moduleUpdate: 'Module has been updated',
        liveStreaming: 'Live Streaming',
        liveStreamingDescription: 'This booth module allows you to broadcast a live stream to visitors on the booth. ',
        boothAnalytics: 'Booth Analytics',
        analytics: 'Analytics',
        editModule: 'Edit Module',
        video: 'Videos',
        videoDescription: 'This module allows you to add Youtube/Vimeo video to booths (lite version only)',
        userList: 'User List',
        enterModuleName: 'Please enter module name',
        subType: 'Subtype',
        enterSubType: 'Please enter subtype',
        showInMannedList: 'Show in manned list',
        products: 'Products',
        productsDescription: 'Dynamic list of products.',
        panoramaName: 'Panorama Name',
        invite: 'Invite',
        yes: 'Yes',
        no: 'No',
        sure: 'Are you sure you want to delete?',
        visitorEndCall: 'Visitor ended the video call',
        newRequest: 'New video call request',
        link: 'Link',
        linkDescription: 'This module allows you to add links to booths (lite version only)',
        externalLink: 'External Links',
        newLiveStreamingSpeakerRequest: 'A sponsor sent a new call for paper request in module {0}',
    },
    de: {
        moduleAdded: 'Modul wurde hinzugefügt',
        moduleDeleted: 'Modul wurde gelöscht',
        chat: 'Chat',
        videoCall: 'Videoanruf',
        id: 'ID',
        fileDownloads: 'Dateidownloads',
        addModule: 'Modul hinzufügen',
        selectBooth: 'Bitte wählen Sie ein Standmodul aus der oben stehenden Liste',
        boothUpdated: 'Standinfo wurde aktualisiert',
        boothDeleted: 'Stand wurde gelöscht',
        deleteModule: 'Modul löschen',
        editBooth: 'Stand bearbeiten',
        add: 'Hinzufügen',
        module: 'Modul',
        cancel: 'Abbrechen',
        delete: 'Löschen',
        fileDownloadsDescription:
            'Mit diesem Modul können Sie die Dateien verwalten, die für den Besucher als Download in einem bestimmten Stand verfügbar sein sollen. Die Dateien werden in Echtzeit aktualisiert.',
        videoCallDescription:
            'Dies ermöglicht Videoanrufe zwischen dem Besucher und dem Veranstalterteam. Die Anrufe können von beiden Seiten initialisiert werden. ',
        chatDescription:
            'Dies ermöglicht dem Standbetreiber, mit Standbesuchern in Echtzeit über Chat zu interagieren. ',
        save: 'Speichern',
        name: 'Name',
        enterClientName: 'Bitte Kundennamen eingeben',
        enterClientDescription: 'Bitte Kundenbeschreibung eingeben',
        sponsors: 'Sponsoren',
        staffs: 'Mitarbeiter',
        category: 'Kategorie',
        moduleUpdate: 'Modul aktualisiert',
        liveStreaming: 'Live-Stream',
        liveStreamingDescription: 'Mit diesem Modul können Sie einen Live-Stream senden.',
        boothAnalytics: 'Standanalysen',
        analytics: 'Analysen',
        editModule: 'Modul bearbeiten',
        enterModuleName: 'Bitte Modulnamen eingeben',
        subType: 'Untertyp',
        enterSubType: 'Bitte Untertyp eingeben',
        products: 'Produkte',
        productsDescription: 'Dynamische Produktliste.',
        invite: 'Einladen',
        userList: 'Besucherliste',
        yes: 'Ja',
        no: 'Nein',
        sure: 'Sind Sie sicher, dass Sie löschen möchten?',
        visitorEndCall: 'Der Besucher hat den Videoanruf beendet',
        newRequest: 'Neue Videoanruf-Anfrage',
        newLiveStreamingSpeakerRequest: 'Ein Sponsor hat im Modul {0} einen neuen Call for Paper Request gesendet',
    },
});

export const BoothModules = ({
    clientId,
    eventId,
    boothId,
}: {
    clientId?: string;
    eventId?: string;
    boothId?: string;
}) => {
    const pubnub = usePubNub();
    const context = useContext(GlobalContext);
    const [visibleAddDrawer, setVisibleAddDrawer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [addFormModuleName, setAddFormModuleName] = useState<Modules.MODULE_IDS>();
    const [activeModuleId, setActiveModuleId] = useState<string>('');
    const [visibleBoothEditDrawer, setVisibleBoothEditDrawer] = useState(false);
    const [visibleModuleEditDrawer, setVisibleModuleEditDrawer] = useState(false);
    const [boothEditForm] = Form.useForm();
    const [moduleEditForm] = Form.useForm();
    const [users, setUsers] = useState<Users.User[]>();
    const loggedInUser = store.get('user') as Users.User;
    const [showEmailInviteModal, setEmailInviteModal] = useState(false);
    const { addChannels, removeChannels, addListener, removeListener } = useBoothCommunication();
    const boothOperatorsChannel = `booth-operators.${boothId}`;
    const boothVisitorsChannel = `booth-visitors.${boothId}`;
    const boothVisitorsPresenceChannel = `booth-visitors.${boothId}-pnpres`;
    const boothChatModuleId = context.boothModules.find(module => module.type === 'chat')?._id?.toString();
    const { chats } = useChatList({
        clientId: clientId!,
        eventId: eventId!,
        boothId: boothId!,
        moduleId: boothChatModuleId!,
    });
    const chatRef = useRef<{ refreshChats: () => void }>(null);

    const getBooth = async () => {
        const booth = await apiRequester.getBooth(clientId!, eventId!, boothId!);
        context.setActiveBooth({ _id: booth._id!, ...booth });
        return booth;
    };

    const getBoothModules = async () => {
        const boothModules = await apiRequester.getBoothModules(clientId!, eventId!, boothId!);
        context.setBoothModules(boothModules.length ? boothModules : []);
        return boothModules;
    };

    useEffect(() => {
        setLoading(true);
        if (!clientId || !eventId || !boothId) {
            navigate('/');
            return;
        } else {
            Promise.all([
                getBooth(),
                getBoothModules(),
                loggedInUser?.roles?.includes('sponsor') ? [] : apiRequester.getOperators(clientId!),
            ])
                .then(data => {
                    setUsers(data[2]), setLoading(false);
                })
                .catch(handleError);
        }
        return () => {
            setActiveModuleId('');
            boothEditForm.resetFields();
        };
    }, [clientId, eventId, boothId]);

    const debouncedNewChatNotification = useCallback(
        debounce((userName?: string) => {
            if (userName) {
                showNotification('info', 'Chat Notification', `You've received a new chat message from ${userName}`);
            } else
                showNotification(
                    'info',
                    'Chat Notification',
                    `You've received a new chat message from an Anonymous User`,
                );
            sound.play();
        }, 3000),
        [],
    );

    const handleChatMessageNotification = async (event: Pubnub.MessageEvent) => {
        const { message } = event;
        const { action } = message;
        if (action === CHAT_ACTIONS.NEW_MESSAGE) {
            console.log('Handling new chat message');
            const { sender } = message;
            const { id: userId } = sender;
            const userList = await context.refreshUserList([userId]);
            chatRef.current?.refreshChats();
            const user = userList[userId];
            const userName =
                user?.firstName && user?.lastName
                    ? `${user.firstName} ${user.lastName}`
                    : user?.firstName
                    ? user.firstName
                    : undefined;
            debouncedNewChatNotification(userName);
        }
    };

    const handleLiveStreamingSpeakerRequest = (messageEvent: Pubnub.MessageEvent) => {
        const { message } = messageEvent;
        const { action, module } = message;
        if (action === 'NEW_LIVE_STREAMING_SPEAKER_REQUEST') {
            handleSuccess(strings.formatString(strings.newLiveStreamingSpeakerRequest, module.name!) as string);
        }
    };

    const debouncedVideoCallEndedNotification = debounce(
        () => showNotification('info', undefined, strings.visitorEndCall),
        3000,
    );

    const debouncedNewVideoCallEndedNotification = debounce(
        () => showNotification('info', undefined, strings.newRequest),
        3000,
    );

    const messageListener = (messageEvent: Pubnub.MessageEvent) => {
        const { message, channel } = messageEvent;
        const { id, action } = message;
        if (channel === boothOperatorsChannel) {
            if (id === 'CHAT') {
                switch (id) {
                    case NOTIFIER_MESSAGE_IDS.CHAT:
                        Promise.all([handleChatMessageNotification(messageEvent)]).catch(handleError);
                        break;
                    default:
                        break;
                }
            } else if (id === 'VIDEO_CALL') {
                if (action && action === VIDEO_CALL_ACTIONS.VISITOR_ENDED.toString()) {
                    debouncedVideoCallEndedNotification();
                }
                if (action && action === VIDEO_CALL_ACTIONS.NEW_REQUEST_FROM_VISITOR_TO_OPERATOR.toString()) {
                    debouncedNewVideoCallEndedNotification();
                }
            } else if (id === 'LIVE_STREAMING_SPEAKER_REQUEST') handleLiveStreamingSpeakerRequest(messageEvent);
        }
    };

    const presenceListener = async (event: Pubnub.PresenceEvent) => {
        const { channel, occupancy, action, uuid } = event;
        if (channel === boothOperatorsChannel && action === 'join') {
            console.info(`Operator ${uuid} came online, total occupancy operator channel is ${occupancy}`);
        } else if (channel === boothOperatorsChannel && action === 'leave') {
            console.info(`Operator ${uuid} went offline, total occupancy operator channel is ${occupancy}`);
        } else if (channel === boothVisitorsChannel && action === 'join') {
            console.info(`Visitor ${uuid} came online, total occupancy of visitor channel is ${occupancy}`);
        } else if (channel === boothVisitorsChannel && action === 'leave') {
            console.info(`Visitor ${uuid} went offline, total occupancy of visitor channel is ${occupancy}`);
        }
    };

    useEffect(() => {
        if (!context.pubnub) {
            context.setPubnub(pubnub);
        }

        console.info('Loading booth modules');
        addChannels([`booth-operators.${boothId}`, boothVisitorsPresenceChannel]);

        return () => {
            console.info('Unloading booth modules');
            removeChannels([`booth-operators.${boothId}`, boothVisitorsPresenceChannel]);
        };
    }, [boothId]);

    useEffect(() => {
        const listeners = { message: messageListener, presence: presenceListener };

        if (context.pubnub) {
            console.info('Adding booth listeners');
            addListener && addListener(listeners);
        }

        return () => {
            if (context.pubnub) {
                console.info('Removing booth listeners');
                context.pubnub && removeListener && removeListener(listeners);
            }
        };
    }, [context.pubnub]);

    const showBoothEditDrawer = () => {
        const booth = context.activeBooth!;
        boothEditForm.setFieldsValue({
            ...booth,
            staffs: booth.staffs?.map(staff => {
                return `${staff._id} - ${staff.firstName} ${staff.lastName} - ${staff.emailId}`;
            }),
            sponsors: booth.sponsors?.map(sponsor => {
                return `${sponsor._id} - ${sponsor.firstName} ${sponsor.lastName} - ${sponsor.emailId}`;
            }),
        });
        setVisibleBoothEditDrawer(true);
    };

    const showModuleEditDrawer = () => {
        moduleEditForm.resetFields();
        const [moduleType, moduleId] = activeModuleId.split('-');
        if (moduleType !== 'analytics' && moduleType !== 'userList') {
            const module = context.boothModules.find(module => module._id === moduleId);
            moduleEditForm.setFieldsValue(module);
            setVisibleModuleEditDrawer(true);
        } else handleError(new Error('This module cannot be edited.'));
    };

    const showAddDrawer = (event: any) => {
        const key = event.key;
        setAddFormModuleName(key);
        setVisibleAddDrawer(true);
    };

    const onCloseAddDrawer = () => {
        setVisibleAddDrawer(false);
    };

    const addModule = async (moduleName?: Modules.MODULE_IDS) => {
        try {
            setLoading(true);
            await apiRequester.addBoothModule(clientId!, eventId!, boothId!, { type: moduleName! });
            const boothModules = await apiRequester.getBoothModules(clientId!, eventId!, boothId!);
            context.setBoothModules(boothModules);
            onCloseAddDrawer();
            handleSuccess(`${moduleName?.charAt(0).toUpperCase()}${moduleName?.slice(1)} ${strings.moduleAdded}`);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            handleError(err);
        }
    };

    const removeModule = async () => {
        try {
            setLoading(true);
            const [moduleType, moduleId] = activeModuleId.split('-');
            if (moduleType === 'analytics' || moduleType === 'userList')
                throw new Error('This module cannot be deleted');
            await apiRequester.removeBoothModule(clientId!, eventId!, boothId!, moduleId);
            const boothModules = await apiRequester.getBoothModules(clientId!, eventId!, boothId!);
            context.setBoothModules(boothModules);
            setVisibleModuleEditDrawer(false);
            handleSuccess(strings.moduleDeleted!);
            setActiveModuleId('');
            setLoading(false);
        } catch (err) {
            setLoading(false);
            handleError(err);
        }
    };

    const sortBoothModulesByName = (b1: Modules.Module, b2: Modules.Module) => {
        let boothModule1Name = b1.name;
        let boothModule2Name = b2.name;

        const getModuleDefaultName = (type: Modules.MODULE_IDS): string => {
            if (type === 'file-downloads') return 'File Downloads';
            else if (type === 'forms') return 'Forms';
            else if (type === 'video-call') return 'Video Call';
            else if (type === 'chat') return 'Chat';
            else if (type === 'live-streaming') return 'Live Streaming';
            else if (type === 'video') return 'Video';
            else if (type === 'products') return 'Products';
            else return type;
        };

        if (!boothModule1Name) boothModule1Name = getModuleDefaultName(b1.type!);
        if (!boothModule2Name) boothModule2Name = getModuleDefaultName(b2.type!);

        return boothModule1Name > boothModule2Name ? 1 : -1;
    };

    const getBoothModuleMenu = useMemo(() => {
        return (
            <Menu mode="horizontal" style={{ backgroundColor: 'transparent' }} selectedKeys={[activeModuleId]}>
                <Menu.Item
                    key={`analytics`}
                    icon={<AreaChartOutlined />}
                    onClick={e => setActiveModuleId(e.key.toString())}
                >
                    <Tooltip title={strings.boothAnalytics}>{strings.analytics}</Tooltip>
                </Menu.Item>
                {context.boothModules.sort(sortBoothModulesByName).map(boothModule => {
                    if (boothModule.type === 'chat')
                        return (
                            <Menu.Item
                                key={`chat-${boothModule._id}`}
                                icon={<WechatOutlined />}
                                onClick={e => setActiveModuleId(e.key.toString())}
                            >
                                <Tooltip title={`${strings.id}: ${boothModule._id}`}>
                                    {boothModule.name ? boothModule.name : strings.chat}{' '}
                                    <Badge count={chats.filter(chat => chat.operatorUnread).length} />
                                </Tooltip>
                            </Menu.Item>
                        );
                    else if (boothModule.type === 'video-call')
                        return (
                            <Menu.Item
                                key={`videoCall-${boothModule._id}`}
                                icon={<VideoCameraOutlined />}
                                onClick={e => setActiveModuleId(e.key.toString())}
                                testing-id={`booth-module-tab-${boothModule._id}`}
                            >
                                <Tooltip title={`${strings.id}: ${boothModule._id}`}>
                                    {boothModule.name ? boothModule.name : strings.videoCall}
                                </Tooltip>
                            </Menu.Item>
                        );
                    else if (boothModule.type === 'forms')
                        return (
                            <Menu.Item
                                key={`forms-${boothModule._id}`}
                                icon={<FormOutlined />}
                                onClick={e => setActiveModuleId(e.key.toString())}
                            >
                                <Tooltip title={`${strings.id}: ${boothModule._id}`}>
                                    {boothModule.name ? boothModule.name : 'Form'}
                                </Tooltip>
                            </Menu.Item>
                        );
                    else if (boothModule.type === 'file-downloads')
                        return (
                            <Menu.Item
                                key={`fileDownloads-${boothModule._id}`}
                                icon={<FileAddOutlined />}
                                onClick={e => setActiveModuleId(e.key.toString())}
                            >
                                <Tooltip title={`${strings.id}: ${boothModule._id}`}>
                                    {boothModule.name ? boothModule.name : strings.fileDownloads}
                                </Tooltip>
                            </Menu.Item>
                        );
                    else if (boothModule.type === 'live-streaming')
                        return (
                            <Menu.Item
                                key={`liveStreaming-${boothModule._id}`}
                                icon={<PlaySquareOutlined />}
                                onClick={e => setActiveModuleId(e.key.toString())}
                            >
                                <Tooltip title={`${strings.id}: ${boothModule._id}`}>
                                    {boothModule.name ? boothModule.name : strings.liveStreaming}
                                </Tooltip>
                            </Menu.Item>
                        );
                    else if (boothModule.type === 'video')
                        return (
                            <Menu.Item
                                key={`video-${boothModule._id}`}
                                icon={<PlayCircleOutlined />}
                                onClick={e => setActiveModuleId(e.key.toString())}
                            >
                                <Tooltip title={`${strings.id}: ${boothModule._id}`}>
                                    {boothModule.name ? boothModule.name : strings.video}
                                </Tooltip>
                            </Menu.Item>
                        );
                    else if (boothModule.type === 'products')
                        return (
                            <Menu.Item
                                key={`products-${boothModule._id}`}
                                icon={<DropboxOutlined />}
                                onClick={e => setActiveModuleId(e.key.toString())}
                            >
                                <Tooltip title={`${strings.id}: ${boothModule._id}`}>
                                    {boothModule.name ? boothModule.name : strings.products}
                                </Tooltip>
                            </Menu.Item>
                        );
                    else if (boothModule.type === 'link')
                        return (
                            <Menu.Item
                                key={`link-${boothModule._id}`}
                                icon={<LinkOutlined />}
                                onClick={e => setActiveModuleId(e.key.toString())}
                            >
                                <Tooltip title={`${strings.id}: ${boothModule._id}`}>
                                    {boothModule.name ? boothModule.name : strings.externalLink}
                                </Tooltip>
                            </Menu.Item>
                        );
                    else return <></>;
                })}
                <Menu.Item key={`userList`} icon={<UserOutlined />} onClick={e => setActiveModuleId(e.key.toString())}>
                    <Tooltip title={strings.userList}>{strings.userList}</Tooltip>
                </Menu.Item>
                {loggedInUser?.roles?.includes('super-admin') && (
                    <SubMenu key="SubMenu" icon={<PlusOutlined />} title={strings.addModule}>
                        <Menu.ItemGroup>
                            <Menu.Item key="file-downloads" onClick={showAddDrawer}>
                                {strings.fileDownloads}
                            </Menu.Item>
                            <Menu.Item key="video-call" onClick={showAddDrawer}>
                                {strings.videoCall}
                            </Menu.Item>
                            <Menu.Item key="chat" onClick={showAddDrawer}>
                                {strings.chat}
                            </Menu.Item>
                            <Menu.Item key="live-streaming" onClick={showAddDrawer}>
                                {strings.liveStreaming}
                            </Menu.Item>
                            <Menu.Item key="video" onClick={showAddDrawer}>
                                {strings.video}
                            </Menu.Item>
                            <Menu.Item key="products" onClick={showAddDrawer}>
                                {strings.products}
                            </Menu.Item>
                            <Menu.Item key="link" onClick={showAddDrawer}>
                                {strings.link}
                            </Menu.Item>
                            {/* <Menu.Item key="forms" onClick={showAddDrawer}>
                            Forms
                        </Menu.Item> */}
                        </Menu.ItemGroup>
                    </SubMenu>
                )}
            </Menu>
        );
    }, [context.boothModules, activeModuleId, chats]);

    const getActiveModuleView = () => {
        if (!activeModuleId)
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    {strings.selectBooth}
                </div>
            );
        else {
            const [moduleType, moduleId] = activeModuleId.split('-');
            if (moduleType === 'chat' && clientId && eventId && boothId) {
                return (
                    <ChatModule
                        clientId={clientId}
                        eventId={eventId}
                        boothId={boothId}
                        moduleId={moduleId}
                        ref={chatRef}
                    />
                );
            } else if (moduleType === 'fileDownloads' && clientId && eventId && boothId) {
                return (
                    <FileDownloadsModule clientId={clientId} eventId={eventId} boothId={boothId} moduleId={moduleId} />
                );
            } else if (moduleType === 'videoCall' && clientId && eventId && boothId) {
                return <VideoCallModule clientId={clientId} eventId={eventId} boothId={boothId} moduleId={moduleId} />;
            } else if (moduleType === 'forms' && clientId && eventId && boothId) {
                return (
                    <FormModule
                        clientId={clientId!}
                        eventId={eventId!}
                        boothId={boothId!}
                        target="booth"
                        moduleId={moduleId!}
                    />
                );
            } else if (moduleType === 'liveStreaming' && clientId && eventId && boothId) {
                return (
                    <LiveStreaming clientId={clientId!} eventId={eventId!} boothId={boothId!} moduleId={moduleId!} />
                );
            } else if (moduleType === 'analytics' && clientId && eventId && boothId) {
                return <BoothAnalytics clientId={clientId!} eventId={eventId!} boothId={boothId!} />;
            } else if (moduleType === 'video' && clientId && eventId && boothId) {
                return <VideosModule clientId={clientId!} eventId={eventId!} boothId={boothId!} moduleId={moduleId!} />;
            } else if (moduleType === 'link' && clientId && eventId && boothId) {
                return <LinkModule clientId={clientId!} eventId={eventId!} boothId={boothId!} moduleId={moduleId!} />;
            } else if (moduleType === 'userList' && clientId && eventId && boothId) {
                return (
                    <UserList
                        clientId={clientId!}
                        eventId={eventId!}
                        boothId={boothId!}
                        isOperatorList={false}
                        showStats={false}
                        showAddUser={false}
                        editable={false}
                        showBoothUsersOnly={true}
                    />
                );
            } else if (moduleType === 'products' && clientId && eventId && boothId) {
                return <ProductsModule clientId={clientId} eventId={eventId} boothId={boothId} moduleId={moduleId} />;
            }
        }
    };

    const onCloseEditBoothDrawer = () => {
        setVisibleBoothEditDrawer(false);
    };

    const onCloseEditModuleDrawer = () => {
        setVisibleModuleEditDrawer(false);
    };

    const onFinishEditingBooth = async (booth: {
        _id: string;
        name: string;
        description?: string;
        staffs: string[];
        sponsors: string[];
        category: string;
        showInMannedList: boolean;
        panoramaName: string;
        socialLinks: string[];
    }) => {
        try {
            setLoading(true);
            booth = {
                ...booth,
                staffs: booth?.staffs?.map(staff => staff.split(' - ')[0]) || [],
                sponsors: booth.sponsors.map(sponsor => sponsor.split(' - ')[0]) || [],
            };
            await apiRequester.editBooth(clientId!, eventId!, booth._id!, {
                name: booth.name,
                description: booth.description,
                staffs: booth.staffs,
                sponsors: booth.sponsors,
                category: booth.category ? booth.category : undefined,
                showInMannedList: booth.showInMannedList,
                panoramaName: booth.panoramaName,
                socialLinks: booth.socialLinks,
            });
            const booths = await context.refreshBooths(clientId!, eventId!);
            const activeBooth = booths.find(booth => booth._id === context.activeBooth?._id);
            activeBooth && context.setActiveBooth({ _id: activeBooth._id!, ...activeBooth });
            setLoading(false);
            boothEditForm.resetFields();
            onCloseEditBoothDrawer();
            handleSuccess(strings.boothUpdated!);
        } catch (err) {
            handleError(err);
            setLoading(false);
        }
    };

    const onFinishEditingModule = async (module: { name: string; subType?: Modules.VIDEO_CALL_MODULE_TYPE }) => {
        try {
            setLoading(true);
            const moduleId = activeModuleId.split('-')[1];
            await apiRequester.editModule(clientId!, eventId!, context.activeBooth?._id!, moduleId, {
                name: module.name,
                subType: module.subType,
            });
            await context.refreshBoothModules(clientId!, eventId!, context.activeBooth?._id!);
            setLoading(false);
            moduleEditForm.resetFields();
            onCloseEditModuleDrawer();
            handleSuccess(strings.moduleUpdate!);
        } catch (err) {
            handleError(err);
            setLoading(false);
        }
    };

    const deleteBooth = async (boothId: string) => {
        try {
            setLoading(true);
            await apiRequester.deleteBooth(clientId!, eventId!, boothId);
            await context.refreshBooths(clientId!, eventId!);
            onCloseEditBoothDrawer();
            boothEditForm.resetFields();
            handleSuccess(strings.boothDeleted!);
            navigate('/event-list?clientId=' + clientId);
            setLoading(false);
        } catch (err) {
            handleError(err);
        }
    };

    return (
        <>
            <PageHeader
                onBack={() => window.history.back()}
                title={<Tooltip title={`ID: ${context.activeBooth?._id}`}>{context.activeBooth?.name}</Tooltip>}
                subTitle={context.activeBooth?.description}
                extra={[
                    activeModuleId &&
                        !activeModuleId.includes('analytics') &&
                        !activeModuleId.includes('userList') &&
                        loggedInUser?.roles?.includes('super-admin') && (
                            <Button key="edit-module" onClick={showModuleEditDrawer}>
                                {strings.editModule}
                            </Button>
                        ),
                    !loggedInUser?.roles?.includes('sponsor') && (
                        <Button key="edit-booth" onClick={showBoothEditDrawer}>
                            {strings.editBooth}
                        </Button>
                    ),
                    <Button type="primary" onClick={() => setEmailInviteModal(true)} key="invite-visitors">
                        {strings.invite}
                    </Button>,
                ]}
                style={{ backgroundColor: 'white', paddingBottom: 0 }}
            >
                {context.boothModules && getBoothModuleMenu}
            </PageHeader>
            <div style={{ padding: '1.5rem', height: '100%', overflowY: 'scroll' }}>{getActiveModuleView()}</div>

            <Drawer
                title={`${strings.add} ${addFormModuleName?.charAt(0).toUpperCase()}${addFormModuleName?.slice(1)} ${
                    strings.module
                }`}
                placement="right"
                closable={false}
                onClose={onCloseAddDrawer}
                visible={visibleAddDrawer}
                width="620px"
                footer={
                    <Space style={{ marginRight: '8px' }}>
                        <Button onClick={onCloseAddDrawer}>{strings.cancel}</Button>{' '}
                        <Button type="primary" loading={loading} onClick={() => addModule(addFormModuleName)}>
                            {strings.add}
                        </Button>
                    </Space>
                }
                footerStyle={{ textAlign: 'right', marginRight: '8px' }}
                closeIcon={<CloseOutlined />}
            >
                {addFormModuleName === 'file-downloads' && (
                    <>
                        <Paragraph>
                            <FileAddOutlined style={{ fontSize: '5rem' }} />
                        </Paragraph>
                        <Paragraph>{strings.fileDownloadsDescription}</Paragraph>
                    </>
                )}

                {addFormModuleName === 'video-call' && (
                    <>
                        <Paragraph>
                            <VideoCameraOutlined style={{ fontSize: '5rem' }} />
                        </Paragraph>
                        <Paragraph>{strings.videoCallDescription}</Paragraph>
                    </>
                )}

                {addFormModuleName === 'chat' && (
                    <>
                        <Paragraph>
                            <WechatOutlined style={{ fontSize: '5rem' }} />
                        </Paragraph>
                        <Paragraph>{strings.chatDescription}</Paragraph>
                    </>
                )}

                {addFormModuleName === 'live-streaming' && (
                    <>
                        <Paragraph>
                            <PlaySquareOutlined style={{ fontSize: '5rem' }} />
                        </Paragraph>
                        <Paragraph>{strings.liveStreamingDescription}</Paragraph>
                    </>
                )}

                {addFormModuleName === 'video' && (
                    <>
                        <Paragraph>
                            <PlayCircleOutlined style={{ fontSize: '5rem' }} />
                        </Paragraph>
                        <Paragraph>{strings.videoDescription}</Paragraph>
                    </>
                )}

                {addFormModuleName === 'products' && (
                    <>
                        <Paragraph>
                            <DropboxOutlined style={{ fontSize: '5rem' }} />
                        </Paragraph>
                        <Paragraph>{strings.productsDescription}</Paragraph>
                    </>
                )}

                {addFormModuleName === 'link' && (
                    <>
                        <Paragraph>
                            <LinkOutlined style={{ fontSize: '5rem' }} />
                        </Paragraph>
                        <Paragraph>{strings.linkDescription}</Paragraph>
                    </>
                )}

                {/* {addFormModuleName === 'forms' && (
                    <>
                        <Paragraph>
                            <FormOutlined style={{ fontSize: '5rem' }} />
                        </Paragraph>
                        <Paragraph>
                            This module allows you to ask the visitor to fill up a small form that can have open ended
                            or multiple choice questions.
                        </Paragraph>
                        <Paragraph>All responses are recorded and can be viewed or exported in CSV format.</Paragraph>
                    </>
                )} */}
            </Drawer>

            <Drawer
                title="Edit Module"
                placement="right"
                closable={false}
                onClose={onCloseEditModuleDrawer}
                visible={visibleModuleEditDrawer}
                width="620px"
                footer={
                    <>
                        {loggedInUser?.roles?.includes('super-admin') && (
                            <Popconfirm
                                placement="topLeft"
                                title={strings.sure}
                                onConfirm={removeModule}
                                okText={strings.yes}
                                cancelText={strings.no}
                            >
                                <Button danger style={{ marginLeft: '8px' }} loading={loading}>
                                    {strings.delete}
                                </Button>
                            </Popconfirm>
                        )}
                        <Space style={{ marginRight: '8px' }}>
                            <Button onClick={onCloseEditModuleDrawer}>{strings.cancel}</Button>{' '}
                            <Button type="primary" loading={loading} onClick={moduleEditForm.submit}>
                                {strings.save}
                            </Button>
                        </Space>
                    </>
                }
                footerStyle={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                closeIcon={<CloseOutlined />}
            >
                <Form form={moduleEditForm} onFinish={onFinishEditingModule}>
                    <Form.Item
                        labelCol={{ span: 6 }}
                        label={strings.id}
                        name="_id"
                        rules={[{ required: true }]}
                        hidden={false}
                    >
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                        label={strings.name}
                        name="name"
                        labelCol={{ span: 6 }}
                        rules={[{ required: false, message: strings.enterModuleName }]}
                    >
                        <Input />
                    </Form.Item>
                    {['liveStreaming', 'videoCall'].includes(activeModuleId.split('-')[0]) && (
                        <Form.Item
                            label={strings.subType}
                            name="subType"
                            labelCol={{ span: 6 }}
                            rules={[{ required: false, message: strings.enterSubType }]}
                        >
                            <Select>
                                <Option key={`AUDITORIUM`} value="AUDITORIUM">
                                    Auditorium
                                </Option>
                                <Option key={`GUIDED_TOUR`} value="GUIDED_TOUR">
                                    Guided Tour
                                </Option>
                                <Option key={`MEETING_ROOM`} value="MEETING_ROOM">
                                    Meeting Room
                                </Option>
                            </Select>
                        </Form.Item>
                    )}
                </Form>
            </Drawer>

            <Drawer
                title="Edit Booth"
                placement="right"
                closable={false}
                onClose={onCloseEditBoothDrawer}
                visible={visibleBoothEditDrawer}
                width="620px"
                footer={
                    <>
                        {loggedInUser?.roles?.includes('super-admin') && (
                            <Popconfirm
                                placement="topLeft"
                                title={strings.sure}
                                onConfirm={() => deleteBooth(boothEditForm.getFieldValue('_id'))}
                                okText={strings.yes}
                                cancelText={strings.no}
                            >
                                <Button danger style={{ marginLeft: '8px' }}>
                                    {strings.delete}
                                </Button>
                            </Popconfirm>
                        )}
                        <Space style={{ marginRight: '8px' }}>
                            <Button onClick={onCloseEditBoothDrawer}>{strings.cancel}</Button>{' '}
                            <Button type="primary" loading={loading} onClick={boothEditForm.submit}>
                                {strings.save}
                            </Button>
                        </Space>
                    </>
                }
                footerStyle={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                closeIcon={<CloseOutlined />}
            >
                <Form form={boothEditForm} onFinish={onFinishEditingBooth}>
                    <Form.Item
                        labelCol={{ span: 6 }}
                        label={strings.id}
                        name="_id"
                        rules={[{ required: true }]}
                        hidden={false}
                    >
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                        label={strings.name}
                        name="name"
                        labelCol={{ span: 6 }}
                        rules={[{ required: true, message: strings.enterClientName }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="Description"
                        labelCol={{ span: 6 }}
                        rules={[{ message: strings.enterClientDescription }]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    {/* <Form.Item label={strings.staffs} name="staffs" labelCol={{ span: 6 }}>
                        <Select mode="multiple">
                            {users?.map(user => {
                                return user.roles?.includes('staff') ? (
                                    <Option
                                        key={`add-staff-${user._id}`}
                                        value={`${user._id} - ${user.firstName} ${user.lastName} - ${user.emailId}`}
                                    >
                                        {user.firstName} {user.lastName} - {user.emailId}
                                    </Option>
                                ) : undefined;
                            })}
                        </Select>
                    </Form.Item> */}
                    <Form.Item label={strings.sponsors} name="sponsors" labelCol={{ span: 6 }}>
                        <Select mode="multiple">
                            {users?.map(user => {
                                return user.roles?.includes('sponsor') ? (
                                    <Option
                                        key={`add-sponsor-${user._id}`}
                                        value={`${user._id} - ${user.firstName} ${user.lastName} - ${user.emailId}`}
                                    >
                                        {user.firstName} {user.lastName} - {user.emailId}
                                    </Option>
                                ) : undefined;
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item label={strings.category} name="category" labelCol={{ span: 6 }}>
                        <Select allowClear={true}>
                            {context.activeEvent?.categories?.map(category => {
                                return (
                                    <Option key={`add-category-${category._id}`} value={category._id}>
                                        {category.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={strings.showInMannedList}
                        name="showInMannedList"
                        labelCol={{ span: 6 }}
                        valuePropName="checked"
                    >
                        <Checkbox />
                    </Form.Item>
                    <Divider />
                    <Form.Item label="Social Links" labelCol={{ span: 6 }}>
                        <Form.List name="socialLinks">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'baseline',
                                            }}
                                            key={key}
                                        >
                                            <Form.Item
                                                {...restField}
                                                key={`${key}-link`}
                                                name={name}
                                                style={{ marginRight: '10px' }}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(key)} />
                                        </div>
                                    ))}
                                    <Button type="link" onClick={() => add()} icon={<PlusOutlined />}>
                                        Add Link
                                    </Button>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                    <Divider />
                    <Form.Item label={strings.panoramaName} name="panoramaName" labelCol={{ span: 6 }}>
                        <Input />
                    </Form.Item>
                </Form>
            </Drawer>
            <InviteToBoothModal showModal={showEmailInviteModal} setShowModal={setEmailInviteModal} />
        </>
    );
};

export default BoothModules;
