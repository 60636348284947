import React, { useEffect, useState } from 'react';

import { PhoneOutlined } from '@ant-design/icons';
import { Card, List, Space, Typography } from 'antd';
import moment from 'moment';

import { apiRequester, handleError } from '../utility';

export const MissedCalls = ({
    clientId,
    eventId,
    boothId,
    moduleId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
}) => {
    const [missedCalls, setMissedCalls] = useState<MissedCall.MissedCall[]>([]);
    const [loading, setLoading] = useState(false);

    const refreshMissedCalls = async () => {
        try {
            setLoading(true);
            const missedCalls = await apiRequester.getMissedCalls({ clientId, eventId, boothId, moduleId });
            setMissedCalls(missedCalls);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        refreshMissedCalls();
    }, [clientId, eventId, boothId, moduleId]);

    return (
        <Card>
            <List
                size="small"
                loading={loading}
                dataSource={missedCalls}
                itemLayout="vertical"
                renderItem={item => {
                    return (
                        <List.Item extra={moment(item.createdAt).fromNow()}>
                            <List.Item.Meta
                                avatar={<PhoneOutlined color="#e74c3c" style={{ color: '#e74c3c' }} />}
                                title={
                                    <Space>
                                        <Typography.Text>{`${item.user?.firstName || item.user?._id || ''} ${
                                            item.user?.lastName || ''
                                        }`}</Typography.Text>
                                        <Typography.Text type="secondary">({item.user?.emailId || ''})</Typography.Text>
                                    </Space>
                                }
                                description={`Company: ${item.user?.company || ''}`}
                            />
                        </List.Item>
                    );
                }}
            />
        </Card>
    );
};

export default MissedCalls;
