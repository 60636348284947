import React, { useContext, useEffect, useState } from 'react';

import { Card, Row, Col, Select } from 'antd';
import moment from 'moment';
import { Tooltip, ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Bar, LabelList } from 'recharts';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError } from '../utility';
import { VideoCallSummary, OnlineVisitorCountSummary, TotalVisitorCountSummary, BillingStats } from './Analytics';

export const EventAnalytics = ({ clientId, eventId }: { clientId: string; eventId: string }) => {
    const context = useContext(GlobalContext);
    const [billingStatsDate, setBillingStatsDate] = useState<[moment.Moment, moment.Moment]>([
        moment().startOf('month'),
        moment().endOf('month'),
    ]);
    const [billingStats, setBillingStats] = useState({ totalParticipantMinutes: 0, totalUserRegistrations: 0 });
    const [totalVisitorCountSummary, setTotalVisitorCountSummary] = useState<EventsAnalytics.TotalVisitorCountSummary>({
        totalRegistrationCount: 0,
        activeVisitorCount: 0,
        inactiveVisitorCount: 0,
    });
    const [onlineVisitorCountSummary, setOnlineVisitorCountSummary] = useState<
        EventsAnalytics.OnlineVisitorCountSummary
    >({});
    const [videoCallSummary, setVideoCallSummary] = useState<EventsAnalytics.VideoCallSummary>({});

    const refreshBillingStats = async () => {
        try {
            const billingStats = await apiRequester.getStatsForBilling({
                clientId,
                eventId,
                startTime: billingStatsDate[0].toDate().getTime(),
                endTime: billingStatsDate[1].toDate().getTime(),
            });
            setBillingStats(billingStats);
        } catch (err) {
            handleError(err);
        }
    };

    useEffect(() => {
        context.user?.roles?.includes('super-admin') && refreshBillingStats();
    }, [billingStatsDate]);

    const refreshData = async () => {
        try {
            const [totalVisitorCountSummary, onlineVisitorCountSummary, videoCallSummary] = await Promise.all([
                apiRequester.getTotalVisitorCountSummary({ clientId, eventId }),
                apiRequester.getOnlineVisitorCount({ clientId, eventId }),
                apiRequester.getVideoCallSummary({ clientId, eventId }),
            ]);
            setTotalVisitorCountSummary(totalVisitorCountSummary);
            setOnlineVisitorCountSummary(onlineVisitorCountSummary);
            setVideoCallSummary(videoCallSummary);
        } catch (err) {
            handleError(err);
        }
    };

    useEffect(() => {
        refreshData();

        const refreshDataInterval = setInterval(refreshData, 30000);

        return () => {
            clearInterval(refreshDataInterval);
        };
    }, [clientId, eventId]);

    return (
        <>
            {context.user?.roles?.includes('super-admin') && (
                <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
                    <Col xs={24}>
                        <BillingStats
                            totalParticipantMinutes={billingStats.totalParticipantMinutes}
                            totalUserRegistrations={billingStats.totalUserRegistrations}
                            billingStatsDate={billingStatsDate}
                            setBillingStatsDate={setBillingStatsDate}
                        />
                    </Col>
                </Row>
            )}
            <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
                <Col xs={12}>
                    <VideoCallSummary videoCallSummary={videoCallSummary} />
                </Col>
                <Col xs={12}>
                    <TotalVisitorCountSummary totalVisitorCountSummary={totalVisitorCountSummary} />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24}>
                    <OnlineVisitorCountSummary onlineVisitorCountSummary={onlineVisitorCountSummary} />
                </Col>
            </Row>
        </>
    );
};

export default EventAnalytics;
