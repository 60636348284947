import React, { useEffect, useState } from 'react';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Drawer, Form, Input, Select, Space, Table } from 'antd';
import download from 'downloadjs';
import { Parser } from 'json2csv';

import { apiRequester, handleError, handleSuccess } from '../utility';

const { Option } = Select;

export const FormModule = ({
    clientId,
    eventId,
    boothId,
    moduleId,
    target = 'event',
}: {
    clientId?: string;
    eventId?: string;
    boothId?: string;
    moduleId?: string;
    target: 'event' | 'booth';
}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [showResponseDrawer, setShowResponseDrawer] = useState(false);
    const [moduleFullRecord, setModuleFullRecord] = useState<
        Modules.Module & { moduleData: Modules.FormsModuleData }
    >();

    useEffect(() => {
        try {
            target === 'event' &&
                apiRequester
                    .getEventModule(clientId!, eventId!, moduleId!)
                    .then(moduleRecord =>
                        setModuleFullRecord(moduleRecord as Modules.Module & { moduleData: Modules.FormsModuleData }),
                    )
                    .catch(handleError);

            target === 'booth' &&
                apiRequester
                    .getBoothModule(clientId!, eventId!, boothId!, moduleId!)
                    .then(moduleRecord =>
                        setModuleFullRecord(moduleRecord as Modules.Module & { moduleData: Modules.FormsModuleData }),
                    )
                    .catch(handleError);
        } catch (err) {
            handleError(err);
        }
    }, [moduleId]);

    const submitQuestions = async (questions: any) => {
        try {
            setLoading(true);
            target === 'event' &&
                (await apiRequester.updateEventFormModuleQuestions(clientId!, eventId!, moduleId!, questions));
            target === 'booth' &&
                (await apiRequester.updateBoothFormModuleQuestions(
                    clientId!,
                    eventId!,
                    boothId!,
                    moduleId!,
                    questions,
                ));
            handleSuccess('Questions have been updated!');
            setLoading(false);
        } catch (err) {
            setLoading(false);
            handleError(err);
        }
    };

    const processTableData = (responses: Modules.FormResponse[]) => {
        const processedData = responses.map(response => {
            const returnData: {
                [key: string]: string;
            } = {
                key: response?._id!,
                submitterName: response.submitter?.firstName + ' ' + response.submitter?.lastName,
                submitterEmail: response.submitter?.emailId!,
            };
            response.response.map(r => {
                const questionId = r.question!;
                returnData[questionId!] = r.singleAnswer!;
            });
            return returnData;
        });
        return processedData;
    };

    const cols = (moduleFullRecord?.moduleData?.questions || []).map((question, index) => {
        return { key: question._id, title: question.question, dataIndex: question._id };
    });

    if (moduleFullRecord) {
        const questions = moduleFullRecord?.moduleData?.questions || [];
        const modifiedQuestions = questions.map(question => {
            return {
                ...question,
                required: question.required ? 'true' : 'false',
            };
        });
        form.setFieldsValue({
            questions: modifiedQuestions,
        });
    }

    const getQuestion = (id?: string) => {
        const question = moduleFullRecord?.moduleData?.questions.find(q => q._id === id);
        return question?.question;
    };

    const prepareCSVData = () => {
        const fields = (moduleFullRecord?.moduleData?.questions || []).map(question => {
            return question.question!;
        });
        fields.push('Submitter Name', 'Submitter Email', 'Submitter User ID');
        const opts = { fields };
        const parser = new Parser(opts);
        const csv = parser.parse(
            (moduleFullRecord?.moduleData?.responses || []).map(response => {
                const returnData: { [key: string]: string } = {
                    'Submitter Name': response.submitter?.firstName + ' ' + response.submitter?.lastName,
                    'Submitter Email': response.submitter?.emailId!,
                    'Submitter User ID': response.submitter?._id!,
                };
                response.response.map(r => {
                    returnData[getQuestion(r.question)!] = r.singleAnswer!;
                });
                return returnData;
            }),
        );
        download(csv, 'form-response.csv', 'text/csv');
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1.5rem' }}>
                <Button onClick={() => setShowResponseDrawer(true)} loading={loading} style={{ marginRight: '1.5rem' }}>
                    View Responses
                </Button>
                <Button onClick={prepareCSVData} loading={loading} style={{ marginRight: '1.5rem' }}>
                    Export Responses (CSV)
                </Button>
                <Button type="primary" onClick={form.submit} loading={loading}>
                    Save Questions
                </Button>
            </div>
            <Card style={{ width: '100%' }}>
                <Form form={form} onFinish={submitQuestions}>
                    <Form.List name="questions">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(field => (
                                    <Space key={field.key} style={{ display: 'flex' }} align="baseline">
                                        <Form.Item
                                            {...field}
                                            name={[field.name, '_id']}
                                            fieldKey={[field.fieldKey, '_id']}
                                            hidden={true}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'type']}
                                            fieldKey={[field.fieldKey, 'type']}
                                            rules={[{ required: true, message: 'Missing first name' }]}
                                            label="Type"
                                            initialValue="text"
                                        >
                                            <Select>
                                                <Option value="text">Open Ended Question</Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'question']}
                                            fieldKey={[field.fieldKey, 'question']}
                                            rules={[{ required: true, message: 'Please provide a question to ask.' }]}
                                            label="Question"
                                        >
                                            <Input.TextArea style={{ width: '30vw' }} />
                                        </Form.Item>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'required']}
                                            fieldKey={[field.fieldKey, 'required']}
                                            label="Required"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select if this question is optional or not.',
                                                },
                                            ]}
                                            initialValue="true"
                                        >
                                            <Select>
                                                <Option value="true">True</Option>
                                                <Option value="false">False</Option>
                                            </Select>
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        block
                                        onClick={() => add()}
                                        icon={<PlusOutlined />}
                                        loading={loading}
                                    >
                                        Add field
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form>
            </Card>
            <Drawer
                title="Form Responses"
                placement="bottom"
                closable={true}
                onClose={() => setShowResponseDrawer(false)}
                visible={showResponseDrawer}
                height="90vh"
            >
                <Table
                    columns={[
                        ...cols,
                        {
                            title: 'Submitter Name',
                            dataIndex: 'submitterName',
                        },
                        {
                            title: 'Submitter Email',
                            dataIndex: 'submitterEmail',
                        },
                    ]}
                    pagination={false}
                    dataSource={processTableData(moduleFullRecord?.moduleData?.responses || [])}
                />
            </Drawer>
        </>
    );
};

export default FormModule;
