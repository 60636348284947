import React, { useEffect, useState } from 'react';

import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import {
    Button,
    Card,
    Divider,
    Typography,
    Upload,
    List,
    Space,
    Row,
    Col,
    Drawer,
    Form,
    Input,
    Select,
    Popconfirm,
} from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import LocalizedStrings from 'react-localization';
import store from 'store';

import { apiRequester, handleError, handleSuccess } from '../utility';

const strings = new LocalizedStrings({
    en: {
        uploaded: 'Product has been uploaded',
        deleted: 'Product has been deleted',
        thumbUploaded: 'Product image has been uploaded.',
        thumbUploadError: 'Product image could not be uploaded. Please try again.',
        changePreview: 'Change',
        uploadProduct: 'Upload new product document',
        addProduct: 'Add Product',
        noData: 'No products found',
        yes: 'Yes',
        no: 'No',
        sure: 'Are you sure you want to delete?',
    },
    de: {
        uploaded: 'Produkt wurde hochgeladen',
        deleted: 'Produkt wurde gelöscht',
        thumbUploaded: 'Produktbild wurde hochgeladen.',
        thumbUploadError: 'Produktbild konnte nicht hochgeladen werden. Bitte versuche es erneut.',
        changePreview: 'Veränderung',
        uploadProduct: 'Neues Produktdokument hochladen',
        addProduct: 'Produkt hinzufügen',
        noData: 'Keine Dateien vorhanden',
        yes: 'Ja',
        no: 'Nein',
        sure: 'Sind Sie sicher, dass Sie löschen möchten?',
    },
});

export const ProductsModule = ({
    clientId,
    eventId,
    boothId,
    moduleId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
}) => {
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState<Products.Product[]>([]);
    const [visibleAddDrawer, setVisibleAddDrawer] = useState(false);
    const [visibleEditDrawer, setVisibleEditDrawer] = useState(false);
    const [addForm] = Form.useForm();
    const [editForm] = Form.useForm();

    const refreshProducts = async () => {
        try {
            setLoading(true);
            const products = await apiRequester.getProducts({ clientId, eventId, boothId, moduleId });
            const productsWithNewImageHashes = products.map(product => {
                if (product?.imageType === 'DIRECT_UPLOAD' && product.imageLink?.link)
                    return {
                        ...product,
                        imageLink: {
                            ...product.imageLink,
                            link: product.imageLink.link,
                        },
                    };
                else return product;
            });
            setProducts(productsWithNewImageHashes);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        refreshProducts();
    }, [clientId, eventId, boothId, moduleId]);

    const deleteProduct = async ({ productId }: { productId: string }) => {
        try {
            setLoading(true);
            await apiRequester.deleteProduct({ clientId, eventId, boothId, moduleId, productId });
            await refreshProducts();
            handleSuccess('Product has been deleted');
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const addProduct = async () => {
        try {
            setLoading(true);
            const productData = addForm.getFieldsValue();
            await apiRequester.createProduct({ clientId, eventId, boothId, moduleId, ...productData });
            await refreshProducts();
            setVisibleAddDrawer(false);
            handleSuccess('Product has been added');
            addForm.resetFields();
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const editProduct = async () => {
        try {
            setLoading(true);
            const productData = editForm.getFieldsValue();
            await apiRequester.updateProduct({ clientId, eventId, boothId, moduleId, ...productData });
            await refreshProducts();
            setVisibleEditDrawer(false);
            editForm.resetFields();
            handleSuccess('Product has been updated');
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const preFillEditForm = (product: Products.Product) => {
        editForm.resetFields();
        editForm.setFieldsValue({
            productId: product._id,
            name: product.name,
            imageType: product.imageType,
            link: product.imageLink?.link,
            document: product.document?.link,
        });
        setVisibleEditDrawer(true);
    };

    const fileProps = {
        name: 'file',
        onChange({ file, fileList }: { file?: any; fileList: any }) {
            if (file.status === 'done') {
                setLoading(false);
                handleSuccess('Product image has been updated');
                refreshProducts();
            } else if (file.status === 'uploading') {
                setLoading(true);
                return;
            } else if (file.status === 'error') setLoading(false);
        },
        headers: { Authorization: 'Bearer ' + store.get('token') },
    };

    const saveLink = async () => {
        try {
            setLoading(true);
            const link = editForm.getFieldValue('link');
            const productId = editForm.getFieldValue('productId');
            await apiRequester.updateProductImageIframeLink({ clientId, eventId, boothId, moduleId, productId, link });
            handleSuccess('Product link has been updated');
            await refreshProducts();
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    // const fileProps = {
    //     name: 'file',
    //     action: apiRequester.getFileUploadUrl({ clientId, eventId, boothId, moduleId }),
    //     onChange({ file, fileList }: { file?: any; fileList: any }) {
    //         if (file.status === 'done') {
    //             setLoading(false);
    //             updateFileList().catch(handleError);
    //             handleSuccess(strings.uploaded!);
    //         } else if (file.status === 'uploading') {
    //             setLoading(true);
    //             return;
    //         } else if (file.status === 'error') setLoading(false);
    //     },
    //     onRemove: async (file: any) => {
    //         const fileId = file.uid;
    //         apiRequester
    //             .deleteFile(clientId, eventId, boothId, moduleId, fileId)
    //             .then(updateFileList)
    //             .then(() => handleSuccess(strings.deleted!))
    //             .catch(handleError);
    //     },
    //     progress: {
    //         strokeColor: {
    //             '0%': '#108ee9',
    //             '100%': '#87d068',
    //         },
    //         strokeWidth: 3,
    //         format: (percent?: number) => `${parseFloat(percent?.toFixed(2) || '0')}%`,
    //     },
    //     headers: { Authorization: 'Bearer ' + store.get('token') },
    //     showUploadList: {
    //         showDownloadIcon: true,
    //         downloadIcon: 'download ',
    //         showRemoveIcon: true,
    //     },
    // };

    // const thumbnailProps = {
    //     name: 'file',
    //     onChange({ file, fileList }: { file?: any; fileList: any }) {
    //         if (file.status === 'done') {
    //             handleSuccess(strings.thumbUploaded!);
    //             updateFileList().catch(handleError);
    //             setLoading(false);
    //         } else if (file.status === 'uploading') {
    //             setLoading(true);
    //             return;
    //         } else if (file.status === 'error') {
    //             setLoading(false);
    //             handleError(strings.thumbUploadError);
    //         }
    //     },
    //     headers: { Authorization: 'Bearer ' + store.get('token') },
    // };

    const getFilePreview = (link: string) => {
        const fileExtension = link.split('?')[0].split('.').slice(-1)[0];
        if (fileExtension === 'pdf') return <iframe src={link} style={{ border: 0, width: '100%' }} />;
        else if (fileExtension === 'doc' || fileExtension === 'docx')
            return (
                <iframe
                    src={`https://docs.google.com/viewer?url=${encodeURIComponent(link)}&embedded=true`}
                    style={{ border: 0, width: '100%' }}
                />
            );
        else
            return (
                <iframe
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${link}`}
                    style={{ border: 0, width: '100%' }}
                />
            );
    };

    return (
        <>
            <Row gutter={[24, 24]}>
                <Col xs={24} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={() => setVisibleAddDrawer(true)}>
                        {strings.addProduct}
                    </Button>
                </Col>
                <Col xs={24}>
                    <List
                        locale={{ emptyText: strings.noData }}
                        loading={loading}
                        dataSource={products}
                        renderItem={product => (
                            <Card
                                size="small"
                                title={product.name}
                                style={{ marginBottom: '1.5rem' }}
                                extra={
                                    <Space>
                                        <Popconfirm
                                            placement="topLeft"
                                            title={strings.sure}
                                            onConfirm={() => deleteProduct({ productId: product._id! })}
                                            okText={strings.yes}
                                            cancelText={strings.no}
                                        >
                                            <Button size="small" danger>
                                                Delete
                                            </Button>
                                        </Popconfirm>
                                        <Button size="small" onClick={() => preFillEditForm(product)}>
                                            Edit
                                        </Button>
                                    </Space>
                                }
                            >
                                {product.imageType === 'DIRECT_UPLOAD' ? (
                                    <img src={product.imageLink?.link} style={{ maxWidth: '100%' }} />
                                ) : (
                                    <iframe src={product.imageLink?.link} style={{ maxWidth: '100%', border: 0 }} />
                                )}
                            </Card>
                        )}
                    />
                </Col>
            </Row>

            <Drawer
                title={strings.addProduct}
                placement="right"
                closable={false}
                onClose={() => setVisibleAddDrawer(false)}
                visible={visibleAddDrawer}
                width="620px"
                footer={
                    <Space style={{ marginRight: '8px' }}>
                        <Button onClick={() => setVisibleAddDrawer(false)}>Cancel</Button>{' '}
                        <Button type="primary" loading={loading} onClick={addProduct}>
                            Add
                        </Button>
                    </Space>
                }
                footerStyle={{ textAlign: 'right', marginRight: '8px' }}
                closeIcon={<CloseOutlined />}
                destroyOnClose={true}
            >
                <Form form={addForm}>
                    <Form.Item label="Name" name="name" labelCol={{ span: 6 }}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Image Type" name="imageType" labelCol={{ span: 6 }}>
                        <Select>
                            <Select.Option value="IFRAME">External Link</Select.Option>
                            <Select.Option value="DIRECT_UPLOAD">Image Upload</Select.Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Drawer>

            <Drawer
                title="Edit Product"
                placement="right"
                closable={false}
                onClose={() => setVisibleEditDrawer(false)}
                visible={visibleEditDrawer}
                width="620px"
                footer={
                    <Space style={{ marginRight: '8px' }}>
                        <Button onClick={() => setVisibleEditDrawer(false)}>Cancel</Button>{' '}
                        <Button type="primary" loading={loading} onClick={editProduct}>
                            Edit
                        </Button>
                    </Space>
                }
                footerStyle={{ textAlign: 'right', marginRight: '8px' }}
                closeIcon={<CloseOutlined />}
                destroyOnClose={true}
            >
                <Form form={editForm}>
                    <Form.Item label="productId" name="productId" labelCol={{ span: 6 }}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item label="Name" name="name" labelCol={{ span: 6 }}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Image Type" name="imageType" labelCol={{ span: 6 }}>
                        <Select disabled>
                            <Select.Option value="IFRAME">External Link</Select.Option>
                            <Select.Option value="DIRECT_UPLOAD">Image Upload</Select.Option>
                        </Select>
                    </Form.Item>
                    {editForm.getFieldValue('imageType') === 'DIRECT_UPLOAD' && (
                        <Form.Item label="Image" labelCol={{ span: 6 }}>
                            {editForm.getFieldValue('link') && (
                                <>
                                    <img
                                        src={editForm.getFieldValue('link')}
                                        style={{ maxHeight: '200px', maxWidth: '100%' }}
                                    />
                                    <br />
                                </>
                            )}
                            <Upload
                                accept="image/png, image/jpeg"
                                multiple={false}
                                maxCount={1}
                                action={apiRequester.getProductImageUploadUrl({
                                    clientId,
                                    eventId,
                                    boothId,
                                    moduleId,
                                    productId: editForm.getFieldValue('productId'),
                                })}
                                {...fileProps}
                            >
                                <Button type="link" style={{ marginLeft: 0, paddingLeft: 0 }}>
                                    Click to Upload
                                </Button>
                            </Upload>
                        </Form.Item>
                    )}
                    {editForm.getFieldValue('imageType') === 'IFRAME' && (
                        <Form.Item label="Image" labelCol={{ span: 6 }} name="link">
                            <Input
                                onChange={e => editForm.setFieldsValue({ link: e.target.value })}
                                defaultValue={editForm.getFieldValue('link')}
                            />
                            <Button style={{ marginLeft: 0, paddingLeft: 0 }} type="link" onClick={saveLink}>
                                Save Link
                            </Button>
                        </Form.Item>
                    )}
                    <Form.Item label="Document" labelCol={{ span: 6 }}>
                        {editForm.getFieldValue('document') && getFilePreview(editForm.getFieldValue('document'))}
                        <Upload
                            accept="application/pdf, image/jpeg, .doc, .docx, .ppt, .pptx"
                            multiple={false}
                            maxCount={1}
                            action={apiRequester.getProductDocumentUploadUrl({
                                clientId,
                                eventId,
                                boothId,
                                moduleId,
                                productId: editForm.getFieldValue('productId'),
                            })}
                            {...fileProps}
                        >
                            <Button type="link" style={{ marginLeft: 0, paddingLeft: 0 }}>
                                Click to Upload
                            </Button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );

    // return files?.length ? (
    //     <Card>
    //         {JSON.stringify(products)}
    //         <Upload
    //             {...fileProps}
    //             defaultFileList={files}
    //             fileList={files}
    //             listType="text"
    //             itemRender={(originNode: React.ReactElement, file: UploadFile, fileList?: object[]) => {
    //                 return (
    //                     <>
    //                         <div style={{ display: 'flex', flexDirection: 'column' }}>
    //                             <div>
    //                                 <Typography.Text copyable>Product ID: {file.uid}</Typography.Text>
    //                             </div>
    //                             <div>
    //                                 <Upload
    //                                     {...thumbnailProps}
    //                                     action={apiRequester.getFileThumbnailUploadUrl({
    //                                         fileId: file.uid,
    //                                         clientId,
    //                                         eventId,
    //                                         boothId,
    //                                         moduleId,
    //                                     })}
    //                                     showUploadList={false}
    //                                 >
    //                                     Product Image:{' '}
    //                                     <Button type="link" size="small" loading={loading}>
    //                                         {strings.changePreview}
    //                                     </Button>
    //                                 </Upload>
    //                             </div>
    //                             <div style={{ display: 'flex', alignItems: 'baseline' }}>
    //                                 <span>asd</span>
    //                                 {originNode}
    //                             </div>
    //                         </div>
    //                         <Divider />
    //                     </>
    //                 );
    //             }}
    //             style={{ width: '100%' }}
    //         >
    //             <Button type="primary" icon={<UploadOutlined />} loading={loading} style={{ marginBottom: '1.5rem' }}>
    //                 {strings.uploadProduct}
    //             </Button>
    //         </Upload>
    //     </Card>
    // ) : (
    //     <Upload {...fileProps} defaultFileList={files}>
    //         <Button block type="primary" icon={<UploadOutlined />} loading={loading}>
    //             {strings.uploadProduct}
    //         </Button>
    //     </Upload>
    // );
};

export default ProductsModule;
