import React, { useCallback, useContext, useEffect, useState } from 'react';

import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import {
    Button,
    Card,
    Col,
    DatePicker,
    Drawer,
    Dropdown,
    Form,
    Input,
    List,
    Menu,
    Radio,
    Row,
    Space,
    Spin,
    Table,
    Tag,
    Tooltip,
    Typography,
} from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { useForm } from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal/Modal';
import { debounce } from 'lodash';
import moment from 'moment';
import { string } from 'prop-types';
import Pubnub from 'pubnub';
import { usePubNub } from 'pubnub-react';
import LocalizedStrings from 'react-localization';
import store from 'store';

import { GlobalContext } from '../context/GlobalContextProvider';
import { useBoothCommunication, useChatList } from '../hooks';
import { apiRequester, handleError, handleSuccess, showNotification } from '../utility';
import { NOTIFIER_MESSAGE_IDS, VIDEO_CALL_ACTIONS, generateNotifierMessage } from '../utility/NotifierMessages';
import MissedCalls from './MissedCalls';
import VideoCallRequestCard from './VideoCallRequestCard';
import VideoRoomCard from './VideoRoomCard';

const strings = new LocalizedStrings({
    en: {
        visitorEndCall: 'Visitor ended the video call',
        newRequest: 'New video call request',
        callEnded: 'Call has been ended',
        requestNotFound: 'Request not found',
        alreadyJoined: "You've already joined the video call",
        callAccepted: 'Call has been accepted. Please proceed to call',
        callRejected: 'Call has been rejected',
        ongoing: 'ONGOING',
        accepted: 'ACCEPTED',
        rejected: 'REJECTED',
        requested: 'REQUESTED',
        ended: 'ENDED',
        private: 'PRIVATE',
        merged: 'MERGED',
        join: 'Join',
        accept: 'Accept',
        reject: 'Reject',
        end: 'End',
        cancel: 'Cancel',
        requestSent: 'Video call request has been sent to the visitor',
        selectTwoCalls: 'Please select at least 2 accepted requests to merge',
        callsMerged: 'Calls have been merged',
        mergeCalls: 'Merge Calls',
        newCall: 'New Video Call',
        visitorsAtBooth: 'Visitors currently at your booth',
        anonymousUser: 'Anonymous User',
        id: 'ID',
        requester: 'Requester',
        respondent: 'Respondent',
        status: 'Status',
        actions: 'Actions',
        type: 'Type',
        requestedAt: 'Requested',
        initiate: 'Initiate',
        scheduledRoomsCreated: 'Scheduled Room has been created',
        activeRequests: 'Active Requests',
        activeRooms: 'Active Rooms',
        rejectedRequests: 'Rejected Requests',
        missedCalls: 'Missed Calls',
        closedRooms: 'Closed Rooms',
        scheduledRooms: 'New Scheduled Room',
    },
    de: {
        visitorEndCall: 'Der Besucher hat den Videoanruf beendet',
        newRequest: 'Neue Videoanruf-Anfrage',
        callEnded: 'Anruf wurde beendet',
        requestNotFound: 'Anfrage nicht gefunden',
        alreadyJoined: 'Sie sind dem Videoanruf bereits beigetreten',
        callAccepted: 'Der Anruf wurde akzeptiert. Bitte fortfahren.',
        callRejected: 'Der Anruf wurde abgelehnt.',
        ongoing: 'Laufend',
        accepted: 'Akzeptiert',
        rejected: 'Abgelehnt',
        requested: 'Angefragt',
        ended: 'Beendet',
        private: 'Privat',
        merged: 'Zusammengefügt',
        join: 'Beitreten',
        accept: 'Akzeptieren',
        reject: 'Ablehnen',
        end: 'Beenden',
        cancel: 'Abbrechen',
        requestSent: 'Die Videoanruf-Anfrage wurde an den Besucher gesendet',
        selectTwoCalls: 'Bitte wählen Sie mindestens zwei akzeptierte Anfragen aus, um sie zusammenzufügen',
        callsMerged: 'Anrufe wurden zusammengefügt',
        mergeCalls: 'Anrufe zusammenfügen',
        newCall: 'Neuer Videoanruf',
        visitorsAtBooth: 'Der Besucher befindet sich aktuell auf Ihrem Stand',
        anonymousUser: 'Anonymer Benutzer',
        id: 'ID',
        requester: 'Anfragender',
        respondent: 'Antwortender',
        status: 'Status',
        actions: 'Aktionen',
        type: 'Typ',
        requestedAt: 'Anfrageuhrzeit',
        initiate: 'Initiieren',
        scheduledRoomsCreated: 'Scheduled Room has been created',
        activeRequests: 'Aktive Anfragen',
        activeRooms: 'Aktive Räume',
        rejectedRequests: 'Abgelehnte Anfragen',
        missedCalls: 'Verpasste Anrufe',
        closedRooms: 'Geschlossene Räume',
        scheduledRooms: 'Neuen terminierten Raum erstellen',
    },
});

const ActionButton = ({
    title,
    type = 'link',
    danger = false,
    onClick,
}: {
    title: string;
    type?: 'link';
    danger?: boolean;
    onClick: () => Promise<void>;
}) => {
    const [localLoading, setLocalLoading] = useState(false);
    const localOnClickHandler = async () => {
        try {
            setLocalLoading(true);
            await onClick();
            setLocalLoading(false);
        } catch (err) {
            setLocalLoading(false);
            console.log(err);
        }
    };
    return (
        <Button type={type} loading={localLoading} onClick={localOnClickHandler} danger={danger}>
            {title}
        </Button>
    );
};

export const VideoCallModule = ({
    clientId,
    eventId,
    boothId,
    moduleId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
}) => {
    const [activeVideoCallRequests, setActiveVideoCallRequests] = useState<Modules.VideoCallRequest[]>([]);
    const [rejectedVideoCallRequests, setRejectedVideoCallRequests] = useState<Modules.VideoCallRequest[]>([]);
    const [videoRooms, setVideoRooms] = useState<Modules.VideoRoom[]>([]);
    const [closedVideoRooms, setClosedVideoRooms] = useState<Modules.VideoRoom[]>([]);
    const [visibleNewCallDrawer, setVisibleNewCallDrawer] = useState(false);
    const [scheduleRoomModal, setScheduleRoomModal] = useState(false);
    const [onlineUsers, setOnlineUsers] = useState<Users.User[]>([]);
    const [loading, setLoading] = useState(false);
    const context = useContext(GlobalContext);
    const boothVisitorsChannel = `booth-visitors.${boothId}`;
    const boothOperatorsChannel = `booth-operators.${boothId}`;
    const [subMenuState, setSubMenuState] = useState<
        'active-requests' | 'rejected-requests' | 'rooms' | 'missed-calls' | 'closed-rooms'
    >('active-requests');
    const pubnub = usePubNub();
    const [createScheduledRoomForm] = useForm();
    const { addListener, removeListener, hereNow, publish } = useBoothCommunication();
    const [moduleLoading, setModuleLoading] = useState(false);
    let loggedInUser: Users.User;

    useEffect(() => {
        loggedInUser = store.get('user');
    }, []);

    const refreshOnlineVisitorList = async () => {
        if (hereNow) {
            const response = await hereNow({ channels: [boothVisitorsChannel] });
            const channel = response?.channels[boothVisitorsChannel];
            const occupants = channel?.occupants;
            const onlineUserIds = occupants?.map(occupant => occupant.uuid);
            const globalUserList = await context.refreshUserList(onlineUserIds);
            const localUserList: Users.User[] = [];
            onlineUserIds.map(userId => {
                const user = globalUserList[userId];
                if (user.roles?.includes('visitor')) localUserList.push(user);
            });
            setOnlineUsers(localUserList);
        }
    };

    const updateVideoCallRequests = async () => {
        const requests = await apiRequester.getVideoCallRequests(clientId, eventId, boothId, moduleId);
        const filteredActiveRequests = requests
            .reverse()
            .filter(
                request =>
                    ['accepted', 'requested'].includes(typeof request === 'string' ? request : request?.status!) &&
                    !request.room,
            );
        setActiveVideoCallRequests(filteredActiveRequests);
        const [videoRooms, closedVideoRooms] = await Promise.all([
            apiRequester.viewVideoRooms({
                clientId,
                eventId,
                boothId,
                moduleId,
                status: 'active',
            }),
            apiRequester.viewVideoRooms({
                clientId,
                eventId,
                boothId,
                moduleId,
                status: 'ended',
            }),
        ]);
        setVideoRooms(videoRooms);
        setClosedVideoRooms(closedVideoRooms);
    };

    const debouncedUpdateVideoCallRequests = useCallback(
        debounce(() => {
            updateVideoCallRequests();
        }, 3000),
        [],
    );

    const messageListener = (messageEvent: Pubnub.MessageEvent) => {
        const { message } = messageEvent;
        const { id } = message;
        if (id) console.log({ module: 'video-call-module', id: id });
        switch (id) {
            case NOTIFIER_MESSAGE_IDS.VIDEO_CALL:
                debouncedUpdateVideoCallRequests();
                break;
            default:
                break;
        }
    };

    const presenceListener = async (event: Pubnub.PresenceEvent) => {
        const { channel } = event;
        if (channel === boothVisitorsChannel) refreshOnlineVisitorList();
    };

    const listeners = {
        presence: presenceListener,
        message: messageListener,
    };

    useEffect(() => {
        setModuleLoading(true);
        setSubMenuState('active-requests');
        Promise.all([debouncedUpdateVideoCallRequests(), refreshOnlineVisitorList()])
            .then(refreshOnlineVisitorList)
            .catch(handleError)
            .then(() => setModuleLoading(false));

        addListener && addListener(listeners);
        return () => {
            removeListener && removeListener(listeners);
        };
    }, [moduleId]);

    const requestVideoCall = async ({
        respondent,
    }: {
        respondent: { id: string; firstName?: string; lastName?: string };
    }) => {
        try {
            setLoading(true);
            await apiRequester.requestVideoCall(clientId, eventId, boothId, moduleId, respondent.id);
            await debouncedUpdateVideoCallRequests();
            publish &&
                (await publish({
                    channel: `users.${respondent.id}`,
                    message: generateNotifierMessage.videoRequest({
                        booth: {
                            id: boothId,
                            name: context.activeBooth?.name,
                        },
                        requester: {
                            id: context.user?._id!,
                            firstName: context.user?.firstName,
                            lastName: context.user?.lastName,
                        },
                        respondent,
                        action: VIDEO_CALL_ACTIONS.NEW_REQUEST_FROM_OPERATOR_TO_VISITOR,
                    }),
                }));
            publish &&
                (await publish({
                    channel: boothOperatorsChannel,
                    message: generateNotifierMessage.videoRequest({
                        booth: {
                            id: boothId,
                            name: context.activeBooth?.name,
                        },
                        requester: {
                            id: context.user?._id!,
                            firstName: context.user?.firstName,
                            lastName: context.user?.lastName,
                        },
                        respondent,
                        action: VIDEO_CALL_ACTIONS.NEW_REQUEST_FROM_OPERATOR_TO_VISITOR,
                    }),
                }));
            handleSuccess(strings.requestSent!);
            setVisibleNewCallDrawer(false);
            setLoading(false);
        } catch (err) {
            handleError(err);
            setLoading(false);
        }
    };

    const handleSubMenuClick = (e: any) => {
        setSubMenuState(e.key);
    };

    const createScheduledRoom = async (values: any) => {
        try {
            setLoading(true);
            const { name, duration, isGroup, canBeScheduledByVisitor, maxVisitorCount } = values;
            const startTime = duration[0].toString();
            const endTime = duration[1].toString();
            await apiRequester.createScheduledVideoRoom({
                clientId,
                eventId,
                boothId,
                moduleId,
                name,
                isBroadcast: false,
                isGroup,
                startTime,
                endTime,
                canBeScheduledByVisitor,
                maxVisitorCount,
            });
            await debouncedUpdateVideoCallRequests();
            handleSuccess(strings.scheduledRoomsCreated!);
            setScheduleRoomModal(false);
            setLoading(false);
        } catch (err) {
            handleError(err);
            setLoading(false);
        }
    };

    const acceptAllPutInFirstRoom = async () => {
        try {
            setLoading(true);

            const allRequests = await apiRequester.getVideoCallRequests(clientId, eventId, boothId, moduleId);
            const requests = allRequests.filter(
                request =>
                    ['requested'].includes(typeof request === 'string' ? request : request?.status!) && !request.room,
            );

            // Create room
            const roomResponse = await apiRequester.createScheduledVideoRoom({
                clientId: clientId!,
                eventId: eventId!,
                boothId: boothId!,
                moduleId: moduleId!,
                isBroadcast: false,
                isGroup: true,
                startTime: moment().subtract(1, 'hour').toString(),
                endTime: moment().add(10, 'days').toString(),
                maxVisitorCount: 1000,
                canBeScheduledByVisitor: false,
                name: new Date().getTime().toString(),
            });
            const room = roomResponse.room;

            const chunkSize = 50;
            for (let i = 0; i < requests.length; i += chunkSize) {
                const requestsChunk = requests.slice(i, i + chunkSize);
                console.log(
                    `Handling requests for: `,
                    requestsChunk.map(r => r._id),
                );
                await Promise.all(
                    requestsChunk.map(async request => {
                        const requestId = request._id;

                        // Accept request
                        await apiRequester.acceptVideoCallRequest({
                            clientId: clientId!,
                            eventId: eventId!,
                            boothId: boothId!,
                            moduleId: moduleId!,
                            requestId: requestId!,
                        });

                        await apiRequester.addVisitorToRoom({
                            clientId: clientId!,
                            eventId: eventId!,
                            boothId: boothId!,
                            moduleId: moduleId!,
                            requestId: requestId!,
                            roomId: room._id!,
                        });

                        // Notify visitor
                        await pubnub.publish({
                            channel: `users.${request.visitor?._id}`,
                            message: generateNotifierMessage.videoRequest({
                                booth: {
                                    id: boothId!,
                                    name: context.activeBooth?.name,
                                },
                                respondent: {
                                    id: context.user?._id!,
                                    firstName: context.user?.firstName,
                                    lastName: context.user?.lastName,
                                },
                                action: VIDEO_CALL_ACTIONS.OPERATOR_ACCEPTED,
                            }),
                        });
                    }),
                );
            }

            // Inform all operators
            await pubnub.publish({
                channel: boothOperatorsChannel,
                message: generateNotifierMessage.videoRequest({
                    booth: {
                        id: boothId!,
                        name: context.activeBooth?.name,
                    },
                    respondent: {
                        id: loggedInUser?._id!,
                        firstName: loggedInUser?.firstName,
                        lastName: loggedInUser?.lastName,
                    },
                    action: VIDEO_CALL_ACTIONS.OPERATOR_ACCEPTED,
                }),
            });

            await debouncedUpdateVideoCallRequests();
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Spin spinning={moduleLoading}>
            <div style={{ marginBottom: '1.5rem', display: 'flex', justifyContent: 'space-between' }}>
                <Menu
                    onClick={handleSubMenuClick}
                    selectedKeys={[subMenuState]}
                    mode="horizontal"
                    style={{ backgroundColor: 'transparent' }}
                >
                    <Menu.Item key="active-requests" testing-id="active-requests-tab">
                        {strings.activeRequests}{' '}
                        <Tag
                            color="red"
                            testing-id="total-requests-tag"
                            testing-value={JSON.stringify(activeVideoCallRequests.length)}
                            testing-loading={JSON.stringify(loading)}
                        >
                            {activeVideoCallRequests.length}
                        </Tag>
                    </Menu.Item>
                    <Menu.Item key="active" testing-id="active-rooms-tab">
                        {strings.activeRooms}
                    </Menu.Item>
                    <Menu.Item key="rejected-requests">{strings.rejectedRequests}</Menu.Item>
                    <Menu.Item key="missed-calls">{strings.missedCalls}</Menu.Item>
                    <Menu.Item key="closed-rooms">{strings.closedRooms}</Menu.Item>
                </Menu>
                <Space>
                    <Button
                        type="primary"
                        onClick={() => acceptAllPutInFirstRoom()}
                        loading={loading}
                        testing-id="accept-all-in-new-room-btn"
                        testing-loading={JSON.stringify(loading)}
                    >
                        <PlusOutlined /> Accept all in new room
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => setScheduleRoomModal(true)}
                        loading={loading}
                        testing-id="new-scheduled-video-room-btn"
                    >
                        <PlusOutlined /> {strings.scheduledRooms}
                    </Button>
                    <Button type="primary" onClick={() => setVisibleNewCallDrawer(true)} loading={loading}>
                        <PlusOutlined /> {strings.newCall}
                    </Button>
                </Space>
            </div>

            {subMenuState === 'active-requests' ? (
                <List
                    loading={loading}
                    dataSource={activeVideoCallRequests}
                    grid={{ gutter: 16, column: 3 }}
                    pagination={{ pageSize: 20, position: 'both' }}
                    renderItem={item => (
                        <List.Item>
                            <VideoCallRequestCard
                                rooms={videoRooms}
                                item={item}
                                refreshVideoCallRequestList={debouncedUpdateVideoCallRequests}
                            />
                        </List.Item>
                    )}
                />
            ) : subMenuState === 'rejected-requests' ? (
                <List
                    loading={loading}
                    dataSource={rejectedVideoCallRequests}
                    grid={{ gutter: 16, column: 3 }}
                    renderItem={item => (
                        <List.Item>
                            <VideoCallRequestCard
                                rooms={videoRooms}
                                item={item}
                                refreshVideoCallRequestList={debouncedUpdateVideoCallRequests}
                            />
                        </List.Item>
                    )}
                />
            ) : subMenuState === 'missed-calls' ? (
                <MissedCalls clientId={clientId} eventId={eventId} boothId={boothId} moduleId={moduleId} />
            ) : subMenuState === 'closed-rooms' ? (
                <List
                    loading={loading}
                    dataSource={closedVideoRooms}
                    grid={{ gutter: 16, column: 3 }}
                    renderItem={item => (
                        <List.Item>
                            <VideoRoomCard
                                room={item}
                                clientId={clientId}
                                eventId={eventId}
                                boothId={boothId}
                                moduleId={moduleId}
                                refreshVideoCallRequestList={debouncedUpdateVideoCallRequests}
                                ended={true}
                            />
                        </List.Item>
                    )}
                />
            ) : (
                <List
                    loading={loading}
                    dataSource={videoRooms}
                    grid={{ gutter: 16, column: 3 }}
                    renderItem={item => (
                        <List.Item>
                            <VideoRoomCard
                                room={item}
                                clientId={clientId}
                                eventId={eventId}
                                boothId={boothId}
                                moduleId={moduleId}
                                refreshVideoCallRequestList={debouncedUpdateVideoCallRequests}
                            />
                        </List.Item>
                    )}
                />
            )}

            <Drawer
                title={strings.newRequest}
                placement="right"
                closable={false}
                onClose={() => setVisibleNewCallDrawer(false)}
                visible={visibleNewCallDrawer}
                width="50vw"
                destroyOnClose={true}
            >
                <List
                    header={<div>{strings.visitorsAtBooth}</div>}
                    bordered
                    dataSource={onlineUsers}
                    renderItem={item => (
                        <List.Item>
                            <Typography.Text>
                                <Avatar src={item?.profilePicture?.link} size="large">
                                    <UserOutlined />
                                </Avatar>{' '}
                                {item?.firstName && item?.lastName ? (
                                    item?.firstName + ' ' + item?.lastName
                                ) : item?.firstName ? (
                                    item?.firstName
                                ) : (
                                    <>
                                        {strings.anonymousUser}{' '}
                                        <Tag>
                                            {strings.id}: {item._id}
                                        </Tag>
                                    </>
                                )}
                            </Typography.Text>
                            <ActionButton
                                onClick={async () =>
                                    requestVideoCall({
                                        respondent: {
                                            id: item._id!,
                                            firstName: item.firstName,
                                            lastName: item.lastName,
                                        },
                                    })
                                }
                                title={strings.initiate!}
                            />
                        </List.Item>
                    )}
                />
            </Drawer>
            <Modal
                title="Create new Scheduled Video Room"
                visible={scheduleRoomModal}
                onCancel={() => setScheduleRoomModal(false)}
                onOk={() => createScheduledRoomForm.submit()}
                footer={
                    <Button
                        onClick={() => createScheduledRoomForm.submit()}
                        type="primary"
                        loading={loading}
                        testing-id="create-video-room-submit-btn"
                    >
                        Create
                    </Button>
                }
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={createScheduledRoomForm}
                    onFinish={createScheduledRoom}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[{ required: true, message: 'Please input your name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="isGroup"
                        label="Size"
                        rules={[{ required: true, message: 'Please input size of the room!' }]}
                    >
                        <Radio.Group name="isGroup">
                            <Radio testing-id="private-room-option" value={false}>
                                Private
                            </Radio>
                            <Radio testing-id="group-room-option" value={true}>
                                Group
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name="canBeScheduledByVisitor"
                        label="Visitors can book"
                        rules={[
                            { required: true, message: 'Please select if visitors can schedule calls in this room!' },
                        ]}
                    >
                        <Radio.Group name="canBeScheduledByVisitor">
                            <Radio testing-id="allow-visitors-to-schedule-calls-option" value={true}>
                                Yes
                            </Radio>
                            <Radio testing-id="disallow-visitors-to-schedule-calls-option" value={false}>
                                No
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name="maxVisitorCount"
                        label="Max. visitor count"
                        rules={[{ required: true, message: 'Please enter maximum number of visitors!' }]}
                        extra="Enter 0 for no limit"
                    >
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item
                        name="duration"
                        label="Duration"
                        rules={[{ required: true, message: 'Please input duration!' }]}
                    >
                        <DatePicker.RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                    </Form.Item>
                </Form>
            </Modal>
        </Spin>
    );
};

export default VideoCallModule;
