import React, { useEffect, useState } from 'react';

import { DownloadOutlined, ExportOutlined, MoreOutlined } from '@ant-design/icons';
import { Card, DatePicker, Space } from 'antd';
import download from 'downloadjs';
import { Parser } from 'json2csv';
import moment, { Moment } from 'moment';
import LocalizedStrings from 'react-localization';
import {
    Tooltip,
    ResponsiveContainer,
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Bar,
    Area,
    LabelList,
    AreaChart,
} from 'recharts';

import { handleError } from '../../utility';

const strings = new LocalizedStrings({
    en: {
        visitorCount: 'Online Visitor Count - Historical',
    },
    de: {
        visitorCount: '',
    },
});
const { RangePicker } = DatePicker;

export const OnlineVisitorCountHistory = ({
    onlineVisitorCountHistory,
    dates,
    changeDates,
}: {
    onlineVisitorCountHistory: EventsAnalytics.OnlineVisitorCountHistory;
    dates: [Moment, Moment];
    changeDates: any;
}) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!onlineVisitorCountHistory || !onlineVisitorCountHistory.length) setLoading(true);
        else setLoading(false);
    }, [onlineVisitorCountHistory]);

    const getGraphData = () => {
        return onlineVisitorCountHistory.map(count => ({
            name: count.label,
            value: count.count,
        }));
    };

    // const downloadHistory = async () => {
    //     try {
    //         const actions = await apiRequester.getOnlineVisitorCountHistoryDownload({
    //             clientId: context.activeClient?._id!,
    //             eventId: context.activeEvent?._id!,
    //             boothId: context.activeBooth?._id!,
    //             startDate: dates[0].toISOString(),
    //             endDate: dates[0].toISOString(),
    //             timeZone: moment.tz.guess(),
    //         });
    //         console.log(actions);
    //         const fields = [
    //             'Time',
    //             'Visitor User ID',
    //             'Visitor First Name',
    //             'Visitor Last Name',
    //             'Visitor Email',
    //             'Visitor Company',
    //             'Visitor Designation',
    //         ];
    //         const opts = { fields };
    //         const parser = new Parser(opts);
    //         const csv = parser.parse(
    //             actions.map(action => ({
    //                 Time: action.createdAt,
    //                 'Visitor User ID': typeof action?.user === 'string' ? action.user : action.user?._id,
    //                 'Visitor First Name': typeof action?.user === 'string' ? '' : action?.user?.firstName || '',
    //                 'Visitor Last Name': typeof action?.user === 'string' ? '' : action?.user?.lastName || '',
    //                 'Visitor Email': typeof action?.user === 'string' ? '' : action?.user?.emailId || '',
    //                 'Visitor Company': typeof action?.user === 'string' ? '' : action?.user?.company || '',
    //                 'Visitor Designation': typeof action?.user === 'string' ? '' : action?.user?.designation || '',
    //             })),
    //         );
    //         download(csv, 'online-visitor-count-history.csv', 'text/csv');
    //     } catch (err) {
    //         handleError(err);
    //     }
    // };

    const downloadHistory = () => {
        try {
            const fields = ['Time', 'Label', 'Online Visitor Count'];
            const opts = { fields };
            const parser = new Parser(opts);
            const csv = parser.parse(
                onlineVisitorCountHistory.map(history => ({
                    Time: moment(history.time).toString(),
                    Label: history.label,
                    'Online Visitor Count': history.count,
                })),
            );
            download(csv, 'online-visitor-count-history.csv', 'text/csv');
        } catch (err) {
            handleError(err);
        }
    };

    return (
        <Card
            title={strings.visitorCount}
            size="small"
            loading={loading}
            extra={
                <Space>
                    <RangePicker value={dates} format="DD-MM-YYYY" onChange={changeDates} onOk={changeDates} />
                    <DownloadOutlined onClick={downloadHistory} />
                </Space>
            }
        >
            <ResponsiveContainer width="100%" height={350}>
                <BarChart data={getGraphData()}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis allowDecimals={false} />
                    <Tooltip />
                    <Bar dataKey="value" fill="#1853DB">
                        <LabelList dataKey="value" position="top" />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </Card>
    );
};

export default OnlineVisitorCountHistory;
