import React, { useContext, useEffect, useState } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Drawer, Form, Input, List, Modal, Space, Tag, Tooltip, Typography } from 'antd';
import moment from 'moment';
import { string } from 'prop-types';
import Pubnub from 'pubnub';
import { usePubNub } from 'pubnub-react';
import LocalizedStrings from 'react-localization';

import { GlobalContext } from '../context/GlobalContextProvider';
import { useBoothCommunication } from '../hooks';
import { apiRequester, handleError, handleSuccess } from '../utility';
import {
    generateNotifierMessage,
    LIVE_STREAM_ACTIONS,
    NOTIFIER_MESSAGE_IDS,
    VIDEO_CALL_ACTIONS,
} from '../utility/NotifierMessages';

const strings = new LocalizedStrings({
    en: {
        questions: 'Questions',
        questionApproved: 'Question has been approved',
        approve: 'Approve',
        reject: 'Reject',
        approved: 'Approved',
        rejected: 'Rejected',
        rejectQuestion: 'Reject Question',
        question: 'Question',
        reason: 'Reason',
        questionRejected: 'Question has been rejected',
        archiveAll: 'Archive All Questions',
        questionsArchived: 'Questions have been archived',
        questionsArchive: 'Questions Archive',
    },
    de: {
        questions: 'Fragen',
        questionApproved: 'Ihre Frage wurde zugelassen',
        approve: 'Zulassen',
        reject: 'Ablehnen',
        approved: 'Zugelassen',
        rejected: 'Abgelehnt',
        rejectQuestion: 'Frage ablehnen',
        question: 'Frage',
        reason: 'Grund',
        questionRejected: 'Frage wurde abgelehnt',
        archiveAll: 'Alle Fragen archivieren',
        questionsArchived: 'Fragen wurden archiviert',
        questionsArchive: 'Fragen Archiv',
    },
});

export const LiveStreamingQuestions = ({
    clientId,
    eventId,
    boothId,
    moduleId,
    roomId,
    view = 'moderatorVideo',
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    roomId: string;
    view?: 'moderatorVideo' | 'speakerView';
}) => {
    const [loading, setLoading] = useState(false);
    const [questions, setQuestions] = useState<Modules.LiveStreamQuestion[]>([]);
    const [questionToReject, setQuestionToReject] = useState<Modules.LiveStreamQuestion>();
    const [rejectReason, setRejectReason] = useState<string>();
    const [module, setModule] = useState<Modules.Module<Modules.LiveStreamingData>>();
    const { addListener, removeListener, publish } = useBoothCommunication();
    const context = useContext(GlobalContext);

    const refreshModule = async () => {
        if (moduleId && typeof moduleId === 'string') {
            const module = await apiRequester.getBoothModule<Modules.LiveStreamingData>(
                clientId,
                eventId,
                boothId,
                moduleId,
            );
            setModule(module);
        }
    };

    const refreshQuestions = async () => {
        if (moduleId && typeof moduleId === 'string') {
            const questions = await apiRequester.getLiveStreamQuestions({
                clientId,
                eventId,
                boothId,
                moduleId,
                status: view === 'moderatorVideo' ? 'all' : 'approved',
            });
            setQuestions(
                questions
                    .filter(q => {
                        if (q.status === 'approved') return true;
                        else if (!context.user?.roles?.includes('sponsor')) return true;
                        else return false;
                    })
                    .reverse(),
            );
        }
    };

    const messageListener = async (messageEvent: Pubnub.MessageEvent) => {
        const { message } = messageEvent;
        const { id, action } = message;
        if (id) console.log({ module: 'live-streaming-module', id, action });
        if (action && id === NOTIFIER_MESSAGE_IDS.LIVE_STREAMING) await refreshQuestions();
    };

    const listeners = {
        message: messageListener,
    };

    useEffect(() => {
        refreshModule();
        console.log(roomId);
        if (roomId) {
            setLoading(true);
            refreshQuestions()
                .catch(handleError)
                .finally(() => setLoading(false));

            addListener && addListener(listeners);
        }

        return () => {
            if (roomId) {
                removeListener && removeListener(listeners);
            }
        };
    }, [roomId]);

    const generateDescription = (question: Modules.LiveStreamQuestion) => {
        const name =
            typeof question.askedBy === 'string'
                ? question.askedBy
                : question.askedBy?.firstName + ' ' + question.askedBy?.lastName;
        const askedAt = moment(question.createdAt).fromNow();
        return `asked by ${name} ${askedAt}`;
    };

    const sendApproveNotification = async ({ channelName }: { channelName: string }) => {
        publish &&
            (await publish({
                channel: channelName,
                message: generateNotifierMessage.liveStreamQuestion({
                    booth: {
                        id: boothId,
                    },
                    action: LIVE_STREAM_ACTIONS.QUESTION_APPROVED,
                }),
            }));
    };

    const approveQuestion = async (
        setButtonLoading: React.Dispatch<React.SetStateAction<boolean>>,
        question: Modules.LiveStreamQuestion,
    ) => {
        try {
            setButtonLoading(true);
            const questionId = question._id!;
            await apiRequester.approveLiveStreamQuestion({ clientId, eventId, boothId, moduleId, roomId, questionId });
            handleSuccess(strings.questionApproved!);
            await refreshQuestions();
            await sendApproveNotification({ channelName: `booth-visitors.${boothId}` });
            await sendApproveNotification({ channelName: `booth-operators.${boothId}` });
        } catch (err) {
            handleError(err);
        } finally {
            setButtonLoading(false);
        }
    };

    const rejectQuestion = async () => {
        try {
            const questionId = questionToReject?._id!;
            await apiRequester.rejectLiveStreamQuestion({
                clientId,
                eventId,
                boothId,
                moduleId,
                questionId,
                rejectReason,
            });
            handleSuccess(strings.questionRejected!);
            await refreshQuestions();
            await sendApproveNotification({ channelName: `booth-visitors.${boothId}` });
            await sendApproveNotification({ channelName: `booth-operators.${boothId}` });
        } catch (err) {
            handleError(err);
        } finally {
            setRejectReason('');
            setQuestionToReject(undefined);
        }
    };

    const generateApproveButton = (question: Modules.LiveStreamQuestion) => {
        const [buttonLoading, setButtonLoading] = useState(false);
        if (question.status === 'approved') return <Tag color="green">{strings.approved}</Tag>;
        else if (question.status === 'rejected')
            return (
                <Tooltip title={question?.rejectReason} placement="left">
                    <Tag color="red" icon={<InfoCircleOutlined />}>
                        {strings.rejected}
                    </Tag>
                </Tooltip>
            );
        else
            return (
                <Space>
                    <Button
                        size="small"
                        loading={buttonLoading}
                        onClick={() => approveQuestion(setButtonLoading, question)}
                        type="link"
                    >
                        {strings.approve}
                    </Button>
                    <Button
                        size="small"
                        loading={buttonLoading}
                        onClick={() => setQuestionToReject(question)}
                        type="link"
                        danger
                    >
                        {strings.reject}
                    </Button>
                </Space>
            );
    };

    const QuestionList = () => {
        return (
            <List
                loading={loading}
                size="small"
                dataSource={questions}
                renderItem={item => (
                    <List.Item extra={generateApproveButton(item)}>
                        <List.Item.Meta description={generateDescription(item)} title={item.question} />
                    </List.Item>
                )}
            />
        );
    };

    const archiveAllQuestions = async () => {
        try {
            await apiRequester.archiveLiveStreamQuestions({
                clientId,
                eventId,
                boothId,
                moduleId,
            });
            handleSuccess(strings.questionsArchived!);
            await refreshQuestions();
            await refreshModule();
        } catch (err) {
            handleError(err);
        }
        await refreshModule();
    };

    const QuestionArchive = () => {
        const archiveQuestions = module?.moduleData?.questionArchives || [];
        return (
            <List
                loading={loading}
                size="small"
                dataSource={archiveQuestions.reverse()}
                renderItem={archive => (
                    <List.Item>
                        <Tag>Archive time</Tag> {moment(new Date(archive?.createdAt!)).format('LLL')}{' '}
                        <Button href={archive.link} download type="link" target="_blank">
                            Download
                        </Button>
                    </List.Item>
                )}
            />
        );
    };

    return (
        <>
            <Modal
                visible={questionToReject && questionToReject._id ? true : false}
                title={strings.rejectQuestion}
                onCancel={() => setQuestionToReject(undefined)}
                okText={strings.reject}
                okButtonProps={{ danger: true }}
                onOk={() => rejectQuestion()}
            >
                <Typography.Text strong>{strings.question}</Typography.Text>: {questionToReject?.question}
                <Divider />
                <Form.Item>
                    <Input.TextArea
                        placeholder={strings.reason}
                        onChange={e => setRejectReason(e.target.value)}
                        value={rejectReason}
                    />
                </Form.Item>
            </Modal>
            {view === 'moderatorVideo' && (
                <Card
                    title={strings.questions}
                    style={{ marginBottom: '1.5rem' }}
                    extra={
                        !context.user?.roles?.includes('sponsor') && [
                            <Button
                                type="dashed"
                                size="small"
                                onClick={archiveAllQuestions}
                                key="archive-live-stream-questions-btn"
                            >
                                {strings.archiveAll}
                            </Button>,
                        ]
                    }
                >
                    <QuestionList />
                </Card>
            )}
            {view === 'speakerView' && <QuestionList />}
            <Card style={{ marginBottom: '1.5rem' }} title={strings.questionsArchive}>
                <QuestionArchive />
            </Card>
        </>
    );
};

export default LiveStreamingQuestions;
