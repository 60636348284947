import React, { useContext, useEffect, useState } from 'react';

import { CloseOutlined, DeleteOutlined, PlusCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import {
    Button as AntdButton,
    Card,
    Collapse,
    DatePicker,
    Divider,
    Drawer,
    Form,
    Input as AntdInput,
    Popconfirm,
    Select,
    Space,
    Switch,
    Tag,
    Tooltip,
    Typography,
} from 'antd';
import { Link, navigate } from 'gatsby';
import moment from 'moment';
import LocalizedStrings from 'react-localization';
import store from 'store';
import styled from 'styled-components';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess } from '../utility';

const { Paragraph, Title } = Typography;

const strings = new LocalizedStrings({
    en: {
        eventCreated: 'Event has been created',
        clientUpdated: 'Client info has been updated',
        eventDeleted: 'Event has been deleted',
        yetToStart: 'YET TO START',
        ended: 'ENDED',
        ongoing: 'ONGOING',
        filter: 'Filter events by name and id',
        addEvent: 'Add New Event',
        edit: 'Edit',
        view: 'View',
        startTime: 'Start Time',
        endTime: 'End Time',
        created: 'Created At',
        description: 'Description',
        add: 'Add',
        enterClientName: 'Please enter client name',
        enterClientDescription: 'Please enter client description',
        staffs: 'Staffs',
        enterStartTime: 'Please enter event start time',
        enterEndTime: 'Please enter event end time',
        availableMods: 'Available event modules',
        auth: 'Authentication',
        authDescription:
            'This allows admins to add user authentication to events. During registration users will be asked for name, email, password, mobile number and company name. During login visitors will have to use their email and password to enter the event.',
        editEvent: 'Edit Event',
        delete: 'Delete',
        save: 'Save',
        cancel: 'Cancel',
        name: 'Name',
        eventRootAddress: 'Event Address',
        yes: 'Yes',
        no: 'No',
        sure: 'Are you sure you want to delete?',
    },
    de: {
        eventCreated: 'Event wurde angelegt',
        clientUpdated: 'Kundeninfo wurde aktualisiert',
        eventDeleted: 'Event wurde gelöscht',
        yetToStart: 'Started bald',
        ended: 'Beendet',
        ongoing: 'Laufend',
        filter: 'Events nach Namen oder ID filtern',
        addEvent: 'Neues Event hinzufügen',
        edit: 'Bearbeiten',
        view: 'Ansehen',
        startTime: 'Beginn',
        endTime: 'Ende',
        created: 'Erstellt am',
        description: 'Beschreibung',
        add: 'Hinzufügen',
        enterClientName: 'Bitte geben Sie den Kundennamen ein',
        enterClientDescription: 'Bitte geben Sie eine Kundenbeschreibung ein',
        staffs: 'Mitarbeiter',
        enterStartTime: 'Bitte geben Sie die Startzeit des Events ein',
        enterEndTime: 'Bitte geben Sie ein, wann das Event endet',
        availableMods: 'Verfügbare Event-Module',
        auth: 'Authentifizierung',
        authDescription:
            'Dies erlaubt den Administratoren, Benutzer zu Events hinzuzufügen. Bei der Registrierung werden die Benutzer aufgefordert, Namen, E-Mail-Adresse, Mobilnummer, Unternehmensnamen und Passwort anzugeben. Beim Login müssen die Benutzer ihren Benutzernamen und Passwort angeben, um zum Event zu gelangen. ',
        editEvent: 'Event bearbeiten',
        delete: 'Löschen',
        save: 'Speichern',
        cancel: 'Abbrechen',
        name: 'Name',
        eventRootAddress: 'Event Address',
        yes: 'Ja',
        no: 'Nein',
        sure: 'Sind Sie sicher, dass Sie löschen möchten?',
    },
});

const Input = styled(AntdInput)`
    height: 3rem;
`;

const Button = styled(AntdButton)`
    height: 3rem;
`;

export const Events = ({ clientId }: { clientId?: string | null }) => {
    const context = useContext(GlobalContext);
    const [visibleAddDrawer, setVisibleAddDrawer] = useState(false);
    const [visibleEditDrawer, setVisibleEditDrawer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState('');
    const [users, setUsers] = useState<Users.User[]>();
    const [addForm] = Form.useForm();
    const [editForm] = Form.useForm();
    const loggedInUser = store.get('user') as Users.User;

    useEffect(() => {
        setLoading(true);
        if (!clientId) navigate('/');
        else {
            apiRequester
                .getEvents(clientId)
                .then(events => context.setEvents(events.length ? events : []))
                .then(() =>
                    loggedInUser.roles?.includes('super-admin') ||
                    loggedInUser.roles?.includes('admin') ||
                    loggedInUser.roles?.includes('staff')
                        ? apiRequester.getOperators(clientId!)
                        : [],
                )
                .then(users => setUsers(users))
                .then(() => setLoading(false))
                .then(() => context.setActiveClient({ _id: clientId }))
                .catch(handleError);
        }
    }, []);

    const showAddDrawer = () => {
        setVisibleAddDrawer(true);
    };

    const onCloseAddDrawer = () => {
        setVisibleAddDrawer(false);
    };

    const showEditDrawer = (event: Events.Event) => {
        editForm.setFieldsValue({
            ...event,
            staffs: event.staffs?.map(staff => {
                return `${staff._id} - ${staff.firstName} ${staff.lastName} - ${staff.emailId}`;
            }),
        });
        setVisibleEditDrawer(true);
    };

    const onCloseEditDrawer = () => {
        setVisibleEditDrawer(false);
    };

    const onFinishAdding = async (event: {
        name: string;
        description?: string;
        staffs: string[];
        startTime: Date;
        endTime: Date;
    }) => {
        try {
            setLoading(true);
            event = {
                ...event,
                staffs: event.staffs?.map(staff => staff.split(' - ')[0]) || [],
            };
            const response = await apiRequester.createEvent(clientId!, event);
            await context.refreshEvents(clientId!);
            setLoading(false);
            addForm.resetFields();
            onCloseAddDrawer();
            handleSuccess(strings.eventCreated!);
        } catch (err) {
            handleError(err);
            setLoading(false);
        }
    };

    const onFinishEditing = async (event: {
        _id: string;
        name: string;
        description?: string;
        staffs: string[];
        startTime: Date;
        endTime: Date;
        profileFields: any[];
        eventRootAddress: string;
    }) => {
        try {
            setLoading(true);
            console.log(event);
            event = {
                ...event,
                staffs: event.staffs?.map(staff => staff.split(' - ')[0]) || [],
                profileFields: event.profileFields?.filter(field => field !== undefined),
            };
            const response = await apiRequester.editEvent(clientId!, event._id, {
                name: event.name,
                description: event.description,
                staffs: event.staffs,
                startTime: event.startTime,
                endTime: event.endTime,
                profileFields: event.profileFields,
                eventRootAddress: event.eventRootAddress,
            });
            await context.refreshEvents(clientId!);
            setLoading(false);
            editForm.resetFields();
            onCloseEditDrawer();
            handleSuccess(strings.clientUpdated!);
        } catch (err) {
            handleError(err);
            setLoading(false);
        }
    };

    const processTableData = (clients: Array<any & Events.Event>) => {
        return clients.map((client, i) => {
            const startTime = new Date(client.startTime!);
            const endTime = new Date(client.endTime!);
            return {
                key: 'client-list-item-' + i,
                ...client,
                startTime: moment(startTime),
                endTime: moment(endTime),
            };
        });
    };

    const filterTableData = (clients: Events.Event[]) => {
        if (!filter) return clients;
        else
            return clients.filter(client => {
                if (client.name?.toLowerCase().includes(filter) || client._id?.toLowerCase().includes(filter))
                    return true;
                else return false;
            });
    };

    const deleteEvent = async (eventId: string) => {
        try {
            setLoading(true);
            await apiRequester.deleteEvent(clientId!, eventId!);
            await context.refreshEvents(clientId!);
            onCloseEditDrawer();
            editForm.resetFields();
            handleSuccess(strings.eventCreated!);
            setLoading(false);
        } catch (err) {
            handleError(err);
        }
    };

    const decideEventStatus = (event?: Events.Event) => {
        const startTime = new Date(event?.startTime || '');
        const endTime = new Date(event?.endTime || '');
        const currentTime = new Date();
        if (currentTime < startTime) return <Tag>{strings.yetToStart}</Tag>;
        else if (currentTime >= startTime && currentTime <= endTime)
            return <Tag color="#87d068">{strings.ongoing}</Tag>;
        else return <Tag color="#f50">{strings.ended}</Tag>;
    };

    return (
        <>
            <div style={{ padding: '0rem 4rem 0 4rem' }}>
                <div style={{ display: 'flex' }}>
                    <Input
                        placeholder={strings.filter}
                        style={{
                            marginBottom: '1.5rem',
                            height: '3rem',
                            marginTop: '-1.5rem',
                            marginRight: loggedInUser?.roles?.includes('super-admin') ? '1.5rem' : undefined,
                        }}
                        onChange={e => setFilter(e.target.value)}
                    />
                    {loggedInUser?.roles?.includes('super-admin') && (
                        <Button
                            type="dashed"
                            icon={<PlusCircleTwoTone />}
                            onClick={showAddDrawer}
                            style={{ height: '3rem', marginTop: '-1.5rem' }}
                        >
                            {strings.addEvent}{' '}
                        </Button>
                    )}
                </div>
                {loading && (
                    <div style={{ display: 'flex' }}>
                        <Card style={{ width: '50%' }} loading={true}></Card>
                    </div>
                )}
                {!loading && (
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        {filterTableData(processTableData(context.events)).map((event, eventIndex) => {
                            return (
                                <Card
                                    title={
                                        <>
                                            <Tooltip title={`ID: ${event._id}`} placement="right">
                                                {event.name}
                                            </Tooltip>{' '}
                                            {decideEventStatus(event)}
                                        </>
                                    }
                                    key={`event-${eventIndex}`}
                                    extra={[
                                        !loggedInUser?.roles?.includes('sponsor') &&
                                            !loggedInUser?.roles?.includes('staff') && (
                                                <AntdButton
                                                    key="btn-1"
                                                    type="link"
                                                    onClick={() => showEditDrawer(event)}
                                                >
                                                    {strings.edit}
                                                </AntdButton>
                                            ),
                                        <AntdButton key="btn-2" type="primary">
                                            <Link to={`/event?clientId=${clientId}&eventId=${event._id}`}>
                                                {strings.view}
                                            </Link>
                                        </AntdButton>,
                                    ]}
                                    style={{ width: 'calc(50% - 0.75rem)', marginBottom: '1.5rem' }}
                                >
                                    <Form>
                                        {/* <Form.Item
                                            label="ID"
                                            labelCol={{ span: 12 }}
                                            labelAlign="left"
                                            style={{ textAlign: 'right' }}
                                            colon={false}
                                        >
                                            {event._id}
                                        </Form.Item> */}
                                        {/* <Form.Item
                                            label="Status"
                                            labelCol={{ span: 12 }}
                                            labelAlign="left"
                                            style={{ textAlign: 'right' }}
                                            colon={false}
                                        >
                                            {decideEventStatus(event)}
                                        </Form.Item> */}
                                        <Form.Item
                                            label={strings.startTime}
                                            labelCol={{ span: 12 }}
                                            labelAlign="left"
                                            style={{ textAlign: 'right' }}
                                            colon={false}
                                        >
                                            {moment(event.startTime).format('dddd, MMMM Do YYYY, h:mm:ss a')}
                                        </Form.Item>
                                        <Form.Item
                                            label={strings.endTime}
                                            labelCol={{ span: 12 }}
                                            labelAlign="left"
                                            style={{ textAlign: 'right' }}
                                            colon={false}
                                        >
                                            {moment(event.endTime).format('dddd, MMMM Do YYYY, h:mm:ss a')}
                                        </Form.Item>
                                        <Form.Item
                                            label={strings.created}
                                            labelCol={{ span: 12 }}
                                            labelAlign="left"
                                            style={{ textAlign: 'right' }}
                                            colon={false}
                                        >
                                            {moment(event.createdAt).fromNow()}
                                        </Form.Item>
                                        <Form.Item
                                            label={strings.description}
                                            labelCol={{ span: 12 }}
                                            labelAlign="left"
                                            style={{ textAlign: 'right' }}
                                            colon={false}
                                        >
                                            {event.description}
                                        </Form.Item>
                                    </Form>
                                </Card>
                            );
                        })}
                    </div>
                )}
            </div>

            {!loggedInUser?.roles?.includes('sponsor') && !loggedInUser?.roles?.includes('staff') && (
                <>
                    <Drawer
                        title={strings.addEvent}
                        placement="right"
                        closable={false}
                        onClose={onCloseAddDrawer}
                        visible={visibleAddDrawer}
                        width="620px"
                        footer={
                            <Space style={{ marginRight: '8px' }}>
                                <Button onClick={onCloseAddDrawer}>{strings.cancel}</Button>{' '}
                                <Button type="primary" loading={loading} onClick={addForm.submit}>
                                    {strings.add}
                                </Button>
                            </Space>
                        }
                        footerStyle={{ textAlign: 'right', marginRight: '8px' }}
                        closeIcon={<CloseOutlined />}
                    >
                        <Form form={addForm} onFinish={onFinishAdding}>
                            <Form.Item
                                label={strings.name}
                                name="name"
                                labelCol={{ span: 6 }}
                                rules={[{ required: true, message: strings.enterClientName }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="description"
                                label="Description"
                                labelCol={{ span: 6 }}
                                rules={[{ message: strings.enterClientDescription }]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                            <Form.Item label="Staffs" name="staffs" labelCol={{ span: 6 }}>
                                <Select mode="multiple">
                                    {users?.map(user => {
                                        return user.roles?.includes('staff') ? (
                                            <Select.Option
                                                key={`add-staff-${user._id}`}
                                                value={`${user._id} - ${user.firstName} ${user.lastName} - ${user.emailId}`}
                                            >
                                                {user.firstName} {user.lastName} - {user.emailId}
                                            </Select.Option>
                                        ) : undefined;
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={strings.startTime}
                                name="startTime"
                                labelCol={{ span: 6 }}
                                rules={[{ required: true, message: strings.enterStartTime }]}
                            >
                                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                            </Form.Item>
                            <Form.Item
                                label={strings.endTime}
                                name="endTime"
                                labelCol={{ span: 6 }}
                                rules={[{ required: true, message: strings.enterEndTime }]}
                            >
                                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                            </Form.Item>
                        </Form>
                        <Divider />
                        <Paragraph strong={true}>{strings.availableMods}</Paragraph>
                        <Space direction="vertical">
                            <Card>
                                <Paragraph strong={true}>{strings.auth}</Paragraph>
                                <Paragraph>{strings.authDescription}</Paragraph>
                            </Card>
                            {/* <Card>
                        <Paragraph strong={true}>Forms</Paragraph>
                        <Paragraph>
                            This allows admins to request visitors to fill a form at registration for additional
                            questions. All form submissions will be recorded and can be exported in CSV format.
                        </Paragraph>
                    </Card> */}
                        </Space>
                    </Drawer>

                    <Drawer
                        title={strings.editEvent}
                        placement="right"
                        closable={false}
                        onClose={onCloseEditDrawer}
                        visible={visibleEditDrawer}
                        width="620px"
                        footer={
                            <>
                                <Popconfirm
                                    placement="topLeft"
                                    title={strings.sure}
                                    onConfirm={() => deleteEvent(editForm.getFieldValue('_id'))}
                                    okText={strings.yes}
                                    cancelText={strings.no}
                                >
                                    <Button danger style={{ marginLeft: '8px' }}>
                                        {strings.delete}
                                    </Button>
                                </Popconfirm>
                                <Space style={{ marginRight: '8px' }}>
                                    <Button onClick={onCloseEditDrawer}>{strings.cancel}</Button>{' '}
                                    <Button type="primary" loading={loading} onClick={editForm.submit}>
                                        {strings.save}
                                    </Button>
                                </Space>
                            </>
                        }
                        footerStyle={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                        closeIcon={<CloseOutlined />}
                    >
                        <Form form={editForm} onFinish={onFinishEditing}>
                            <Form.Item
                                labelCol={{ span: 6 }}
                                label="ID"
                                name="_id"
                                rules={[{ required: true }]}
                                hidden={false}
                            >
                                <Input disabled={true} />
                            </Form.Item>
                            <Form.Item
                                label={strings.name}
                                name="name"
                                labelCol={{ span: 6 }}
                                rules={[{ required: true, message: strings.enterClientName }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="description"
                                label={strings.description}
                                labelCol={{ span: 6 }}
                                rules={[{ message: strings.enterClientDescription }]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                            <Form.Item label="Staffs" name="staffs" labelCol={{ span: 6 }}>
                                <Select mode="multiple">
                                    {users?.map(user => {
                                        return user.roles?.includes('staff') ? (
                                            <Select.Option
                                                key={`add-staff-${user._id}`}
                                                value={`${user._id} - ${user.firstName} ${user.lastName} - ${user.emailId}`}
                                            >
                                                {user.firstName} {user.lastName} - {user.emailId}
                                            </Select.Option>
                                        ) : undefined;
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={strings.startTime}
                                name="startTime"
                                labelCol={{ span: 6 }}
                                rules={[{ required: true, message: strings.enterStartTime }]}
                            >
                                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                            </Form.Item>
                            <Form.Item
                                label={strings.endTime}
                                name="endTime"
                                labelCol={{ span: 6 }}
                                rules={[{ required: true, message: strings.enterEndTime }]}
                            >
                                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                            </Form.Item>
                            <Form.Item
                                label={strings.eventRootAddress}
                                name="eventRootAddress"
                                labelCol={{ span: 6 }}
                                extra="Example: https://demo-event.virtooally.com"
                            >
                                <Input type="url" />
                            </Form.Item>
                        </Form>
                    </Drawer>
                </>
            )}
        </>
    );
};

export default Events;
