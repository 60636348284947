import React, { useEffect, useState } from 'react';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Drawer, Row, Space, Typography, Form, Input, Popconfirm } from 'antd';
import LocalizedStrings from 'react-localization';

import { apiRequester, handleError, handleSuccess } from '../utility';

const strings = new LocalizedStrings({
    en: {
        addLink: 'Add Link',
        add: 'Add',
        linkAdded: 'Link has been added',
        linkDeleted: 'Link has been deleted',
        yes: 'Yes',
        no: 'No',
        sure: 'Are you sure you want to delete?',
    },
    de: { yes: 'Ja', no: 'Nein', sure: 'Sind Sie sicher, dass Sie löschen möchten?' },
});

export const LinkModule = ({
    clientId,
    eventId,
    boothId,
    moduleId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
}) => {
    const [links, setLinks] = useState<Modules.LinkModuleLink[]>([]);
    const [loading, setLoading] = useState(false);
    const [addDrawer, setAddDrawer] = useState(false);
    const [addForm] = Form.useForm();

    const refreshLinks = async () => {
        try {
            setLoading(true);
            const links = await apiRequester.getLinkOfLinkModule({ clientId, eventId, boothId, moduleId });
            setLinks(links);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        refreshLinks();
    }, [clientId, eventId, boothId, moduleId]);

    const addLink = async ({ link, text }: { link: string; text?: string }) => {
        try {
            setLoading(true);
            await apiRequester.addLinkToLinkModule({
                clientId,
                eventId,
                boothId,
                moduleId,
                link,
                text,
            });
            setAddDrawer(false);
            handleSuccess(strings.linkAdded!);
            await refreshLinks();
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const deleteLink = async (linkId: string) => {
        try {
            setLoading(true);
            await apiRequester.deleteLinkOfLinkModule({
                clientId,
                eventId,
                boothId,
                moduleId,
                linkId,
            });
            setAddDrawer(false);
            handleSuccess(strings.linkDeleted!);
            await refreshLinks();
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Row gutter={[24, 24]}>
                <Col xs={24} style={{ textAlign: 'right' }}>
                    <Button loading={loading} type="primary" icon={<PlusOutlined />} onClick={() => setAddDrawer(true)}>
                        {strings.addLink}
                    </Button>
                </Col>
                {links.map((link, index) => {
                    return (
                        <Col key={link._id} xs={24}>
                            <Card
                                size="small"
                                extra={
                                    <Popconfirm
                                        placement="topLeft"
                                        title={strings.sure}
                                        onConfirm={() => deleteLink(link._id!)}
                                        okText={strings.yes}
                                        cancelText={strings.no}
                                    >
                                        <Button icon={<DeleteOutlined />} danger shape="circle" size="small" />
                                    </Popconfirm>
                                }
                                title={link.link}
                            >
                                {link.text}
                            </Card>
                        </Col>
                    );
                })}
            </Row>
            <Drawer
                closable={true}
                visible={addDrawer}
                footer={
                    <Button type="primary" onClick={addForm.submit} loading={loading}>
                        {strings.add}
                    </Button>
                }
                onClose={() => setAddDrawer(false)}
                title={strings.addLink}
                width="620px"
                footerStyle={{ textAlign: 'right' }}
            >
                <Form form={addForm} onFinish={addLink}>
                    <Form.Item name="link" label="Link" labelCol={{ span: 24 }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="text" label="Description" labelCol={{ span: 24 }}>
                        <Input.TextArea rows={4} />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

export default LinkModule;
