import React, { useContext, useEffect, useRef, useState } from 'react';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import LocalizedStrings from 'react-localization';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess } from '../utility';

const strings = new LocalizedStrings({
    en: {
        inviteToBooth: 'Invite visitors to your booth via Email',
        emailSent: 'Invitations have been sent',
        invite: 'Invite',
        emails: 'Emails',
    },
    de: {
        inviteToBooth: 'Laden Sie Besucher per E-Mail an Ihren Stand ein',
        emailSent: 'Einladungen wurden verschickt',
        invite: 'Einladen',
        emails: 'E-Mails',
    },
});

export const InviteToBoothModal = ({
    showModal,
    setShowModal,
}: {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [loading, setLoading] = useState(false);
    const [emailForm] = Form.useForm();
    const context = useContext(GlobalContext);

    const sendEmail = async ({ emails }: { emails: string[] }) => {
        try {
            setLoading(true);
            const boothId = context.activeBooth?._id;
            const eventId =
                typeof context.activeBooth?.event === 'string'
                    ? context.activeBooth?.event
                    : context.activeBooth?.event?._id!;
            const clientId =
                typeof context.activeBooth?.client === 'string'
                    ? context.activeBooth?.client
                    : context.activeBooth?.client?._id!;
            await apiRequester.inviteToBoothViaEmail({ clientId, eventId, boothId: boothId!, emails });
            handleSuccess(strings.emailSent);
            emailForm.resetFields();
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {}, []);

    return (
        <Modal
            closable={true}
            visible={showModal}
            onCancel={() => setShowModal(false)}
            footer={
                <Button type="primary" onClick={emailForm.submit} loading={loading}>
                    {strings.invite}
                </Button>
            }
            centered
            title={strings.inviteToBooth}
            destroyOnClose={true}
        >
            <Form form={emailForm} onFinish={sendEmail}>
                <Form.List name="emails">
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <Form.Item rules={[{ required: false }]} required={false} key={field.key}>
                                    <span
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Form.Item
                                            {...field}
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: "Please input visitor's email",
                                                },
                                            ]}
                                            noStyle
                                        >
                                            <Input
                                                placeholder="E-Mail"
                                                style={{ width: '100%', marginRight: '1rem' }}
                                            />
                                        </Form.Item>
                                        <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(field.name)}
                                        />
                                    </span>
                                </Form.Item>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    style={{ width: '100%' }}
                                    icon={<PlusOutlined />}
                                >
                                    Add E-Mail
                                </Button>
                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
        </Modal>
    );
};

export default InviteToBoothModal;
