/* eslint-disable react/prop-types */

import React, { MouseEventHandler, useEffect, useState } from 'react';

import {
    CheckOutlined,
    DeleteOutlined,
    EditOutlined,
    EllipsisOutlined,
    FileImageOutlined,
    PictureOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import {
    Button,
    Card,
    Descriptions,
    Divider,
    Input,
    List,
    Popover,
    Space,
    Spin,
    Tooltip,
    Typography,
    Upload,
} from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import moment from 'moment';
import LocalizedStrings from 'react-localization';
import store from 'store';

import { apiRequester, handleError, handleSuccess } from '../utility';

const strings = new LocalizedStrings({
    en: {
        uploaded: 'File has been uploaded',
        deleted: 'File has been deleted',
        thumbUploaded: 'Thumbnail file has been uploaded.',
        thumbUploadError: 'Thumbnail file could not be uploaded. Please try again.',
        change: 'Change',
        uploadFile: 'Upload new file',
        uploadThumbnail: 'Upload Thumbnail',
        removeThumbnail: 'Remove Thumbnail',
        renameFile: 'Rename File',
        fileRenamed: 'File has been renamed',
        thumbnailRemoved: 'Thumbnail has been removed',
    },
    de: {
        uploaded: 'Datei wurde hochgeladen',
        deleted: 'Datei wurde gelöscht',
        thumbUploaded: 'Vorschaudatei wurde hochgeladen',
        thumbUploadError: 'Vorschaudatei konnte nicht hochgeladen werden. Bitte versuchen Sie es erneut.',
        change: 'Veränderung',
        uploadFile: 'Neue Datei hochladen',
        uploadThumbnail: 'Miniaturbild hochladen',
        removeThumbnail: 'Miniaturansicht entfernen',
        renameFile: 'Datei umbenennen',
        fileRenamed: 'Datei wurde umbenannt',
        thumbnailRemoved: 'Vorschaubild wurde entfernt',
    },
});

export const FileDownloadsModule = ({
    clientId,
    eventId,
    boothId,
    moduleId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
}) => {
    const [files, setFiles] = useState<UploadFile[]>();
    const [loading, setLoading] = useState(false);

    const getFileThumbnail = (file: Modules.File) => {
        const thumbnailLink = file.thumbnail?.link;
        if (thumbnailLink) return thumbnailLink;
        else {
            const fileName = file.name;
            const extension = fileName?.split('.').pop();
            if (extension?.toLocaleLowerCase() === 'pdf') return '/pdf.png';
            else return '/file.png';
        }
    };

    const updateFileList = async () => {
        const files = await apiRequester.getFiles(clientId, eventId, boothId, moduleId);
        const modFiles = files?.map(file => {
            return {
                uid: file._id!,
                name: file.customName,
                url: file.link!,
                thumbUrl: getFileThumbnail(file),
                lastModifiedDate: new Date(file.updatedAt!),
                fileName: file.name,
            } as UploadFile;
        });
        setFiles(modFiles);
    };

    useEffect(() => {
        updateFileList().catch(handleError);
    }, [moduleId]);

    const fileProps = {
        name: 'file',
        action: apiRequester.getFileUploadUrl({ clientId, eventId, boothId, moduleId }),
        onChange({ file, fileList }: { file?: any; fileList: any }) {
            if (file.status === 'done') {
                setLoading(false);
                updateFileList().catch(handleError);
                handleSuccess(strings.uploaded!);
            } else if (file.status === 'uploading') {
                setLoading(true);
                return;
            } else if (file.status === 'error') setLoading(false);
        },
        headers: { Authorization: 'Bearer ' + store.get('token') },
        showUploadList: false,
    };

    const thumbnailProps = {
        name: 'file',
        onChange({ file, fileList }: { file?: any; fileList: any }) {
            if (file.status === 'done') {
                handleSuccess(strings.thumbUploaded!);
                updateFileList().catch(handleError);
                setLoading(false);
            } else if (file.status === 'uploading') {
                setLoading(true);
                return;
            } else if (file.status === 'error') {
                setLoading(false);
                handleError(strings.thumbUploadError);
            }
        },
        headers: { Authorization: 'Bearer ' + store.get('token') },
    };

    const removeThumbnail = async (fileId: string) => {
        try {
            setLoading(true);
            await apiRequester.removeFileThumbnail({ clientId, eventId, boothId, moduleId, fileId });
            handleSuccess(strings.thumbnailRemoved!);
            await updateFileList();
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const removeFile = async (fileId: string) => {
        try {
            setLoading(true);
            await apiRequester.deleteFile(clientId, eventId, boothId, moduleId, fileId);
            handleSuccess(strings.deleted!);
            await updateFileList();
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const EditableFileName = ({ file }: { file: UploadFile<any> }) => {
        const [editing, setEditing] = useState(false);
        const [newName, setNewName] = useState(file.name || file.fileName!);

        const renameFile = async () => {
            try {
                setLoading(true);
                await apiRequester.renameFile({
                    clientId,
                    eventId,
                    boothId,
                    moduleId,
                    fileId: file.uid,
                    customName: newName,
                });
                handleSuccess(strings.fileRenamed!);
                setEditing(false);
                await updateFileList();
            } catch (err) {
                handleError(err);
            } finally {
                setLoading(false);
            }
        };

        return (
            <>
                {editing ? (
                    <Input
                        name="customName"
                        defaultValue={file.name || file.fileName}
                        suffix={
                            <Button
                                style={{ color: 'green' }}
                                type="link"
                                icon={<CheckOutlined />}
                                onClick={() => renameFile()}
                            />
                        }
                        onChange={e => setNewName(e.target.value)}
                    />
                ) : (
                    <>
                        {file.name || file.fileName}
                        <Button
                            style={{ float: 'right', right: 0 }}
                            type="link"
                            icon={
                                <Tooltip title={strings.renameFile}>
                                    <EditOutlined onClick={() => setEditing(true)} />
                                </Tooltip>
                            }
                        />
                    </>
                )}
            </>
        );
    };

    return files?.length ? (
        <Spin spinning={loading}>
            <Upload {...fileProps} style={{ width: '100%' }}>
                <Button type="primary" icon={<UploadOutlined />} style={{ marginBottom: '1.5rem' }}>
                    {strings.uploadFile}
                </Button>
            </Upload>
            <List
                dataSource={files}
                grid={{ column: 2, gutter: 16 }}
                renderItem={(file, index) => (
                    <List.Item>
                        <Card
                            size="small"
                            title={file.fileName}
                            extra={
                                <Button
                                    type="link"
                                    icon={<DeleteOutlined />}
                                    danger
                                    onClick={() => removeFile(file.uid)}
                                />
                            }
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'baseline',
                                        alignItems: 'center',
                                    }}
                                >
                                    <img src={file.thumbUrl} height="100" style={{ marginRight: '15px' }} />
                                    <div>
                                        <Tooltip title={strings.uploadThumbnail}>
                                            <Upload
                                                {...thumbnailProps}
                                                action={apiRequester.getFileThumbnailUploadUrl({
                                                    fileId: file.uid,
                                                    clientId,
                                                    eventId,
                                                    boothId,
                                                    moduleId,
                                                })}
                                                showUploadList={false}
                                            >
                                                <Button type="link" icon={<UploadOutlined />} />
                                            </Upload>
                                        </Tooltip>
                                        <Tooltip title={strings.removeThumbnail}>
                                            <Button
                                                type="link"
                                                icon={<DeleteOutlined />}
                                                danger
                                                onClick={() => removeThumbnail(file.uid)}
                                            />
                                        </Tooltip>
                                    </div>
                                </div>

                                <Descriptions column={2} size="small" bordered style={{ flexGrow: 1 }}>
                                    <Descriptions.Item label="Custom Name" span={2}>
                                        <EditableFileName file={file} />
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Uploaded On" span={2}>
                                        {moment(file.lastModifiedDate).format('dddd, MMMM Do YYYY, h:mm:ss a')}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="ID">{file.uid}</Descriptions.Item>
                                    <Descriptions.Item label="Index">{index}</Descriptions.Item>
                                </Descriptions>
                            </div>
                        </Card>
                    </List.Item>
                )}
            />
        </Spin>
    ) : (
        <Upload {...fileProps} defaultFileList={files}>
            <Button block type="primary" icon={<UploadOutlined />}>
                {strings.uploadFile}
            </Button>
        </Upload>
    );
};

export default FileDownloadsModule;
