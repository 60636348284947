import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';

import { ExportOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Drawer, Timeline, Descriptions, Space, Card, Typography, Divider, Form, Select, Switch, Button } from 'antd';
import fileDownload from 'js-file-download';
import moment from 'moment';
import LocalizedStrings from 'react-localization';
import store from 'store';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError } from '../utility';

const { Option } = Select;

const strings = new LocalizedStrings({
    en: {
        visitorJourney: 'Visitor Journey',
    },
    de: {},
});

export const VisitorJourney = ({
    visitor,
    setVisitor,
}: {
    visitor?: Users.User;
    setVisitor: Dispatch<SetStateAction<Users.User | undefined>>;
}) => {
    const context = useContext(GlobalContext);
    const [visitorActions, setVisitorActions] = useState<VisitorActions.VisitorAction[]>([]);
    const [filters, setFilters] = useState<{ boothId?: string; showEventJoinLeaveActions: boolean }>({
        boothId: undefined,
        showEventJoinLeaveActions: false,
    });
    const [filterForm] = Form.useForm();
    const [boothList, setBoothList] = useState<Booths.Booth[]>([]);
    const user = store.get('user') as Users.User;
    const userRoles = user?.roles;
    const isSponsor = userRoles?.includes('sponsor');

    useEffect(() => {
        const event = visitor?.event;
        const clientId = typeof event?.client === 'string' ? event.client : event?.client?._id;
        const eventId = event?._id;
        const visitorId = visitor?._id;
        let periodicRefresh: NodeJS.Timeout;

        filterForm.setFieldsValue(filters);

        if (clientId && eventId && visitorId) {
            (isSponsor
                ? apiRequester.findAllVisitorActionsForSponsor({
                      clientId: clientId!,
                      eventId: eventId!,
                      visitorId: visitorId!,
                      boothId: context.activeBooth?._id!,
                  })
                : apiRequester.findAllVisitorActions({ clientId: clientId!, eventId: eventId!, visitorId: visitorId! })
            )
                .then((actions: VisitorActions.VisitorAction[]) => {
                    const showEventJoinLeaveActions = filterForm.getFieldValue('showEventJoinLeaveActions');
                    const boothId = filterForm.getFieldValue('boothId');
                    const filteredActions = actions
                        .filter(action => {
                            if (showEventJoinLeaveActions) return true;
                            else if (!action.booth) return false;
                            else return true;
                        })
                        .filter(action => {
                            if (boothId) {
                                if (
                                    boothId === (typeof action.booth === 'string' ? action.booth : action.booth?._id) ||
                                    action.booth
                                )
                                    return true;
                                else return false;
                            } else return true;
                        });
                    setVisitorActions(filteredActions);
                    const booths: Booths.Booth[] = [];
                    actions.map(action => {
                        const booth = action.booth;
                        if (booth && typeof booth !== 'string') {
                            const { _id } = booth;
                            const boothExists = booths.find(booth => booth._id === _id);
                            if (!boothExists) booths.push(booth);
                        }
                    });
                    setBoothList(booths);
                })
                .catch(handleError);

            periodicRefresh = setInterval(() => {
                (isSponsor
                    ? apiRequester.findAllVisitorActionsForSponsor({
                          clientId: clientId!,
                          eventId: eventId!,
                          visitorId: visitorId!,
                          boothId: context.activeBooth?._id!,
                      })
                    : apiRequester.findAllVisitorActions({
                          clientId: clientId!,
                          eventId: eventId!,
                          visitorId: visitorId!,
                      })
                )
                    .then((actions: VisitorActions.VisitorAction[]) => {
                        const showEventJoinLeaveActions = filterForm.getFieldValue('showEventJoinLeaveActions');
                        const boothId = filterForm.getFieldValue('boothId');
                        const filteredActions = actions
                            .filter(action => {
                                if (showEventJoinLeaveActions) return true;
                                else if (!action.booth) return false;
                                else return true;
                            })
                            .filter(action => {
                                if (boothId) {
                                    if (
                                        boothId ===
                                            (typeof action.booth === 'string' ? action.booth : action.booth?._id) ||
                                        action.booth
                                    )
                                        return true;
                                    else return false;
                                } else return true;
                            });
                        setVisitorActions(filteredActions);
                        const booths: Booths.Booth[] = [];
                        actions.map(action => {
                            const booth = action.booth;
                            if (booth && typeof booth !== 'string') {
                                const { _id } = booth;
                                const boothExists = booths.find(booth => booth._id === _id);
                                if (!boothExists) booths.push(booth);
                            }
                        });
                        setBoothList(booths);
                    })
                    .catch(handleError);
            }, 30000);
        }

        return () => {
            if (periodicRefresh) clearInterval(periodicRefresh);
        };
    }, [visitor?._id, filters]);

    const getModuleName = (module?: Modules.Module) => {
        if (!module) return '';
        else if (module.name) return module.name;
        else {
            switch (module.type) {
                case 'chat':
                    return 'Chat';
                case 'file-downloads':
                    return 'File Downloads';
                case 'forms':
                case 'forms-ext':
                case 'forms-siemens':
                    return 'Forms';
                case 'products':
                    return 'Products';
                case 'video':
                    return 'Videos';
                case 'video-call':
                    return 'Video Call';
                default:
                    return module._id?.toString();
            }
        }
    };

    const transformActionText = (visitorAction: VisitorActions.VisitorAction) => {
        const { action, booth, module } = visitorAction;
        const boothName = typeof booth === 'string' ? booth : booth?.name;
        const moduleName = typeof module === 'string' ? module : getModuleName(module);
        const roomName = visitorAction.request?.room?.name;
        const bookedRoomName = 'visitorAction.room?.name';
        const fileName = visitorAction.file?.name;
        switch (action) {
            case 'connect':
                return (
                    <>
                        Visitor{' '}
                        <Typography.Text type="success" strong>
                            joined
                        </Typography.Text>{' '}
                        the event
                    </>
                );

            case 'disconnect':
                return (
                    <>
                        Visitor{' '}
                        <Typography.Text type="danger" strong>
                            left
                        </Typography.Text>{' '}
                        the event
                    </>
                );

            case 'register':
                return (
                    <>
                        Visitor{' '}
                        <Typography.Text type="success" strong>
                            registered
                        </Typography.Text>{' '}
                        for the event
                    </>
                );

            case 'login':
                return (
                    <>
                        Visitor{' '}
                        <Typography.Text type="success" strong>
                            logged into
                        </Typography.Text>{' '}
                        the event
                    </>
                );

            case 'logout':
                return (
                    <>
                        Visitor{' '}
                        <Typography.Text type="danger" strong>
                            logged out
                        </Typography.Text>{' '}
                        from the event
                    </>
                );

            case 'enter-booth':
                return (
                    <>
                        Visitor{' '}
                        <Typography.Text type="success" strong>
                            entered
                        </Typography.Text>{' '}
                        booth:{' '}
                        <Typography.Text strong underline>
                            {JSON.stringify(boothName)}
                        </Typography.Text>
                    </>
                );

            case 'exit-booth':
                return (
                    <>
                        Visitor{' '}
                        <Typography.Text type="danger" strong>
                            left
                        </Typography.Text>{' '}
                        booth:{' '}
                        <Typography.Text strong underline>
                            {JSON.stringify(boothName)}
                        </Typography.Text>
                    </>
                );

            case 'request-video-call':
                return (
                    <>
                        Visitor requested for an instant video call on module:{' '}
                        <Typography.Text strong underline>
                            {JSON.stringify(moduleName)}
                        </Typography.Text>
                    </>
                );

            case 'end-video-room':
                return (
                    <>
                        Visitor cancelled a video call request on module:{' '}
                        <Typography.Text strong underline>
                            {JSON.stringify(moduleName)}
                        </Typography.Text>
                    </>
                );

            case 'leave-video-room':
                return (
                    <>
                        Visitor left an ongoing video call room:{' '}
                        <Typography.Text strong underline>
                            {roomName}
                        </Typography.Text>{' '}
                        on module:{' '}
                        <Typography.Text strong underline>
                            {JSON.stringify(moduleName)}
                        </Typography.Text>
                    </>
                );

            case 'join-video-room':
                return (
                    <>
                        Visitor joined a video call room:{' '}
                        <Typography.Text strong underline>
                            {roomName}
                        </Typography.Text>{' '}
                        on module:{' '}
                        <Typography.Text strong underline>
                            {JSON.stringify(moduleName)}
                        </Typography.Text>
                    </>
                );

            case 'book-video-call-slot':
                return (
                    <>
                        Visitor booked a video call slot in room:{' '}
                        <Typography.Text strong underline>
                            {bookedRoomName}
                        </Typography.Text>{' '}
                        on module:{' '}
                        <Typography.Text strong underline>
                            {JSON.stringify(moduleName)}
                        </Typography.Text>
                    </>
                );

            case 'initiate-chat':
                return (
                    <>
                        Visitor initiated chat on module:{' '}
                        <Typography.Text strong underline>
                            {JSON.stringify(moduleName)}
                        </Typography.Text>
                    </>
                );

            case 'download-file':
                return (
                    <>
                        Visitor downloaded file:{' '}
                        <Typography.Text strong underline>
                            {fileName}
                        </Typography.Text>{' '}
                        from module{' '}
                        <Typography.Text strong underline>
                            {JSON.stringify(moduleName)}
                        </Typography.Text>
                    </>
                );

            default:
                return <>{JSON.stringify(action) || 'Action not found'}</>;
        }
    };

    const generateTimelineItems = (visitorActions: VisitorActions.VisitorAction[]) => {
        return visitorActions.map(visitorAction => {
            return (
                <Timeline.Item key={visitorAction._id} dot={<RightCircleOutlined />}>
                    {transformActionText(visitorAction)}
                    <p>
                        <Typography.Text type="secondary">
                            at {moment(visitorAction.createdAt).format('LLL')}
                        </Typography.Text>
                    </p>
                </Timeline.Item>
            );
        });
    };

    const submitFilterForm = () => {
        setFilters(filterForm.getFieldsValue());
    };

    const exportUserJourney = () => {
        let fileContent = 'Time, Action\n';
        visitorActions.map(visitorAction => {
            let actionString: string | undefined = '';
            const { action, booth, module, createdAt } = visitorAction;
            const createdAtDate = new Date(createdAt!);
            const boothName = !booth || typeof booth === 'string' ? booth : booth.name;
            const moduleName = !module || typeof module === 'string' ? module : module.name ? module.name : module._id;
            const roomName = visitorAction.request?.room?.name;
            const bookedRoomName = visitorAction.room?.name;
            const fileName = visitorAction.file?.name;
            switch (action) {
                case 'connect':
                    actionString = `Visitor joined the event`;
                    break;
                case 'disconnect':
                    actionString = `Visitor left the event`;
                    break;
                case 'register':
                    actionString = `Visitor registered for the event`;
                    break;
                case 'login':
                    actionString = `Visitor logged into the event`;
                    break;
                case 'logout':
                    actionString = `Visitor logged out from the event`;
                    break;
                case 'enter-booth':
                    actionString = `Visitor entered booth: ${boothName}`;
                    break;
                case 'exit-booth':
                    actionString = `Visitor left booth: ${boothName}`;
                    break;
                case 'request-video-call':
                    actionString = `Visitor requested for instant video call on module: ${moduleName}`;
                    break;
                case 'end-video-room':
                    actionString = `Visitor cancelled a video call request on module: ${moduleName}`;
                    break;
                case 'leave-video-room':
                    actionString = `Visitor left an ongoing call room: ${roomName} on module: ${moduleName}`;
                    break;
                case 'join-video-room':
                    actionString = `Visitor joined a video call room: ${roomName} on module: ${moduleName}`;
                    break;
                case 'book-video-call-slot':
                    actionString = `Visitor booked a video call slot in room: ${bookedRoomName} on module: ${moduleName}`;
                    break;
                case 'initiate-chat':
                    actionString = `Visitor initiated chat on module: ${moduleName}`;
                    break;
                case 'download-file':
                    actionString = `Visitor downloaded file: ${fileName} from module: ${moduleName}`;
                    break;
                default:
                    actionString = action;
            }
            const timestamp = createdAtDate.toLocaleDateString() + ' - ' + createdAtDate.toLocaleTimeString();
            fileContent += `${timestamp}, ${actionString}\n`;
        });
        fileDownload(fileContent, `user-journey-${visitor?.firstName}-${visitor?.lastName}-${visitor?._id}.csv`);
    };

    return (
        <Drawer
            title={strings.visitorJourney}
            visible={visitor && visitor?._id ? true : false}
            onClose={() => setVisitor(undefined)}
            width="620px"
        >
            <Card
                size="small"
                bordered={false}
                title={
                    <Space size="large" align="baseline">
                        <Typography.Title level={4}>
                            {visitor?.firstName} {visitor?.lastName}
                        </Typography.Title>
                        <Typography.Text type="secondary">ID: {visitor?._id}</Typography.Text>
                    </Space>
                }
                extra={
                    <Button onClick={exportUserJourney} icon={<ExportOutlined />}>
                        Export
                    </Button>
                }
            >
                <Descriptions size="small" column={2} bordered>
                    <Descriptions.Item label="Company">{visitor?.company}</Descriptions.Item>
                    <Descriptions.Item label="Designation">{visitor?.designation}</Descriptions.Item>
                    <Descriptions.Item label="Email">{visitor?.emailId}</Descriptions.Item>
                    <Descriptions.Item label="Mobile">{visitor?.mobile}</Descriptions.Item>
                </Descriptions>
            </Card>
            <Divider />
            {!isSponsor && (
                <Form form={filterForm} onValuesChange={submitFilterForm}>
                    <Form.Item name="boothId">
                        <Select
                            placeholder="Filter for booth"
                            // onChange={onGenderChange}
                            allowClear
                        >
                            {boothList.map(booth => (
                                <Option value={booth._id!} key={booth._id}>
                                    {booth.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="showEventJoinLeaveActions"
                        label="Show event level actions"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                </Form>
            )}

            <Divider />
            <Timeline pending={'Waiting for more visitor actions...'} reverse={true}>
                {generateTimelineItems(visitorActions)}
            </Timeline>
        </Drawer>
    );
};

export default VisitorJourney;
