import React, { useContext, useEffect, useRef, useState } from 'react';

import { FullscreenExitOutlined, CloseOutlined, QuestionOutlined } from '@ant-design/icons';
import Daily, { DailyCall } from '@daily-co/daily-js';
import { Button, Drawer, message, Space, Statistic } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import Pubnub from 'pubnub';
import { usePubNub } from 'pubnub-react';
import LocalizedStrings from 'react-localization';
import store from 'store';
import styled from 'styled-components';

import { GlobalContext } from '../context/GlobalContextProvider';
import { useBoothCommunication } from '../hooks';
import LiveStreamingQuestions from './LiveStreamingQuestions';

import './VideoCallModal.css';

const strings = new LocalizedStrings({
    en: {
        linkNotFound: 'Video call link not found',
        tokenNotFound: 'Token not found',
        ongoingCall: 'Ongoing video call',
        maximize: 'Maximize',
        minimize: 'Minimize',
        endCall: 'End Call',
        leaveCall: 'Leave Call',
        questions: 'Questions',
    },
    de: {
        linkNotFound: 'Videoanruf wurde nicht gefunden',
        tokenNotFound: 'Nicht gefunden',
        ongoingCall: 'Laufender Videoanruf',
        maximize: 'Maximieren',
        minimize: 'Minimieren',
        endCall: 'Anruf beenden',
        leaveCall: 'Leave Call',
        questions: 'Questions',
    },
});

const IframeWrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
`;

const Iframe = styled.iframe`
    border: 0;
    width: 100%;
    flex-grow: 1;
`;

const IframeActionButtonWrapper = styled.div`
    text-align: center;
    margin-top: 1.5rem;
`;

const { Countdown } = Statistic;

export const VideoCallModal = () => {
    const context = useContext(GlobalContext);
    const [maximized, setMaximized] = useState(true);
    const videoCallFrameRef = useRef<HTMLIFrameElement>(null);
    const [loading, setLoading] = useState(false);
    const [showQuestions, setShowQuestions] = useState(false);
    const [toTime, setToTime] = useState<string>();
    const pubnub = usePubNub();
    const [liveStreamViewerCount, setLiveStreamViewerCount] = useState(0);
    const { addChannels, addListener, removeChannels, removeListener } = useBoothCommunication();

    const presenceListener = async (event: Pubnub.PresenceEvent) => {
        const occupancy = event.occupancy;
        setLiveStreamViewerCount(occupancy || 0);
    };

    const roomListeners = { presence: presenceListener };

    const subscribeToRoomChannel = ({ moduleId }: { moduleId: string }) => {
        addChannels([`live-stream.${moduleId}-pnpres`]);
        addListener && addListener(roomListeners);
    };

    const unSubscribeToRoomChannel = ({ moduleId }: { moduleId: string }) => {
        removeChannels([`live-stream.${moduleId}-pnpres`]);
        removeListener && removeListener(roomListeners);
    };

    useEffect(() => {
        const activeVideoRoom = context.activeVideoRoom;
        if (activeVideoRoom) {
            const moduleId = activeVideoRoom?.module!;
            subscribeToRoomChannel({ moduleId });
            return () => {
                unSubscribeToRoomChannel({ moduleId });
            };
        }
    }, [context.activeVideoRoom]);

    useEffect(() => {
        console.log('VideoCallModal mounting');
        const activeVideoRoom = context.activeVideoRoom;
        let callFrame: DailyCall;

        if (activeVideoRoom) {
            const loggedInUser = store.get('user') as Users.User;

            if (videoCallFrameRef.current !== null && activeVideoRoom) {
                const videoLink = activeVideoRoom.link;
                const operator = activeVideoRoom?.participants?.find(
                    participant => participant?.user?._id === loggedInUser._id,
                );
                const toTime = operator?.toTime;
                setToTime(toTime);
                const token = operator?.token;
                if (!token) throw new Error(strings.linkNotFound);
                const url = videoLink + '?t=' + token;
                console.log('VideoCallModal url', url);

                callFrame = Daily.wrap(videoCallFrameRef.current, {
                    showFullscreenButton: true,
                    iframeStyle: {
                        position: 'fixed',
                        top: '10%',
                        left: '10%',
                        width: '80%',
                        height: '80%',
                        zIndex: '1000',
                        border: '0',
                    },
                });

                callFrame.on('left-meeting', async () => {
                    await callFrame?.stopRecording();
                    callFrame?.leave();
                });

                const currentUserIsSponsor = loggedInUser.roles?.includes('sponsor');

                if (activeVideoRoom.toStream) {
                    const streamUrl = activeVideoRoom.channel?.ingestEndpoint;
                    const streamKey = activeVideoRoom.streamKey?.value;

                    callFrame.on('recording-started', async () => {
                        !currentUserIsSponsor &&
                            callFrame.startLiveStreaming({
                                rtmpUrl: `rtmps://${streamUrl}:443/app/${streamKey}`,
                            });
                    });

                    callFrame.on('joined-meeting', async () => {
                        currentUserIsSponsor &&
                            callFrame.startLiveStreaming({
                                rtmpUrl: `rtmps://${streamUrl}:443/app/${streamKey}`,
                            });
                    });
                }

                callFrame.join({ url });
            }
        }

        return () => {
            console.log('VideoCallModal unmounting');
            if (activeVideoRoom?.link && callFrame && activeVideoRoom && activeVideoRoom.toStream) {
                // callFrame.stopLiveStreaming();
                callFrame &&
                    activeVideoRoom?.link &&
                    activeVideoRoom &&
                    activeVideoRoom.toStream &&
                    callFrame.stopRecording();
            }
            callFrame ? callFrame.leave() : undefined;
        };
    }, [context.activeVideoRoom]);

    {
        context.activeVideoRoom && !maximized
            ? message.info({
                  content: (
                      <>
                          {strings.ongoingCall}{' '}
                          <Button type="primary" size="small" onClick={() => setMaximized(true)}>
                              {strings.maximize}
                          </Button>
                      </>
                  ),
                  duration: 0,
                  key: 'minimized-video-call',
              })
            : message.destroy('minimized-video-call');
    }

    const handleEndCall = () => {
        context.setActiveVideoRoom(undefined);
    };

    const toggleShowQuestions = () => {
        if (showQuestions) setShowQuestions(false);
        else setShowQuestions(true);
    };

    return (
        <Modal
            className="video-call-modal"
            closable={false}
            width="100vw"
            style={{ height: '100%' }}
            visible={context.activeVideoRoom && maximized}
            footer={null}
            centered
            bodyStyle={{ height: '100%', padding: '10px' }}
        >
            <IframeWrapper>
                {context.activeVideoRoom?.toStream && (
                    <div
                        style={{
                            width: '100%',
                            color: 'white',
                            backgroundColor: '#2ecc71',
                            textAlign: 'center',
                            fontWeight: 'bold',
                        }}
                    >
                        Viewer Count: {liveStreamViewerCount}
                    </div>
                )}
                <Iframe
                    ref={videoCallFrameRef}
                    allowFullScreen={true}
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; camera; microphone; display-capture;"
                    id="video-call-iframe"
                />
                <IframeActionButtonWrapper>
                    <Space>
                        <>
                            {toTime && (
                                <>
                                    Ends in: <Countdown value={new Date(toTime).getTime()} />
                                </>
                            )}
                        </>
                        {context.activeVideoRoom?.toStream && (
                            <Button onClick={toggleShowQuestions} size="small" icon={<QuestionOutlined />}>
                                {strings.questions}
                            </Button>
                        )}
                        <Button onClick={() => setMaximized(false)} size="small" icon={<FullscreenExitOutlined />}>
                            {strings.minimize}
                        </Button>
                        <Button
                            icon={<CloseOutlined />}
                            size="small"
                            onClick={
                                () => handleEndCall()
                                // request as Modules.VideoCallRequest & {
                                //     requester?: Users.User;
                                //     respondent?: Users.User;
                                // },
                            }
                            danger
                            loading={loading}
                            testing-id="leave-ongoing-call-button"
                        >
                            {strings.leaveCall}
                        </Button>
                    </Space>
                </IframeActionButtonWrapper>
            </IframeWrapper>

            {context.activeVideoRoom?.toStream && (
                <Drawer
                    title={strings.questions}
                    placement="right"
                    closable={true}
                    onClose={() => setShowQuestions(false)}
                    visible={showQuestions}
                    getContainer={false}
                    style={{ position: 'absolute' }}
                    mask={false}
                    width={512}
                    destroyOnClose={true}
                >
                    <LiveStreamingQuestions
                        clientId={context.activeBooth?.client?._id!}
                        eventId={context.activeBooth?.event?._id!}
                        boothId={context.activeBooth?._id!}
                        moduleId={context.activeVideoRoom?.module!}
                        roomId={context.activeVideoRoom?._id!}
                        view="speakerView"
                    />
                </Drawer>
            )}
        </Modal>
    );
};

export default VideoCallModal;
