import React, { useEffect, useState } from 'react';

import { Card, Select } from 'antd';
import LocalizedStrings from 'react-localization';
import { Legend, Tooltip, ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Bar, LabelList } from 'recharts';

const strings = new LocalizedStrings({
    en: {
        videoCallSummary: 'Video Call Summary - Live',
    },
    de: {
        videoCallSummary: 'Anzahl Videocalls - Live',
    },
});
const { Option } = Select;

export const VideoCallSummary = ({
    videoCallSummary,
    boothId,
}: {
    videoCallSummary: EventsAnalytics.VideoCallSummary;
    boothId?: string;
}) => {
    const [booths, setBooths] = useState<{ [boothId: string]: string }>({});
    const [data, setData] = useState<({ name: string; Private: number; Group: number } | undefined)[]>([]);
    const [filterBoothId, setFilterBoothId] = useState<string | undefined>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const data = Object.keys(videoCallSummary)
            .map(moduleId => {
                const module = videoCallSummary[moduleId];
                const booth = module.booth;
                setBooths(booths => {
                    booths[booth._id] = booth.name;
                    if (!boothId && !filterBoothId) setFilterBoothId(booth._id);
                    return booths;
                });
                const returnData = {
                    name: module.name,
                    Private: module.activeVisitorCountInOneToOneCall,
                    Group: module.activeVisitorCountInGroupCall,
                };
                if (filterBoothId && booth._id === filterBoothId) return returnData;
                else if (!filterBoothId) return returnData;
                else return undefined;
            })
            .filter(x => x);
        setData(data);

        if (boothId) setFilterBoothId(boothId);

        if (!Object.keys(videoCallSummary).length) setLoading(true);
        else setLoading(false);
    }, [videoCallSummary, filterBoothId, boothId]);

    const changeFilterBoothId = (key: string) => {
        setFilterBoothId(key);
        console.log(key);
    };

    return (
        <Card
            title={strings.videoCallSummary}
            size="small"
            extra={
                <>
                    {!boothId && (
                        <Select
                            style={{ width: 150 }}
                            placeholder="Booth"
                            onChange={changeFilterBoothId}
                            value={filterBoothId}
                        >
                            {Object.keys(booths).map(boothId => (
                                <Option key={boothId} value={boothId}>
                                    {booths[boothId]}
                                </Option>
                            ))}
                        </Select>
                    )}
                </>
            }
            loading={loading}
        >
            <ResponsiveContainer width="100%" height={350}>
                <BarChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis allowDecimals={false} />
                    <Tooltip />
                    <Bar dataKey="Private" fill="#1853DB">
                        <LabelList dataKey="Private" position="top" />
                    </Bar>
                    <Bar dataKey="Group" fill="#ED4C67">
                        <LabelList dataKey="Group" position="top" />
                    </Bar>
                    <Legend />
                </BarChart>
            </ResponsiveContainer>
        </Card>
    );
};

export default VideoCallSummary;
