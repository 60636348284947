import React, { useEffect, useState } from 'react';

import { Card, Col, Row, Select, Typography, DatePicker } from 'antd';
import LocalizedStrings from 'react-localization';
import { Legend, Tooltip, ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Bar, LabelList } from 'recharts';

const RangePicker = DatePicker.RangePicker;

const strings = new LocalizedStrings({
    en: {
        billingStats: 'Stats for Billing',
    },
    de: {
        billingStats: 'Statistiken für die Abrechnung',
    },
});
const { Option } = Select;

export const BillingStats = ({
    totalParticipantMinutes,
    totalUserRegistrations,
    billingStatsDate,
    setBillingStatsDate,
}: {
    totalParticipantMinutes: number;
    totalUserRegistrations: number;
    billingStatsDate: [moment.Moment, moment.Moment];
    setBillingStatsDate: (values: [moment.Moment, moment.Moment]) => void;
}) => {
    return (
        <Card
            title={strings.billingStats}
            size="small"
            extra={
                <RangePicker
                    value={billingStatsDate}
                    onChange={values => setBillingStatsDate([values![0]!, values![1]!])}
                    allowClear={false}
                    allowEmpty={[false, false]}
                />
            }
        >
            <Row style={{ textAlign: 'center' }}>
                <Col span={12}>
                    <Typography.Title level={3}>{totalParticipantMinutes}</Typography.Title>
                    Total Video Call Minutes
                </Col>
                <Col span={12}>
                    <Typography.Title level={3}> {totalUserRegistrations}</Typography.Title> Total New Visitors
                </Col>
            </Row>
        </Card>
    );
};

export default BillingStats;
