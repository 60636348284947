import React, {
    Dispatch,
    forwardRef,
    SetStateAction,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';

import { CloudDownloadOutlined, ExportOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Card, Col, Form, Input, List, Result, Row, Space, Table, Tag, Typography } from 'antd';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { default as Pubnub, default as PubNub } from 'pubnub';
import { usePubNub } from 'pubnub-react';
import LocalizedStrings from 'react-localization';
import store from 'store';
import styled from 'styled-components';
import UIfx from 'uifx';

import notificationSound from '../../static/chat-notification.mp3';
import { GlobalContext } from '../context/GlobalContextProvider';
import { useChatList } from '../hooks';
import { apiRequester, handleError, handleSuccess, Notifier, showNotification } from '../utility';
import { CHAT_ACTIONS, generateNotifierMessage, NOTIFIER_MESSAGE_IDS } from '../utility/NotifierMessages';

const { Text, Paragraph } = Typography;
let sound: UIfx;
const getNavigatorLanguage = () => (navigator.languages && navigator.languages.length ? navigator.languages[0] : 'en');
const strings = new LocalizedStrings({
    en: {
        chatLoaded: 'Full chat history has been loaded',
        chattingWith: 'Chatting with',
        loadChat: 'Load full chat',
        exportChat: 'Export chat',
        typeMessage: 'Type your message',
        send: 'Send',
        chatNotif: 'Chat Notification',
        newMessageNotif: "You've received a new chat message from",
        anonymousMessageNotif: "You've received a new chat message from an Anonymous User",
        anonyUser: 'Anonymous User ',
        id: 'ID',
        addedToChat: 'You have been added to the chat.',
        selectChat:
            'Please select a visitor to chat with form the list on the left. If you do not see the visitor list then please wait for the first visitor to sign in.',
        pressEnter: 'Press Enter to',
    },
    de: {
        chatLoaded: 'Komplette Chathistorie wurde geladen',
        chattingWith: 'Chatten mit',
        loadChat: 'Kompletten Chat laden',
        exportChat: 'Chat exportieren',
        typeMessage: 'Tippen Sie Ihre Nachricht',
        send: 'Senden',
        chatNotif: 'Chatbenachrichtigung',
        newMessageNotif: 'Sie haben eine neue Chatnachricht erhalten von',
        anonymousMessageNotif: 'Sie haben eine neue Chatnachricht erhalten von einem anonymen Benutzer',
        anonyUser: 'Anonymer Benutzer',
        id: 'ID',
        addedToChat: 'Sie wurden zum Chat hinzugefügt',
        selectChat:
            'Bitte wählen Sie ein Besucher aus der Liste aus. Wenn Sie noch keine Besucher sehen, warten Sie bis sich der erste Besucher bei Ihnen anmeldet.',
        pressEnter: 'Enter ',
    },
});

if (typeof window !== 'undefined') sound = new UIfx(notificationSound);

const ChatBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow-y: hidden;
`;

const ChatBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow-y: scroll;
    margin-bottom: 1.5rem;
`;

const BubbleContainer = styled.div<{ right?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: ${props => (props.right ? 'flex-end' : 'flex-start')};
    width: 100%;
`;

const PrimaryBubble = styled.div`
    background-color: #1853db;
    color: white;
    border-radius: 4px;
    padding: 10px;
    max-width: 75%;
    margin-left: 25%;
`;

const SecondaryBubble = styled.div<{ forcePrimaryColor?: boolean }>`
    background-color: ${props => (props.forcePrimaryColor ? '#1853db' : '#eee')};
    color: ${props => (props.forcePrimaryColor ? 'white' : undefined)};
    border-radius: 4px;
    padding: 10px;
    max-width: 75%;
`;

const Message = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 1.5rem;
`;

const AvatarContainer = styled.div<{ right?: boolean }>`
    position: relative;
    flex-shrink: 0;
    margin-right: ${props => (props.right ? undefined : '12px')};
    margin-left: ${props => (props.right ? '12px' : undefined)};
`;

const TitleContainer = styled.div<{ right?: boolean }>`
    display: flex;
    justify-content: ${props => (props.right ? 'flex-end' : undefined)};
`;

const UserName = styled(Text)`
    padding-right: 8px;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.45);
`;

const Time = styled(Text)`
    padding-right: 8px;
    font-size: 12px;
    line-height: 18px;
    color: #ccc;
`;

const getUserFullName = (user?: Users.User) => {
    if (!user) return 'Anonymous User';

    const { firstName = undefined, lastName = undefined } = user;
    if (firstName || lastName) return [firstName, lastName].filter(x => x).join(' ');
    else return 'Anonymous User';
};

const SecondaryMessage = ({ message, user }: { message: Modules.ChatMessage; user?: Users.User }) => {
    const time = new Date(parseInt(message.timetoken.toString()) / 10000);
    return (
        <Message>
            <AvatarContainer>
                <Avatar>{user?.firstName ? user.firstName[0] : 'U'}</Avatar>
            </AvatarContainer>
            <BubbleContainer>
                <TitleContainer>
                    <UserName>{getUserFullName(user)}</UserName>
                    <Time>{moment(time).locale(getNavigatorLanguage()).fromNow()}</Time>
                </TitleContainer>
                <SecondaryBubble forcePrimaryColor={user && user.roles && !user.roles?.includes('visitor')}>
                    <span testing-id="secondary-message">{message.message}</span>
                </SecondaryBubble>
            </BubbleContainer>
        </Message>
    );
};

const PrimaryMessage = ({ message, user }: { message: Modules.ChatMessage; user?: Users.User }) => {
    const time = new Date(parseInt(message.timetoken.toString()) / 10000);
    return (
        <Message>
            <BubbleContainer right>
                <TitleContainer right>
                    <Time>{moment(new Date(time)).locale(getNavigatorLanguage()).fromNow()}</Time>
                    <UserName>{getUserFullName(user)}</UserName>
                </TitleContainer>
                <PrimaryBubble>
                    <span testing-id="primary-message">{message.message}</span>
                </PrimaryBubble>
            </BubbleContainer>
            <AvatarContainer right>
                <Avatar>{user?.firstName ? user.firstName[0] : 'U'}</Avatar>
            </AvatarContainer>
        </Message>
    );
};

const Chat = ({ chat, userId, boothId }: { chat: Modules.Chat; userId: string; boothId: string }) => {
    const { _id: chatId, visitor, operators } = chat;
    const pubnub = usePubNub();
    const [messages, setMessages] = useState<
        { message: string; timetoken: number | string; uuid?: string; channel?: string }[]
    >([]);
    const messagesEndRef = useRef<null | HTMLDivElement>(null);
    const [loading, setLoading] = useState(false);
    const context = useContext(GlobalContext);
    const [chatForm] = Form.useForm();

    const scrollToBottom = () => {
        messagesEndRef?.current!?.scrollIntoView({ behavior: 'smooth' });
    };

    const updateLastReadTime = async ({ channelId }: { channelId: string }) => {
        const currentTime = (await pubnub.time()).timetoken.toString();
        await pubnub.objects.setChannelMetadata({
            channel: channelId,
            data: {
                custom: {
                    lastOperatorMessageTime: currentTime,
                },
            },
        });
    };

    const fetchCompleteChatHistory = async (lastTimeToken?: string | number): Promise<void> => {
        if (!lastTimeToken) {
            setLoading(true);
            setMessages([]);
            lastTimeToken = (await pubnub.time()).timetoken;
        }
        const response = await pubnub.fetchMessages({ channels: [chatId], count: 100, start: lastTimeToken });
        const fetchedMessages: {
            channel: string;
            message: any;
            timetoken: string | number;
            uuid?: string;
        }[] = response.channels[chatId] || [];
        if (fetchedMessages.length) {
            const chatUserIds = new Set<string>();
            fetchedMessages.map(message => {
                const { uuid } = message;
                uuid && chatUserIds.add(uuid);
            });
            await context.refreshUserList([...chatUserIds]);
            setMessages(messages => [...fetchedMessages, ...messages]);
            const lastMessageTimeToken = fetchedMessages[0].timetoken.toString();
            await fetchCompleteChatHistory(lastMessageTimeToken);
        } else {
            setLoading(false);
            handleSuccess(strings.chatLoaded!);
        }
    };

    const fetchChatHistory = async () => {
        setMessages([]);
        const response = await pubnub.fetchMessages({ channels: [chatId], count: 50 });
        const fetchedMessages: {
            channel: string;
            message: any;
            timetoken: string | number;
            uuid?: string;
        }[] = response.channels[chatId] || [];
        const chatUserIds = new Set<string>();
        fetchedMessages.map(message => {
            const { uuid } = message;
            uuid && chatUserIds.add(uuid);
        });
        await context.refreshUserList([...chatUserIds]);
        setMessages(fetchedMessages);
        return fetchedMessages;
    };

    const getUserFromId = (userId: string) => {
        return context.userList[userId];
    };

    const exportChat = async () => {
        await fetchCompleteChatHistory();
        let chatSequence = `Time, First Name, Last Name, Message\n`;
        await messages.reduce(async (lastPromise, message) => {
            await lastPromise;
            const { uuid: userId } = message;
            const date = new Date(parseInt(message.timetoken.toString()) / 10000);
            const userList = await context.refreshUserList([userId!]);
            const user = userList[userId!];
            const timestamp = date.toLocaleDateString() + ' - ' + date.toLocaleTimeString();
            chatSequence += `${timestamp}, ${user?.firstName ? user?.firstName : ''}, ${
                user?.lastName ? user?.lastName : ''
            }, ${message.message}\n`;
            return;
        }, Promise.resolve());
        fileDownload(chatSequence, `chat-history-${chat?.visitor?.firstName}-${chat?.visitor?.lastName}.csv`);
    };

    const listeners: PubNub.ListenerParameters = useMemo(
        () => ({
            message: async (messageEvent: PubNub.MessageEvent) => {
                const { publisher: publisherUserId } = messageEvent;
                await context.refreshUserList([publisherUserId]);
                setMessages(messages => [
                    ...messages,
                    {
                        message: messageEvent.message,
                        timetoken: messageEvent.timetoken,
                        uuid: messageEvent.publisher,
                    },
                ]);
                scrollToBottom();
            },
        }),
        [chatId],
    );

    useEffect(() => {
        console.log('chat mounted');
        fetchChatHistory()
            .then(() => pubnub.addListener(listeners))
            .then(scrollToBottom)
            .then(() => pubnub.subscribe({ channels: [chatId], withPresence: true }))
            .catch(handleError);

        // Unsubscribe when unmounted
        return () => {
            console.log('chat un-mounted');
            pubnub.unsubscribe({ channels: [chatId] });
            pubnub.removeListener(listeners);
        };
    }, [chat]);

    const sendMessage = async ({ message }: { message: string }) => {
        try {
            const input = message;
            if (!input) return;
            chatForm.resetFields();
            await pubnub.publish({
                channel: chatId,
                message: input,
            });
            await updateLastReadTime({ channelId: chatId });
            await pubnub.publish({
                channel: `users.${chat.visitor._id}`,
                message: generateNotifierMessage.chat({
                    action: CHAT_ACTIONS.CHAT_UPDATED,
                    booth: { id: boothId },
                }),
            });
            // refreshChatLastMessageTime({ chatId });
            const boothOperatorsChannel = `booth-operators.${boothId}`;
            await pubnub.publish({
                channel: boothOperatorsChannel,
                message: generateNotifierMessage.chat({
                    booth: {
                        id: boothId,
                    },
                    visitor: {
                        id: chat.visitor._id!,
                    },
                    action: CHAT_ACTIONS.CHAT_UPDATED,
                }),
            });
        } catch (err) {
            handleError(err);
        }
    };

    const keyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            chatForm.submit();
        }
    };

    return (
        <Card
            title={
                <>
                    {`${strings.chattingWith} ${
                        chat?.visitor?.firstName
                            ? chat?.visitor?.firstName + ' ' + chat?.visitor?.lastName
                            : strings.anonyUser
                    }`}
                    <Typography.Text type="secondary" style={{ fontSize: '0.8rem' }}>
                        <span testing-id="active-chat-visitor-id" testing-active-chat-visitor-id={chat.visitor._id}>
                            ({visitor._id})
                        </span>
                    </Typography.Text>
                </>
            }
            extra={[
                <Button
                    key="load-full-chat-btn"
                    icon={<CloudDownloadOutlined />}
                    onClick={() => fetchCompleteChatHistory()}
                    loading={loading}
                >
                    {strings.loadChat}
                </Button>,
                <Button key="export-chat-btn" icon={<ExportOutlined />} onClick={exportChat} loading={loading}>
                    {strings.exportChat}
                </Button>,
            ]}
            style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}
            bodyStyle={{ height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}
        >
            <ChatBoxContainer>
                <ChatBox id="chat-box">
                    <>
                        {messages.map((messageEvent, messageIndex) => {
                            const messageSenderUserId = messageEvent.uuid;
                            const messageBelongsToLoggedInUser = messageSenderUserId === userId;
                            const user = getUserFromId(messageEvent.uuid!);
                            return messageBelongsToLoggedInUser ? (
                                <PrimaryMessage
                                    key={`message-${messageIndex}`}
                                    message={messageEvent as Modules.ChatMessage}
                                    user={user}
                                />
                            ) : (
                                <SecondaryMessage
                                    key={`message-${messageIndex}`}
                                    message={messageEvent as Modules.ChatMessage}
                                    user={user}
                                />
                            );
                        })}
                    </>
                    <div ref={messagesEndRef} testing-id="chat-message-boundary" />
                </ChatBox>
            </ChatBoxContainer>
            <Form layout="vertical" form={chatForm} onFinish={sendMessage} onKeyUp={keyPress}>
                <Form.Item name="message">
                    <Input.TextArea placeholder={strings.typeMessage} />
                </Form.Item>
                <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
                    <Text>{strings.pressEnter} </Text>
                    <Button type="primary" onClick={chatForm.submit}>
                        {strings.send}
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export const ChatModule = forwardRef(
    (
        {
            clientId,
            eventId,
            boothId,
            moduleId,
        }: {
            clientId: string;
            eventId: string;
            boothId: string;
            moduleId: string;
        },
        ref,
    ) => {
        const { chats } = useChatList({
            clientId,
            eventId,
            boothId,
            moduleId,
            refreshListOnLoad: false,
        });
        const loggedInUser = store.get('user') as Users.User;
        const [activeChat, setActiveChat] = useState<Modules.Chat>();
        const [loading, setLoading] = useState(false);

        useImperativeHandle(ref, () => ({
            refreshChats() {
                // refreshChatList();
            },
        }));

        // const isChatMember = ({ user, chat }: { user: Users.User; chat: Modules.Chat }) => {
        //     const userId = user?._id;
        //     const operatorUserIds = chat.operators.map(operator => operator?._id);
        //     const chatMemberUserIds = [chat?.visitor?._id, ...operatorUserIds];
        //     if (chatMemberUserIds.includes(userId)) return true;
        //     else return false;
        // };

        // const joinChat = async (chat: Modules.Chat) => {
        //     try {
        //         setLoading(true);
        //         await apiRequester.joinChat(clientId, eventId, boothId, moduleId, chat?._id);
        //         const chats = await refreshChatList({});
        //         const updatedChat = chats.find(c => c.data?._id === chat?._id);
        //         const visitorId = updatedChat?.data.visitor._id;
        //         await pubnub.publish({
        //             channel: `users.${visitorId}`,
        //             message: generateNotifierMessage.chat({
        //                 booth: {
        //                     id: boothId,
        //                 },
        //                 action: CHAT_ACTIONS.CHAT_UPDATED,
        //             }),
        //         });
        //         await pubnub.publish({
        //             channel: boothOperatorsChannel,
        //             message: generateNotifierMessage.chat({
        //                 booth: {
        //                     id: boothId,
        //                 },
        //                 action: CHAT_ACTIONS.CHAT_UPDATED,
        //             }),
        //         });
        //         setActiveChat({ ...chat, ...updatedChat?.data });
        //         setLoading(false);
        //         handleSuccess('You have been added to the chat.');
        //     } catch (err) {
        //         handleError(err);
        //         setLoading(false);
        //     }
        // };

        return chats?.length ? (
            <>
                <Row gutter={16} style={{ height: '100%' }}>
                    <Col span={8} style={{ height: '100%' }}>
                        <Table
                            showHeader={false}
                            size="small"
                            loading={loading}
                            style={{
                                border: '1px solid #F0F0F0',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                height: '100%',
                                background: 'white',
                                overflowY: 'scroll',
                            }}
                            dataSource={chats}
                            pagination={{
                                pageSize: 20,
                                position: ['topCenter', 'bottomCenter'],
                                size: 'small',
                                showLessItems: true,
                                responsive: true,
                                showQuickJumper: false,
                                simple: true,
                            }}
                            columns={[
                                {
                                    key: 'chats',
                                    render: (value, chat) => {
                                        return (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    gap: '10px',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Space>
                                                    <Badge status={chat.isOnline ? 'success' : undefined}>
                                                        <Avatar src={chat.visitor?.profilePicture?.link}>
                                                            <UserOutlined />
                                                        </Avatar>
                                                    </Badge>

                                                    <a
                                                        onClick={() => setActiveChat(chat)}
                                                        className={chat.operatorUnread ? 'chat-with-new-message' : ''}
                                                    >
                                                        {chat.visitor?.firstName ? (
                                                            chat.visitor?.firstName + ' ' + chat.visitor?.lastName
                                                        ) : (
                                                            <>Anonymous User</>
                                                        )}
                                                    </a>
                                                </Space>
                                                {/* {JSON.stringify(chat.visitor?.emailId || chat.visitor?._id)} */}
                                                {chat.operatorUnread ? <Tag color="#1853db">NEW</Tag> : <div></div>}
                                            </div>
                                        );
                                    },
                                },
                            ]}
                        />
                    </Col>
                    <Col span={16} style={{ overflowY: 'scroll', height: '100%' }}>
                        {loggedInUser.chatAuthToken && activeChat && (
                            <Chat chat={activeChat} userId={loggedInUser?._id!} boothId={boothId} />
                        )}
                        {!activeChat && (
                            <Card
                                style={{ height: '100%' }}
                                bodyStyle={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                }}
                            >
                                <Paragraph testing-id="select-chat-message">{strings.selectChat}</Paragraph>
                            </Card>
                        )}
                    </Col>
                </Row>
            </>
        ) : (
            <>
                <Table dataSource={[]}></Table>
            </>
        );
    },
);

export default ChatModule;
