import React, { useContext, useEffect, useState } from 'react';

import { CloseOutlined, FormOutlined, LockOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, Dropdown, Input, Menu, Popconfirm, Space, Tag, Typography } from 'antd';
import { navigate } from 'gatsby';
import moment from 'moment';
import LocalizedStrings from 'react-localization';
import store from 'store';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess } from '../utility';

const { Paragraph } = Typography;
const strings = new LocalizedStrings({
    en: {
        moduleActivated: 'module has been activated',
        moduleDeactivated: 'module has been de-activated',
        moduleBeen: 'Module has been',
        activated: 'activated',
        deactivated: 'deactivated',
        module: 'Module',
        id: 'ID',
        createdAt: 'createdAt',
        isActivated: 'Activated',
        activate: 'Activate',
        deactivate: 'Deactivate',
        delete: 'Delete',
        auth: 'Authentication',
        addModule: 'Add Module',
        add: 'Add',
        authDescription1:
            'This module allows you to require a sign up and sign in before entering the event. By default the event will be open to all visitors and they will be able to attend it without going thorough an authentication process. Without authentication, user&apos;s will stil have to fill up a small registration form with their first and last name, email and company name to help with user identification but won&apos;t be asked to login to attend the event.',
        authDescription2: 'When this module is activated, visitors will be asked to verify their email before sign in.',
        filter: 'Filter modules by type',
        cancel: 'Cancel',
        yes: 'Yes',
        no: 'No',
        sure: 'Are you sure you want to delete?',
    },
    de: {
        moduleActivated: 'Modul wurde aktiviert',
        moduleDeactivated: 'Modul wurde deaktiviert',
        moduleBeen: 'Modul wurde',
        activated: 'aktiviert',
        deactivated: 'deaktiviert',
        module: 'Modul',
        id: 'ID',
        createdAt: 'Zeit der Schöpfung',
        isActivated: 'Aktiviert',
        activate: 'Aktivieren',
        deactivate: 'Deaktivieren',
        delete: 'Löschen',
        auth: 'Authentifizierung',
        addModule: 'Modul hinzufügen',
        add: 'Hinzufügen',
        authDescription1:
            'Dieses Modul macht eine Registrierung vor der Teilnahme an der Veranstaltung für die Besucher verpflichtend. Standardmäßig steht die Veranstaltung allen Besuchern offen und sie können daran teilnehmen, ohne einen Authentifizierungsprozess durchlaufen zu müssen. Ohne Authentifizierung müssen die Benutzer weiterhin ein Registrierungsformular mit ihrem Vor- und Nachnamen, E-Mail und Firmennamen ausfüllen, um die Benutzeridentifikation zu erleichtern, werden jedoch nicht aufgefordert, sich anzumelden, um an der Veranstaltung teilzunehmen.',
        authDescription2:
            'Wenn dieses Modul aktiviert wird, müssen Besucher ihre E-Mail-Adresse bestätigen, bevor sie sich registrieren können.',
        filter: 'Module nach Typen filtern',
        cancel: 'Abbrechen',
        yes: 'Ja',
        no: 'Nein',
        sure: 'Sind Sie sicher, dass Sie löschen möchten?',
    },
});

export const EventModules = ({ clientId, eventId }: { clientId?: string | null; eventId?: string | null }) => {
    const context = useContext(GlobalContext);
    const [visibleAddDrawer, setVisibleAddDrawer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState('');
    const [addFormModuleName, setAddFormModuleName] = useState<Modules.MODULE_IDS>();
    const loggedInUser = store.get('user') as Users.User;

    useEffect(() => {
        if (!clientId || !eventId) navigate('/');
        else {
            apiRequester
                .getEventModules(clientId, eventId!)
                .then(eventModules => {
                    context.setEventModules(eventModules.length ? eventModules : []);
                    return eventModules;
                })
                .then(() => setLoading(false))
                .catch(handleError);
        }
    }, []);

    const showAddDrawer = (event: any) => {
        const key = event.key;
        setAddFormModuleName(key);
        setVisibleAddDrawer(true);
    };

    const onCloseAddDrawer = () => {
        setVisibleAddDrawer(false);
    };

    const activateModule = async (moduleName?: Modules.MODULE_IDS) => {
        try {
            setLoading(true);
            await apiRequester.addEventModule(clientId!, eventId!, { type: moduleName! });
            const eventModules = await apiRequester.getEventModules(clientId!, eventId!);
            context.setEventModules(eventModules);
            onCloseAddDrawer();
            handleSuccess(`${moduleName?.charAt(0).toUpperCase()}${moduleName?.slice(1)} ${strings.moduleActivated}`);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            handleError(err);
        }
    };

    const removeModule = async (moduleId: string, moduleName: Modules.MODULE_IDS) => {
        try {
            setLoading(true);
            await apiRequester.removeEventModule(clientId!, eventId!, moduleId);
            const eventModules = await apiRequester.getEventModules(clientId!, eventId!);
            context.setEventModules(eventModules);
            handleSuccess(`${moduleName?.charAt(0).toUpperCase()}${moduleName?.slice(1)} ${strings.moduleDeactivated}`);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            handleError(err);
        }
    };

    const processTableData = (booths: Array<any & Modules.Module>) => {
        return booths.map((booth, i) => {
            return {
                key: 'booth-list-item-' + i,
                ...booth,
            };
        });
    };

    const filterTableData = (modules: Modules.Module[]) => {
        if (!filter) return modules;
        else
            return modules.filter(module => {
                if (module.type?.toLowerCase().includes(filter) || module._id?.toLowerCase().includes(filter))
                    return true;
                else return false;
            });
    };

    const toggleModule = async (
        clientId: string,
        eventId: string,
        module: Modules.Module & { key?: string },
        type: boolean,
    ) => {
        try {
            setLoading(true);
            await apiRequester.toggleEventModule(clientId, eventId, module._id!, type);
            const modules = await apiRequester.getEventModules(clientId!, eventId);
            context.setEventModules(modules);
            handleSuccess(`${strings.moduleBeen} ${type ? strings.activated : strings.deactivated}.`);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            handleError(err);
        }
    };

    const columns = [
        {
            title: strings.module,
            dataIndex: 'type',
            render: (data: string) => `${data?.charAt(0).toUpperCase()}${data?.slice(1)}`,
        },
        {
            title: strings.id,
            dataIndex: '_id',
        },
        {
            title: strings.createdAt,
            dataIndex: 'createdAt',
            // eslint-disable-next-line react/display-name
            render: (ts: string) => <span>{moment(ts).toString()}</span>,
        },
        {
            title: strings.isActivated,
            dataIndex: 'activated',
            // eslint-disable-next-line react/display-name
            render: (data: string) => (
                <span>{data ? <Tag color="success">{strings.yes}</Tag> : <Tag color="error">{strings.no}</Tag>}</span>
            ),
        },
        {
            title: 'Actions',
            // eslint-disable-next-line react/display-name
            render: (data: any, module: Modules.Module) => (
                <>
                    {module.activated && (
                        <Button
                            size="small"
                            onClick={() => toggleModule(clientId!, eventId!, module, false)}
                            type="link"
                        >
                            {strings.deactivate}
                        </Button>
                    )}
                    {!module.activated && (
                        <Button
                            size="small"
                            type="link"
                            onClick={() => {
                                toggleModule(clientId!, eventId!, module, true);
                            }}
                        >
                            {strings.activate}
                        </Button>
                    )}
                    <Popconfirm
                        placement="topLeft"
                        title={strings.sure}
                        onConfirm={() => removeModule(module._id!, module.type!)}
                        okText={strings.yes}
                        cancelText={strings.no}
                    >
                        <Button
                            size="small"
                            type="link"
                            onClick={() => {
                                removeModule(module._id!, module.type!);
                            }}
                        >
                            {strings.delete}
                        </Button>
                    </Popconfirm>
                </>
            ),
        },
    ];

    const addModuleMenu = (
        <Menu onClick={showAddDrawer}>
            <Menu.Item key="authentication">{strings.auth}</Menu.Item>
            {/* <Menu.Item key="forms">Forms</Menu.Item> */}
        </Menu>
    );

    return (
        <>
            <div style={{ padding: '1.5rem' }}>
                <div style={{ display: 'flex' }}>
                    <Input
                        placeholder={strings.filter}
                        style={{ marginBottom: '1.5rem', marginRight: '1.5rem' }}
                        onChange={e => setFilter(e.target.value)}
                    />
                    <Dropdown overlay={addModuleMenu}>
                        <Button type="primary" icon={<PlusOutlined />}>
                            {strings.addModule}{' '}
                        </Button>
                    </Dropdown>
                </div>
                {/* <Table
                    loading={loading}
                    columns={columns}
                    dataSource={processTableData(filterTableData(context.eventModules))}
                    bordered={false}
                    pagination={false}
                    style={{ borderRadius: '4px', border: '1px solid #d9d9d9' }}
                    expandable={{
                        // eslint-disable-next-line react/display-name
                        expandedRowRender: (
                            record: Modules.Module & { moduleData: Modules.FormsModuleData } & {
                                moduleData: Modules.AuthModuleData;
                            },
                        ) => {
                            if (record.type === 'forms')
                                return (
                                    <FormModule
                                        clientId={clientId!}
                                        eventId={eventId!}
                                        module={record}
                                        target="event"
                                    />
                                );
                            else if (record.type === 'authentication')
                                return <AuthModule clientId={clientId!} eventId={eventId!} module={record} />;
                            else return <>Module data view not created</>;
                        },
                        // rowExpandable: record => record.type !== 'authentication',
                    }}
                    tableLayout="fixed"
                /> */}
            </div>
            <Drawer
                title={`Add ${addFormModuleName?.charAt(0).toUpperCase()}${addFormModuleName?.slice(1)} Module`}
                placement="right"
                closable={false}
                onClose={onCloseAddDrawer}
                visible={visibleAddDrawer}
                width="620px"
                footer={
                    <Space style={{ marginRight: '8px' }}>
                        <Button onClick={onCloseAddDrawer}>{strings.cancel}</Button>{' '}
                        <Button type="primary" loading={loading} onClick={() => activateModule(addFormModuleName)}>
                            {strings.add}
                        </Button>
                    </Space>
                }
                footerStyle={{ textAlign: 'right', marginRight: '8px' }}
                closeIcon={<CloseOutlined />}
            >
                {addFormModuleName === 'authentication' && (
                    <>
                        <Paragraph>
                            <LockOutlined style={{ fontSize: '5rem' }} />
                        </Paragraph>
                        <Paragraph>{strings.authDescription1}</Paragraph>
                        <Paragraph>{strings.authDescription2}</Paragraph>
                    </>
                )}

                {/* {addFormModuleName === 'forms' && (
                    <>
                        <Paragraph>
                            <FormOutlined style={{ fontSize: '5rem' }} />
                        </Paragraph>
                        <Paragraph>
                            This module allows you to ask the visitor to fill up a small form that can have open ended
                            or multiple choice questions. This form is sent to the user after the initial registration
                            process.
                        </Paragraph>
                        <Paragraph>All responses are recorded and can be viewed or exported in CSV format.</Paragraph>
                    </>
                )} */}
            </Drawer>
        </>
    );
};

export default EventModules;
