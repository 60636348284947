import React, { useEffect, useState } from 'react';

import { Card } from 'antd';
import LocalizedStrings from 'react-localization';
import { Tooltip, ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Bar, LabelList } from 'recharts';

const strings = new LocalizedStrings({
    en: {
        visitorCount: 'Total Visitor Count - Live',
    },
    de: {
        visitorCount: 'Besucher gesamt - Live',
    },
});

export const TotalVisitorCountSummary = ({
    totalVisitorCountSummary,
}: {
    totalVisitorCountSummary: EventsAnalytics.TotalVisitorCountSummary;
}) => {
    const { totalRegistrationCount, activeVisitorCount, inactiveVisitorCount } = totalVisitorCountSummary;
    const [loading, setLoading] = useState(false);

    const data = [
        { name: 'Registered', Visitors: totalRegistrationCount },
        { name: 'Active', Visitors: activeVisitorCount },
        { name: 'Inactive', Visitors: inactiveVisitorCount },
    ];

    useEffect(() => {
        if (!totalRegistrationCount) setLoading(true);
        else setLoading(false);
    }, [totalVisitorCountSummary]);

    return (
        <Card title={strings.visitorCount} size="small" loading={loading}>
            <ResponsiveContainer width="100%" height={350}>
                <BarChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis allowDecimals={false} />
                    <Tooltip />
                    <Bar dataKey="Visitors" fill="#1853DB">
                        <LabelList dataKey="Visitors" position="top" />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </Card>
    );
};

export default TotalVisitorCountSummary;
