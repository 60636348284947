import React, { useContext, useEffect, useState } from 'react';

import {
    CloseOutlined,
    ContactsOutlined,
    DeleteOutlined,
    DownloadOutlined,
    DownOutlined,
    EyeOutlined,
    NodeIndexOutlined,
    PlusCircleTwoTone,
    SaveOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {
    Button as AntdButton,
    Card,
    Col,
    Collapse,
    Drawer,
    Form,
    Input as AntdInput,
    Row,
    Select,
    Space,
    Table,
    Tag,
    Typography,
    Upload,
    DatePicker,
    Dropdown,
    Menu,
    Popconfirm,
} from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import axios from 'axios';
import download from 'downloadjs';
import { navigate } from 'gatsby';
import imageToBase64 from 'image-to-base64/browser';
import { Parser } from 'json2csv';
import moment, { Moment } from 'moment';
import LocalizedStrings from 'react-localization';
import store from 'store';
import styled from 'styled-components';
import vCardsJS from 'vcards-js';

import { VisitorJourney } from '.';
import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess } from '../utility';

const { Option } = Select;
const { Title } = Typography;
const getNavigatorLanguage = () => (navigator.languages && navigator.languages.length ? navigator.languages[0] : 'en');

const { RangePicker } = DatePicker;
const strings = new LocalizedStrings({
    en: {
        userCreated: 'User has been created.',
        userUpdated: 'User info has been updated.',
        email: 'Email',
        firstName: 'First Name',
        lastName: 'Last Name',
        role: 'Role',
        registered: 'Registered',
        actions: 'Actions',
        viewProfile: 'View Profile',
        userDeleted: 'User has been deleted.',
        picUpdated: 'Profile picture has been updated',
        picRemoved: 'Profile picture has been removed',
        optionDeleted: 'Option was deleted',
        filter: 'Filter users by name, email, id, company or designation',
        addUser: 'Add New User',
        adminCount: 'Total Admin Count',
        staffCount: 'Total Staff Count',
        sponsorCount: 'Total Sponsor Count',
        cancel: 'Cancel',
        enterEmail: 'Please enter user email',
        enterFirstName: 'Please enter user first name',
        enterLastName: 'Please enter user last name',
        enterMobile: 'Please enter user mobile number',
        enterCompany: 'Please company name',
        enterDesignation: 'Please enter designation',
        enterPass: 'Please enter user password',
        enterRole: 'Please select role',
        tnc: 'TnC Accepted',
        updates: 'Receive Updates',
        editUser: 'Edit User',
        id: 'ID',
        eventId: 'Event ID',
        clientId: 'Client ID',
        profPic: 'Profile Picture',
        picUploaded: 'Profile picture has been uploaded',
        remove: 'Remove',
        update: 'Update',
        emailVerified: 'Email Verified',
        name: 'Name',
        downloadVisitorActions: 'Download All Visitor Actions',
        download: 'Download',
        downloadVisitorProfiles: 'Download All Visitor Profiles',
        yes: 'Yes',
        no: 'No',
        sure: 'Are you sure you want to delete?',
    },
    de: {
        userCreated: 'Benutzer wurde angelegt',
        userUpdated: 'Benutzerinfo wurde aktualisiert',
        email: 'E-Mail-Adresse',
        firstName: 'Vorname',
        lastName: 'Nachname',
        role: 'Rolle',
        registered: 'Eingetragen',
        actions: 'Aktionen',
        viewProfile: 'Details ansehen',
        userDeleted: 'Benutzer wurde gelöscht',
        picUpdated: 'Profilbild wurde aktualisiert',
        picRemoved: 'Profilbild wurde entfernt',
        optionDeleted: 'Option wurde gelöscht',
        filter: 'Benutzer nach Namen, ID oder Unternehmen filtern',
        addUser: 'Neuen Benutzer hinzufügen',
        adminCount: 'Gesamtzahl Administratoren',
        staffCount: 'Gesamtzahl Mitarbeiter',
        sponsorCount: 'Gesamtzahl Sponsoren',
        cancel: 'Abbrechen',
        enterEmail: 'Bitte E-Mail-Adresse des Benutzers eingeben',
        enterFirstName: 'Bitte Vornamen des Benutzers eingeben',
        enterLastName: 'Bitte Nachnamen des Benutzers eingeben',
        enterMobile: 'Bitte Mobilnummer des Benutzers eingeben',
        enterCompany: 'Bitte Unternehmensnamen eingeben',
        enterDesignation: 'Bitte Position/Bezeichnung eingeben',
        enterPass: 'Bitte Benutzerpasswort eingeben',
        enterRole: 'Bitte Rolle auswählen',
        tnc: 'TnC akzeptiert',
        updates: 'Updates erhalten',
        editUser: 'Benutzer bearbeiten',
        id: 'ID',
        eventId: 'Event-ID',
        clientId: 'Kunden-ID',
        profPic: 'Profilbild',
        picUploaded: 'Profilbild wurde hochgeladen',
        remove: 'Entfernen',
        update: 'Aktualisieren',
        emailVerified: 'E-Mail-Adresse verifiziert',
        name: 'Name',
        downloadVisitorActions: 'Download All Visitor Actions',
        download: 'Herunterladen',
        downloadVisitorProfiles: 'Besucherprofile herunterladen',
        yes: 'Ja',
        no: 'Nein',
        sure: 'Sind Sie sicher, dass Sie löschen möchten?',
    },
});

const Input = styled(AntdInput)`
    height: 3rem;
`;
const Button = styled(AntdButton)`
    height: 3rem;
`;

export const UserList = ({
    clientId,
    eventId,
    boothId,
    filterRoles,
    showStats = true,
    padding = false,
    isOperatorList = false,
    showAddUser = true,
    editable = true,
    showBoothUsersOnly = false,
}: {
    clientId?: string | null;
    eventId?: string;
    boothId?: string;
    filterRoles?: Users.Role[];
    showStats?: boolean;
    padding?: boolean;
    isOperatorList?: boolean;
    showAddUser?: boolean;
    editable?: boolean;
    showBoothUsersOnly?: boolean;
}) => {
    const context = useContext(GlobalContext);
    const [visitorJourneyUser, setVisitorJourneyUser] = useState<Users.User>();
    const [visibleAddDrawer, setVisibleAddDrawer] = useState(false);
    const [visibleEditDrawer, setVisibleEditDrawer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState('');
    const [addForm] = Form.useForm();
    const [editForm] = Form.useForm();
    const loggedInUser = store.get('user') as Users.User;
    const [visitorActionsDates, setVisitorActionsDates] = useState<[Moment, Moment]>([
        moment().subtract('15', 'days'),
        moment(),
    ]);

    const refreshVisitors = async () => {
        try {
            if (clientId && eventId && boothId) {
                const users = await apiRequester.getVisitors(clientId, eventId, boothId);
                context.setClientUsers(users.length ? users.reverse() : []);
            }
        } catch (err) {
            handleError(err);
        }
    };

    useEffect(() => {
        setLoading(true);
        if (!clientId) navigate('/');
        else {
            if (eventId && boothId && showBoothUsersOnly) {
                refreshVisitors()
                    .then(() => setLoading(false))
                    .catch(handleError);
            } else if (eventId) {
                apiRequester
                    .getVisitors(clientId, eventId)
                    .then(users => context.setClientUsers(users.length ? users.reverse() : []))
                    .then(() => setLoading(false))
                    .catch(handleError);
            } else {
                apiRequester
                    .getOperators(clientId)
                    .then(users => context.setClientUsers(users.length ? users.reverse() : []))
                    .then(() => setLoading(false))
                    .catch(handleError);
            }
        }
    }, []);

    const showAddDrawer = () => {
        setVisibleAddDrawer(true);
    };

    const onCloseAddDrawer = () => {
        setVisibleAddDrawer(false);
    };

    const showEditDrawer = (user: Users.User) => {
        editForm.resetFields();
        editForm.setFieldsValue({
            ...user,
            role: user.roles?.length ? user.roles[0] : '',
            event: user.event?._id,
            client: user.client?._id,
        });
        setVisibleEditDrawer(true);
    };

    const onCloseEditDrawer = () => {
        setVisibleEditDrawer(false);
    };

    const onFinishAdding = async (user: {
        firstName: string;
        lastName: string;
        emailId: string;
        password?: string;
        role: string;
        designation?: string;
        mobile?: string;
    }) => {
        try {
            setLoading(true);
            if (isOperatorList) await apiRequester.createClientUser(clientId!, user);
            else {
                const authModule = context.eventModules.find(module => module.type === 'authentication');
                if (!authModule)
                    throw new Error('Auth module not found. Please add auth module before registering a user.');
                const { role, ...userData } = user;
                await apiRequester.createVisitorAccount(clientId!, eventId!, authModule._id!, userData);
            }
            showBoothUsersOnly ? await refreshVisitors() : await context.refreshClientUsers(clientId!);
            setLoading(false);
            addForm.resetFields();
            onCloseAddDrawer();
            handleSuccess(strings.userCreated!);
        } catch (err) {
            handleError(err);
            setLoading(false);
        }
    };

    const onFinishEditing = async (user: {
        _id: string;
        firstName: string;
        lastName: string;
        emailId: string;
        password?: string;
        role: string;
        mobile?: string;
        designation?: string;
        tncAccepted?: boolean;
        optedForUpdates?: boolean;
        activated?: boolean;
    }) => {
        try {
            setLoading(true);
            const response = await apiRequester.editClientUser(clientId!, user._id, {
                firstName: user.firstName,
                lastName: user.lastName,
                emailId: user.emailId,
                role: user.role,
                mobile: user.mobile,
                designation: user.designation,
                tncAccepted: user.tncAccepted,
                optedForUpdates: user.optedForUpdates,
                activated: user.activated,
            });
            showBoothUsersOnly ? await refreshVisitors() : await context.refreshClientUsers(clientId!);
            setLoading(false);
            editForm.resetFields();
            onCloseEditDrawer();
            handleSuccess(strings.userUpdated!);
        } catch (err) {
            handleError(err);
            setLoading(false);
        }
    };

    const transformDataForTable = (users: Array<any & Users.User>) => {
        return (
            users
                // .filter(user => !user.roles?.includes('visitor'))
                .map((user, i) => {
                    const startTime = new Date(user.startTime!);
                    const endTime = new Date(user.endTime!);
                    return {
                        key: 'user-list-item-' + i,
                        ...user,
                        startTime: moment(startTime),
                        endTime: moment(endTime),
                    };
                })
        );
    };

    const filterTableDataAsPerUserInput = (users: Users.User[]) => {
        if (!filter) return users;
        else
            return users.filter(user => {
                if (
                    user.emailId?.toLowerCase().includes(filter) ||
                    user._id?.toLowerCase().includes(filter) ||
                    user.firstName?.toLowerCase().includes(filter) ||
                    user.lastName?.toLowerCase().includes(filter) ||
                    user.company?.toLowerCase().includes(filter) ||
                    user.designation?.toLowerCase().includes(filter)
                )
                    return true;
                else return false;
            });
    };

    const filterUserWithRoles = (users: Users.User[]) => {
        if (!filterRoles || !filterRoles.length) return users;
        else return users.filter(user => user.roles?.map(role => filterRoles?.includes(role)).includes(true));
    };

    const columns = [
        {
            title: '',
            dataIndex: 'profilePicture',
            // eslint-disable-next-line react/display-name
            render: (profilePicture: Users.User['profilePicture']) => {
                return (
                    <Avatar src={profilePicture?.link} size="large">
                        <UserOutlined />
                    </Avatar>
                );
            },
            width: '60px',
        },
        {
            title: strings.name,
            dataIndex: 'fullName',
            render: (data: any, user: Users.User) => {
                let name = '';
                const { company, designation, roles } = user;
                const isVisitor = roles?.includes('visitor');
                if (user.firstName) name += user.firstName;
                if (user.lastName) name += ' ' + user.lastName;
                if (name)
                    return (
                        <Space direction="vertical" size="small">
                            <Typography.Text>{name}</Typography.Text>
                            {isVisitor && (
                                <Typography.Text strong>
                                    {designation} - {company}
                                </Typography.Text>
                            )}
                        </Space>
                    );
                else return '-';
            },
            width: '20%',
        },
        {
            title: strings.email,
            dataIndex: 'emailId',
            render: (data: string) => {
                if (data) return data;
                else return '-';
            },
            width: '30%',
        },
        // {
        //     title: 'Company',
        //     dataIndex: 'company',
        // },
        // {
        //     title: 'Designation',
        //     dataIndex: 'designation',
        // },
        {
            title: strings.role,
            dataIndex: 'roles',
            // eslint-disable-next-line react/display-name
            render: (roles: string[]) => {
                return (
                    <span>
                        {roles.map((role, index) => (
                            <Tag key={index}>{role}</Tag>
                        ))}
                    </span>
                );
            },
        },
        {
            title: strings.registered,
            dataIndex: 'createdAt',
            // eslint-disable-next-line react/display-name
            render: (ts: string) => <span>{moment(ts).locale(getNavigatorLanguage()).fromNow()}</span>,
        },
        {
            title: strings.actions,
            width: '250px',
            // eslint-disable-next-line react/display-name
            render: (user: Users.User) => {
                const { roles } = user;
                const isVisitor = roles?.includes('visitor');
                return (
                    <Space>
                        <Button
                            size="small"
                            type="link"
                            onClick={() => {
                                showEditDrawer(user);
                            }}
                            icon={<EyeOutlined />}
                        >
                            {strings.viewProfile}
                        </Button>
                        {isVisitor && (
                            <Button
                                icon={<NodeIndexOutlined />}
                                type="link"
                                size="small"
                                onClick={() => setVisitorJourneyUser(user)}
                            >
                                Track
                            </Button>
                        )}
                    </Space>
                );
            },
        },
    ];

    const deleteClientUser = async (userId: string) => {
        try {
            setLoading(true);
            await apiRequester.deleteClientUser(clientId!, userId!);
            showBoothUsersOnly ? await refreshVisitors() : await context.refreshClientUsers(clientId!);
            onCloseEditDrawer();
            editForm.resetFields();
            handleSuccess(strings.userDeleted!);
            setLoading(false);
        } catch (err) {
            handleError(err);
        }
    };

    const countUsers = ({ users, role }: { users: Users.User[]; role: 'staff' | 'admin' | 'sponsor' }) => {
        return users.filter(user => user.roles?.includes(role)).length;
    };

    const fileProps = {
        name: 'file',
        onChange({ file, fileList }: { file?: any; fileList: any }) {
            if (file.status === 'done') {
                setLoading(false);
                handleSuccess(strings.picUpdated!);
                showBoothUsersOnly
                    ? context.refreshClientUsers(clientId!, eventId!, boothId!).catch(handleError)
                    : context.refreshClientUsers(clientId!).catch(handleError);
                editForm.setFieldsValue({ profilePicture: file.response });
            } else if (file.status === 'uploading') {
                setLoading(true);
                return;
            } else if (file.status === 'error') setLoading(false);
        },
        headers: { Authorization: 'Bearer ' + store.get('token') },
    };

    const removeProfilePicture = async () => {
        try {
            setLoading(true);
            await apiRequester.deleteProfilePicture({
                clientId: editForm.getFieldValue('client'),
                eventId: editForm.getFieldValue('event'),
                userId: editForm.getFieldValue('_id'),
            });
            handleSuccess(strings.picRemoved!);
            editForm.setFieldsValue({ profilePicture: undefined });
            showBoothUsersOnly ? await refreshVisitors() : await context.refreshClientUsers(clientId!);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const getFieldAnswer = (answers: string[]) =>
        answers?.map((answer: string, index: number) => {
            return <Tag key={`answer-${index}`}>{answer}</Tag>;
        });

    const downloadVisitorActions = async () => {
        try {
            setLoading(true);
            let visitorActions;
            if (showBoothUsersOnly) {
                visitorActions = await apiRequester.findAllActionsOfBooth({
                    clientId: clientId!,
                    eventId: context.activeEvent?._id!,
                    boothId: showBoothUsersOnly ? boothId! : undefined,
                    startDate: visitorActionsDates[0].startOf('day').toISOString(),
                    endDate: visitorActionsDates[1].endOf('day').toISOString(),
                });
            } else {
                visitorActions = await apiRequester.findAllActionsOfEvent({
                    clientId: clientId!,
                    eventId: context.activeEvent?._id!,
                    boothId: showBoothUsersOnly ? boothId! : undefined,
                    startDate: visitorActionsDates[0].startOf('day').toISOString(),
                    endDate: visitorActionsDates[1].endOf('day').toISOString(),
                });
            }
            const fields = [
                'Time',
                'Action',
                'User ID',
                'User First Name',
                'User Last Name',
                'User Email',
                'User Company',
                'User Designation',
            ];
            const opts = { fields };
            const parser = new Parser(opts);
            const csv = parser.parse(
                visitorActions.map(action => ({
                    Time: moment(action.createdAt).toString(),
                    Action: action.action,
                    'User ID': typeof action.user === 'string' ? action.user : action.user?._id! || '',
                    'User First Name': typeof action.user === 'string' ? action.user : action.user?.firstName || '',
                    'User Last Name': typeof action.user === 'string' ? action.user : action.user?.lastName || '',
                    'User Email': typeof action.user === 'string' ? action.user : action.user?.emailId || '',
                    'User Company': typeof action.user === 'string' ? action.user : action.user?.company || '',
                    'User Designation': typeof action.user === 'string' ? action.user : action.user?.designation || '',
                })),
            );
            download(csv, `visitor-actions-event-${context.activeEvent?._id!}.csv`, 'text/csv');
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const downloadVisitorProfiles = async () => {
        try {
            setLoading(true);
            const fields = ['ID', 'First Name', 'Last Name', 'Email', 'Company', 'Designation'];
            const extraFields: string[] = [];
            filterTableDataAsPerUserInput(filterUserWithRoles(context.clientUsers)).map(user => {
                const extras = user.extras;
                if (extras && Array.isArray(extras) && extras.length) {
                    extras.map(extra => {
                        extra.question && extraFields.push(extra.question);
                    });
                }
            });
            const uniqueExtraFields = [...new Set(extraFields)];
            fields.push(...uniqueExtraFields);
            const opts = { fields };
            const parser = new Parser(opts);
            const csv = parser.parse(
                filterTableDataAsPerUserInput(filterUserWithRoles(context.clientUsers)).map(user => {
                    const extraFields: { [key: string]: string } = {};
                    const extras = user.extras;
                    if (extras && Array.isArray(extras) && extras.length) {
                        extras.map(extra => {
                            const type = extra.type;
                            const question: string = extra.question;
                            const answers = extra.answers;
                            const stringifiedAnswers = Array.isArray(answers) ? answers.join(', ') : answers;
                            if (question) {
                                extraFields[question] =
                                    type === 'picture' && answers && Array.isArray(answers) && answers.length
                                        ? answers[0].link
                                        : stringifiedAnswers;
                            }
                        });
                    }
                    return {
                        ID: user._id || '',
                        'First Name': user.firstName || '',
                        'Last Name': user.lastName || '',
                        Email: user.emailId || '',
                        Company: user.company || '',
                        Designation: user.designation || '',
                        Extras: JSON.stringify(user.extras) || '',
                        ...extraFields,
                    };
                }),
            );
            download(csv, `visitor-profiles-all.csv`, 'text/csv');
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const getImageType = async (link: string): Promise<string> => {
        const res = await axios.head(link);
        const headers = res.headers;
        const contentType = headers['content-type'] as string;
        if (contentType.includes('png')) return 'png';
        else return 'jpg';
    };

    const downloadContact = async () => {
        try {
            const user = editForm.getFieldsValue();
            const vCard = vCardsJS();

            const imageType = user.profilePicture?.link ? await getImageType(user.profilePicture?.link) : undefined;
            const image = user.profilePicture?.link ? await imageToBase64(user.profilePicture?.link) : undefined;

            vCard.firstName = user.firstName || '';
            vCard.lastName = user.lastName || '';
            vCard.organization = user.company || '';
            imageType && image && vCard.photo.embedFromString(image, `image/${imageType}`);
            vCard.workPhone = user.mobile || '';
            vCard.email = user.emailId || '';
            vCard.uid = user._id;
            vCard.title = user.designation || '';

            download(vCard.getFormattedString(), `${user.firstName || user._id}.vcf`, 'text/vcard');
        } catch (err) {
            handleError(err);
        }
    };

    return (
        <>
            <div style={{ padding: padding ? '1.5rem' : undefined }}>
                <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
                    <Col xs={24} sm={24} md={isOperatorList ? 18 : !showAddUser ? 12 : 6}>
                        <Input
                            placeholder={strings.filter}
                            style={{
                                height: '3rem',
                            }}
                            onChange={e => setFilter(e.target.value.toLowerCase())}
                        />
                    </Col>
                    {showAddUser && (
                        <Col xs={24} sm={24} md={6}>
                            <Button
                                type="dashed"
                                icon={<PlusCircleTwoTone />}
                                onClick={showAddDrawer}
                                style={{ height: '3rem' }}
                                block
                            >
                                {strings.addUser}{' '}
                            </Button>
                        </Col>
                    )}
                    {!isOperatorList && (
                        <Col xs={24} sm={24} md={6}>
                            <RangePicker
                                style={{ width: '100%', height: '3rem' }}
                                format="DD-MM-YYYY"
                                value={visitorActionsDates}
                                onChange={setVisitorActionsDates as any}
                                onOk={setVisitorActionsDates as any}
                            />
                        </Col>
                    )}
                    {!isOperatorList && (
                        <Col xs={24} sm={24} md={6}>
                            <Dropdown
                                overlay={
                                    <Menu>
                                        <Menu.Item>
                                            <Button type="text" onClick={downloadVisitorActions}>
                                                {strings.downloadVisitorActions}
                                            </Button>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <Button type="text" onClick={downloadVisitorProfiles}>
                                                {strings.downloadVisitorProfiles}
                                            </Button>
                                        </Menu.Item>
                                    </Menu>
                                }
                            >
                                <Button icon={<DownOutlined />} loading={loading} block>
                                    {strings.download}
                                </Button>
                            </Dropdown>
                        </Col>
                    )}
                </Row>

                {showStats && (
                    <div style={{ marginBottom: '1.5rem', display: 'flex', justifyContent: 'space-between' }}>
                        <Card style={{ width: 'calc(33% - 0.5rem)' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                <img src="/admin.png" height="64" />
                                <div>
                                    {strings.adminCount}
                                    <Title>{countUsers({ users: context.clientUsers, role: 'admin' })}</Title>
                                </div>
                            </div>
                        </Card>
                        <Card style={{ width: 'calc(33% - 0.5rem)' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                <img src="/staff.png" height="64" />
                                <div>
                                    {strings.staffCount}
                                    <Title>{countUsers({ users: context.clientUsers, role: 'staff' })}</Title>
                                </div>
                            </div>
                        </Card>
                        <Card style={{ width: 'calc(33% - 0.5rem)' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                <img src="/sponsor.png" height="64" />
                                <div>
                                    {strings.sponsorCount}
                                    <Title>{countUsers({ users: context.clientUsers, role: 'sponsor' })}</Title>
                                </div>
                            </div>
                        </Card>
                    </div>
                )}
                <Table
                    loading={loading}
                    sticky={true}
                    columns={columns}
                    dataSource={transformDataForTable(
                        filterTableDataAsPerUserInput(filterUserWithRoles(context.clientUsers)),
                    )}
                    bordered={false}
                    pagination={false}
                    style={{ borderRadius: '4px', border: '1px solid #d9d9d9' }}
                    size="large"
                />
            </div>
            <Drawer
                title={strings.addUser}
                placement="right"
                closable={false}
                onClose={onCloseAddDrawer}
                visible={visibleAddDrawer}
                width="620px"
                footer={
                    <Space style={{ marginRight: '8px' }}>
                        <Button onClick={onCloseAddDrawer}>{strings.cancel}</Button>{' '}
                        <Button type="primary" loading={loading} onClick={addForm.submit}>
                            Add
                        </Button>
                    </Space>
                }
                footerStyle={{ textAlign: 'right', marginRight: '8px' }}
                closeIcon={<CloseOutlined />}
            >
                <Form form={addForm} onFinish={onFinishAdding}>
                    <Form.Item
                        name="emailId"
                        label="Email"
                        labelCol={{ span: 6 }}
                        rules={[{ required: true, message: strings.enterEmail }]}
                    >
                        <Input style={{ height: '3rem' }} />
                    </Form.Item>
                    <Form.Item
                        name="firstName"
                        label="First Name"
                        labelCol={{ span: 6 }}
                        rules={[{ required: true, message: strings.enterFirstName }]}
                    >
                        <Input style={{ height: '3rem' }} />
                    </Form.Item>
                    <Form.Item
                        name="lastName"
                        label="Last Name"
                        labelCol={{ span: 6 }}
                        rules={[{ required: true, message: strings.enterLastName }]}
                    >
                        <Input style={{ height: '3rem' }} />
                    </Form.Item>
                    <Form.Item
                        name="mobile"
                        label="Mobile"
                        labelCol={{ span: 6 }}
                        rules={[{ message: strings.enterMobile }]}
                    >
                        <Input style={{ height: '3rem' }} />
                    </Form.Item>
                    <Form.Item
                        name="company"
                        label="Company"
                        labelCol={{ span: 6 }}
                        rules={[{ message: strings.enterCompany }]}
                    >
                        <Input style={{ height: '3rem' }} />
                    </Form.Item>
                    <Form.Item
                        name="designation"
                        label="Designation"
                        labelCol={{ span: 6 }}
                        rules={[{ message: strings.enterDesignation }]}
                    >
                        <Input style={{ height: '3rem' }} />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Password"
                        labelCol={{ span: 6 }}
                        rules={[{ required: true, message: strings.enterPass }]}
                    >
                        <Input.Password style={{ height: '3rem' }} />
                    </Form.Item>
                    <Form.Item
                        name="role"
                        label="Role"
                        labelCol={{ span: 6 }}
                        rules={[{ required: true, message: strings.enterRole }]}
                    >
                        <Select>
                            {filterRoles?.map(role => (
                                <Option key={`add-role-${role}`} value={role} style={{ textTransform: 'capitalize' }}>
                                    {role}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label={strings.tnc} labelCol={{ span: 6 }} name="tncAccepted" valuePropName="checked">
                        <Checkbox />
                    </Form.Item>
                    <Form.Item
                        name="optedForUpdates"
                        label={strings.updates}
                        labelCol={{ span: 6 }}
                        valuePropName="checked"
                    >
                        <Checkbox />
                    </Form.Item>
                </Form>
            </Drawer>
            <VisitorJourney visitor={visitorJourneyUser} setVisitor={setVisitorJourneyUser} />
            <Drawer
                title={
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <div className="ant-drawer-title">{strings.editUser}</div>
                        <Button
                            type="link"
                            size="small"
                            style={{ height: 'auto' }}
                            icon={<ContactsOutlined />}
                            onClick={downloadContact}
                        >
                            Download Contact
                        </Button>
                    </div>
                }
                placement="right"
                closable={false}
                onClose={onCloseEditDrawer}
                visible={visibleEditDrawer}
                width="620px"
                footer={
                    <>
                        {loggedInUser?.roles?.includes('super-admin') && (
                            <Popconfirm
                                placement="topLeft"
                                title={strings.sure}
                                onConfirm={() => deleteClientUser(editForm.getFieldValue('_id'))}
                                okText={strings.yes}
                                cancelText={strings.no}
                            >
                                <Button danger style={{ marginLeft: '8px' }} icon={<DeleteOutlined />}>
                                    Delete
                                </Button>
                            </Popconfirm>
                        )}
                        <Space style={{ marginRight: '8px' }}>
                            <Button onClick={onCloseEditDrawer} icon={<CloseOutlined />}>
                                Cancel
                            </Button>{' '}
                            {(loggedInUser?.roles?.includes('super-admin') ||
                                loggedInUser?.roles?.includes('admin')) && (
                                <>
                                    <Button
                                        type="primary"
                                        loading={loading}
                                        onClick={editForm.submit}
                                        icon={<SaveOutlined />}
                                    >
                                        Save
                                    </Button>
                                </>
                            )}
                        </Space>
                    </>
                }
                footerStyle={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                closeIcon={<CloseOutlined />}
            >
                <Form form={editForm} onFinish={onFinishEditing}>
                    <Form.Item labelCol={{ span: 6 }} label="ID" name="_id" rules={[{ required: true }]} hidden={false}>
                        <Input disabled={true} style={{ height: '3rem' }} />
                    </Form.Item>
                    <Form.Item labelCol={{ span: 6 }} label="Event ID" name="event" hidden={true}>
                        <Input disabled={true} style={{ height: '3rem' }} />
                    </Form.Item>
                    <Form.Item labelCol={{ span: 6 }} label="Client ID" name="client" hidden={true}>
                        <Input disabled={true} style={{ height: '3rem' }} />
                    </Form.Item>
                    <Form.Item labelCol={{ span: 6 }} label="Profile Picture" hidden={false} name="profilePicture">
                        <Space direction="horizontal">
                            <Avatar size={100} src={editForm.getFieldValue('profilePicture')?.link}>
                                <UserOutlined />
                            </Avatar>
                            <>
                                <Upload
                                    {...fileProps}
                                    accept="image/png, image/jpeg"
                                    itemRender={() => <></>}
                                    action={apiRequester.getProfilePictureUploadUrl({
                                        clientId: editForm.getFieldValue('client'),
                                        eventId: editForm.getFieldValue('event'),
                                        userId: editForm.getFieldValue('_id'),
                                    })}
                                    multiple={false}
                                    maxCount={1}
                                    onRemove={data => {
                                        apiRequester
                                            .deleteProfilePicture({
                                                clientId: editForm.getFieldValue('client'),
                                                eventId: editForm.getFieldValue('event'),
                                                userId: editForm.getFieldValue('_id'),
                                            })
                                            .catch(handleError);

                                        handleSuccess(strings.picUploaded!);
                                    }}
                                >
                                    <Button type="link">{strings.update}</Button>
                                </Upload>
                                {editForm.getFieldValue('profilePicture')?.link && (
                                    <Button type="link" danger disabled={loading} onClick={removeProfilePicture}>
                                        {strings.remove}
                                    </Button>
                                )}
                            </>
                        </Space>
                    </Form.Item>
                    <Form.Item
                        name="emailId"
                        label="Email"
                        labelCol={{ span: 6 }}
                        rules={[{ required: true, message: strings.enterEmail }]}
                    >
                        <Input style={{ height: '3rem' }} />
                    </Form.Item>
                    <Form.Item
                        name="firstName"
                        label="First Name"
                        labelCol={{ span: 6 }}
                        rules={[{ required: true, message: strings.enterFirstName }]}
                    >
                        <Input style={{ height: '3rem' }} />
                    </Form.Item>
                    <Form.Item
                        name="lastName"
                        label="Last Name"
                        labelCol={{ span: 6 }}
                        rules={[{ required: true, message: strings.enterLastName }]}
                    >
                        <Input style={{ height: '3rem' }} />
                    </Form.Item>
                    <Form.Item
                        name="mobile"
                        label="Mobile"
                        labelCol={{ span: 6 }}
                        rules={[{ message: strings.enterMobile }]}
                    >
                        <Input style={{ height: '3rem' }} />
                    </Form.Item>
                    <Form.Item
                        name="company"
                        label="Company"
                        labelCol={{ span: 6 }}
                        rules={[{ message: strings.enterCompany }]}
                    >
                        <Input style={{ height: '3rem' }} />
                    </Form.Item>
                    <Form.Item
                        name="designation"
                        label="Designation"
                        labelCol={{ span: 6 }}
                        rules={[{ message: strings.enterDesignation }]}
                    >
                        <Input style={{ height: '3rem' }} />
                    </Form.Item>
                    <Form.Item
                        name="role"
                        label="Role"
                        labelCol={{ span: 6 }}
                        rules={[{ required: true, message: strings.enterRole }]}
                    >
                        <Select style={{ height: '3rem' }}>
                            {isOperatorList ? (
                                <>
                                    <Option value="admin">Admin</Option>
                                    <Option value="staff">Staff</Option>
                                    <Option value="sponsor">Sponsor</Option>
                                </>
                            ) : (
                                <Option value="visitor">Visitor</Option>
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item label={strings.tnc} labelCol={{ span: 6 }} name="tncAccepted" valuePropName="checked">
                        <Checkbox />
                    </Form.Item>
                    <Form.Item
                        name="optedForUpdates"
                        label={strings.updates}
                        labelCol={{ span: 6 }}
                        valuePropName="checked"
                    >
                        <Checkbox />
                    </Form.Item>
                    <Form.Item
                        name="activated"
                        label={strings.emailVerified}
                        labelCol={{ span: 6 }}
                        valuePropName="checked"
                    >
                        <Checkbox />
                    </Form.Item>
                    <Form.Item label="Extras" labelCol={{ span: 6 }}>
                        <Collapse
                            activeKey={editForm.getFieldValue('extras')?.map((field: any) => field._id)}
                            expandIcon={() => <span></span>}
                            expandIconPosition="right"
                        >
                            {((editForm.getFieldValue('extras') as Users.User['extras']) || []).map(field => (
                                <Collapse.Panel
                                    key={field._id}
                                    header={<div dangerouslySetInnerHTML={{ __html: field.question }} />}
                                >
                                    {field.type === 'input' || field.type === 'textarea' ? (
                                        field.answers
                                    ) : field.type === 'phone' ? (
                                        <>
                                            {field.answers.code} - {field.answers.phone}
                                        </>
                                    ) : field.type === 'country-city' ? (
                                        <>
                                            Country: {field.answers.country} || City: {field.answers.city}
                                        </>
                                    ) : field.type === 'picture' ? (
                                        <img src={field.answers[0].link} height={200} width="100%" />
                                    ) : field.type === 'select' ? (
                                        <>{getFieldAnswer(field.answers)}</>
                                    ) : field.type === 'booth-tree' ? (
                                        <>{getFieldAnswer(field.answers)}</>
                                    ) : field.type === 'checkbox' ? (
                                        <>{field.answers ? 'Checked' : 'Un-checked'}</>
                                    ) : (
                                        <>-</>
                                    )}
                                </Collapse.Panel>
                            ))}
                        </Collapse>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

export default UserList;
