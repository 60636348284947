import React, { useEffect, useState } from 'react';

import { Card, Typography } from 'antd';
import { string } from 'prop-types';
import LocalizedStrings from 'react-localization';
import { Tooltip, ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Bar, LabelList } from 'recharts';

const strings = new LocalizedStrings({
    en: {
        visitorCount: 'Online Visitor Count - Live',
        visitor: 'Visitor',
        visitors: 'Visitors',
    },
    de: {
        visitorCount: 'Besucher online - Live',
        visitor: 'Besucher',
        visitors: 'Besucher',
    },
});

export const OnlineVisitorCountSummary = ({
    onlineVisitorCountSummary,
    boothId,
}: {
    onlineVisitorCountSummary: EventsAnalytics.OnlineVisitorCountSummary;
    boothId?: string;
}) => {
    const [loading, setLoading] = useState(false);
    const data = Object.keys(onlineVisitorCountSummary).map(boothId => {
        const boothData = onlineVisitorCountSummary[boothId];
        return {
            name: boothData?.name,
            Visitors: boothData?.visitorCount,
        };
    });

    useEffect(() => {
        if (!Object.keys(onlineVisitorCountSummary).length) setLoading(true);
        else setLoading(false);
    }, [onlineVisitorCountSummary]);

    return (
        <Card title={strings.visitorCount} size="small" loading={loading}>
            {boothId ? (
                <>
                    <Typography.Title style={{ textAlign: 'center' }}>
                        {onlineVisitorCountSummary[boothId]?.visitorCount || 0} <br />{' '}
                        {onlineVisitorCountSummary[boothId] && onlineVisitorCountSummary[boothId].visitorCount > 1
                            ? strings.visitors
                            : strings.visitor}
                    </Typography.Title>
                </>
            ) : (
                <ResponsiveContainer width="100%" height={350}>
                    <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis allowDecimals={false} />
                        <Tooltip />
                        <Bar dataKey="Visitors" fill="#1853DB">
                            <LabelList dataKey="value" position="top" />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            )}
        </Card>
    );
};

export default OnlineVisitorCountSummary;
