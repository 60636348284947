import React, { useContext, useEffect, useState } from 'react';

import { EditOutlined, PlusOutlined, SaveOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, List, Popconfirm, Popover, Space, Typography } from 'antd';
import LocalizedStrings from 'react-localization';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess } from '../utility';

const { Search } = Input;
const strings = new LocalizedStrings({
    en: {
        categoryCreated: 'Booth category has been created',
        categoryUpdated: 'Booth category has been updated',
        categoryDeleted: 'Booth category has been deleted',
        categoryName: 'Category Name',
        addCategory: 'Add Category',
        yes: 'Yes',
        no: 'No',
        sure: 'Are you sure you want to delete?',
    },
    de: {
        categoryCreated: 'Standkategorie wurde erzeugt',
        categoryUpdated: 'Standkategorie wurde aktualisiert',
        categoryDeleted: 'Standkategorie wurde gelöscht',
        categoryName: 'Kategorie Name',
        addCategory: 'Kategorie hinzufügen',
        yes: 'Ja',
        no: 'Nein',
        sure: 'Sind Sie sicher, dass Sie löschen möchten?',
    },
});

export const BoothCategories = () => {
    const context = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const [editCategoryId, setEditCategoryId] = useState<string>();

    const addCategory = async (name: string) => {
        try {
            setLoading(true);
            await apiRequester.addBoothCategory(context.activeEvent?.client?._id!, context.activeEvent?._id!, name);
            await context.refreshActiveEvent(context.activeEvent?.client?._id!, context.activeEvent?._id!);
            setLoading(false);
            handleSuccess(strings.categoryCreated!);
        } catch (err) {
            setLoading(false);
            handleError(err);
        }
    };

    const updateCategory = async (id: string, name: string) => {
        try {
            setLoading(true);
            await apiRequester.updateBoothCategory(
                context.activeEvent?.client?._id!,
                context.activeEvent?._id!,
                id,
                name,
            );
            await context.refreshActiveEvent(context.activeEvent?.client?._id!, context.activeEvent?._id!);
            setLoading(false);
            handleSuccess(strings.categoryUpdated!);
        } catch (err) {
            setLoading(false);
            handleError(err);
        } finally {
            setEditCategoryId(undefined);
        }
    };

    const deleteCategory = async (id: string) => {
        try {
            setLoading(true);
            await apiRequester.deleteBoothCategory(context.activeEvent?.client?._id!, context.activeEvent?._id!, id);
            await context.refreshActiveEvent(context.activeEvent?.client?._id!, context.activeEvent?._id!);
            setLoading(false);
            handleSuccess(strings.categoryDeleted!);
        } catch (err) {
            setLoading(false);
            handleError(err);
        }
    };

    const AddCategory = () => {
        return (
            <Search
                placeholder={strings.categoryName}
                enterButton={<PlusCircleOutlined />}
                onSearch={addCategory}
                loading={loading}
            />
        );
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ textAlign: 'right', marginBottom: '1.5rem' }}>
                <Popover placement="left" content={<AddCategory />} trigger="hover">
                    <Button type="primary" icon={<PlusOutlined />}>
                        {strings.addCategory}
                    </Button>
                </Popover>
            </div>
            <List
                grid={{ column: 1 }}
                dataSource={context.activeEvent?.categories}
                renderItem={item => (
                    <List.Item>
                        <Card>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                {editCategoryId === item._id ? (
                                    <Search
                                        defaultValue={item.name}
                                        style={{ marginRight: '0.5rem' }}
                                        enterButton={<SaveOutlined />}
                                        onSearch={name => updateCategory(item._id, name)}
                                        loading={loading}
                                    />
                                ) : (
                                    item.name
                                )}
                                <Space>
                                    <Button shape="circle">
                                        <EditOutlined onClick={() => setEditCategoryId(item._id)} />
                                    </Button>
                                    <Popconfirm
                                        placement="topLeft"
                                        title={strings.sure}
                                        onConfirm={() => deleteCategory(item._id)}
                                        okText={strings.yes}
                                        cancelText={strings.no}
                                    >
                                        <Button shape="circle" danger loading={loading} icon={<DeleteOutlined />} />
                                    </Popconfirm>
                                </Space>
                            </div>
                        </Card>
                    </List.Item>
                )}
            />
        </div>
    );
};

export default BoothCategories;
