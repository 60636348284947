import React, { useEffect, useState } from 'react';

import { InboxOutlined, PlusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import {
    Button,
    Card,
    Tag,
    Table,
    Divider,
    Typography,
    Modal,
    Form,
    Input,
    Select,
    Space,
    DatePicker,
    Upload,
} from 'antd';
import moment from 'moment';
import LocalizedStrings from 'react-localization';
import store from 'store';

import { useBoothCommunication } from '../hooks';
import { apiRequester, handleError, handleSuccess } from '../utility';
import { generateNotifierMessage, LIVE_STREAM_SPEAKER_ACTIONS } from '../utility/NotifierMessages';

const strings = new LocalizedStrings({
    en: {
        newRequest: 'New Request',
        request: 'Request',
        liveStream: 'Live Stream',
        startTime: 'Start Time',
        preferredStartTime: 'Your preferred time to start your presentation',
        endTime: 'End Time',
        preferredEndTime: 'Your preferred time to end your presentation',
        description: 'Description',
        descriptionExtra: 'Tell a few lines about what will you be presenting',
        files: 'Files',
        requestSuccess: 'You request has been sent successfully',
        uploadFile: 'Click or drag your supporting files to this area to upload',
        module: 'Module',
        booth: 'Booth',
    },
    de: {
        newRequest: 'Neue Anfragen',
        request: 'Anfrage',
        liveStream: 'Liveübertragung',
        startTime: 'Startzeit',
        preferredStartTime: 'Ihre bevorzugte Zeit, um Ihre Präsentation zu beginnen',
        endTime: 'Endzeit',
        preferredEndTime: 'Ihre bevorzugte Zeit, um Ihre Präsentation zu beenden',
        description: 'Beschreibung',
        descriptionExtra: 'Erzählen Sie ein paar Zeilen darüber, was Sie präsentieren werden',
        files: 'Dateien',
        requestSuccess: 'Ihre Anfrage wurde erfolgreich gesendet',
        uploadFile: 'Klicken oder ziehen Sie Ihre unterstützenden Dateien in diesen Bereich, um sie hochzuladen',
        module: 'Modul',
        booth: 'Verkaufsstand',
    },
});

export const NewLiveStreamSpeakRequestModal = ({
    clientId,
    eventId,
    showModal,
    setShowModal,
    refreshRequests,
}: {
    clientId: string;
    eventId: string;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    refreshRequests: () => Promise<void>;
}) => {
    const [loading, setLoading] = useState(false);
    const [liveStreamModules, setLiveStreamModules] = useState<Modules.Module<Modules.LiveStreamingData>[]>([]);
    const [addForm] = Form.useForm();
    const [uploadEnabled, setUploadEnabled] = useState(true);
    const [boothId, setBoothId] = useState<string>();
    const [moduleId, setModuleId] = useState<string>();
    const [uploadUrl, setUploadUrl] = useState('#');
    const { publish } = useBoothCommunication();

    const getLiveStreamModules = async () => {
        try {
            setLoading(true);
            const modules = await apiRequester.getLiveStreams({ clientId, eventId });
            setLiveStreamModules(modules);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getLiveStreamModules();
    }, [clientId, eventId, showModal]);

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const submitAddForm = async (values: any) => {
        try {
            setLoading(true);
            const [boothId, moduleId] = values.boothModule.split('-');
            const fileIds = values.files?.map((file: any) => file?.response?._id);
            const description = values.description;
            const fromTime = values.fromTime as moment.Moment;
            const toTime = values.toTime as moment.Moment;
            await apiRequester.requestToSpeak({
                clientId,
                eventId,
                boothId,
                moduleId,
                files: fileIds,
                description,
                fromTime: fromTime.toISOString(),
                toTime: toTime.toISOString(),
            });

            // Notify operators
            const channelName = `booth-operators.${boothId}`;
            const liveStreamingModule = liveStreamModules.find(module => module._id === moduleId);
            const moduleName = liveStreamingModule?.name || 'Live Streaming';
            const boothName = liveStreamingModule?.booth?.name || 'Unnamed Booth';
            await publish({
                channel: channelName,
                message: generateNotifierMessage.newLiveStreamSpeakerRequest({
                    booth: {
                        id: boothId,
                        name: boothName,
                    },
                    module: { id: moduleId, name: moduleName },
                    action: LIVE_STREAM_SPEAKER_ACTIONS.NEW_LIVE_STREAMING_SPEAKER_REQUEST,
                }),
            });

            handleSuccess(strings.requestSuccess!);
            await refreshRequests();
            setShowModal(false);
            addForm.resetFields();
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const fieldValueChange = (changedValues: any, values: any) => {
        if (changedValues.boothModule) {
            const [boothId, moduleId] = changedValues.boothModule.split('-');
            setBoothId(boothId);
            setModuleId(moduleId);
            setUploadUrl(
                apiRequester.getFileUploadUrl({
                    clientId,
                    eventId,
                    boothId: boothId,
                    moduleId: moduleId,
                }),
            );
            setUploadEnabled(false);

            // Delete old module files
            const files = values.files;
            files &&
                files.length &&
                Promise.all(
                    files?.map((file: any) =>
                        apiRequester.deleteFile(
                            clientId,
                            file?.response?.module?.event,
                            file?.response?.module?.booth,
                            file?.response?.module?._id,
                            file?.response?._id,
                        ),
                    ),
                )
                    .then(() => addForm.setFieldsValue({ files: [] }))
                    .catch(handleError);
        }
    };

    const cancelModalAction = async () => {
        try {
            // Delete old module files
            const files = addForm.getFieldValue('files');
            files &&
                files.length &&
                Promise.all(
                    files?.map((file: any) =>
                        apiRequester.deleteFile(
                            clientId,
                            file?.response?.module?.event,
                            file?.response?.module?.booth,
                            file?.response?.module?._id,
                            file?.response?._id,
                        ),
                    ),
                )
                    .then(() => addForm.setFieldsValue({ files: [] }))
                    .catch(handleError);
            setShowModal(false);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            visible={showModal}
            onCancel={cancelModalAction}
            destroyOnClose={true}
            title={strings.newRequest}
            onOk={() => addForm.submit()}
            okText={strings.request}
            confirmLoading={loading}
        >
            <Form onFinish={submitAddForm} form={addForm} onValuesChange={fieldValueChange}>
                <Form.Item
                    label={strings.liveStream}
                    labelCol={{ span: 6 }}
                    name="boothModule"
                    key="boothModule"
                    rules={[{ required: true }]}
                >
                    <Select>
                        {liveStreamModules?.map(module => (
                            <Select.Option
                                value={`${module.booth?._id}-${module._id}`}
                                key={`${module.booth?._id}-${module._id}`}
                            >
                                <Space direction="vertical" size="small" style={{ lineHeight: 0.8 }}>
                                    <div>
                                        <Typography.Text strong>{strings.module} : </Typography.Text>
                                        {module.name || 'Live Streaming'}
                                    </div>
                                    <div>
                                        <Typography.Text strong>{strings.booth} : </Typography.Text>
                                        {module.booth?.name}
                                    </div>
                                </Space>
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={strings.startTime}
                    extra={strings.preferredStartTime}
                    labelCol={{ span: 6 }}
                    name="fromTime"
                    key="fromTime"
                    rules={[{ required: true }]}
                >
                    <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label={strings.endTime}
                    extra={strings.preferredEndTime}
                    labelCol={{ span: 6 }}
                    name="toTime"
                    key="toTime"
                    rules={[{ required: true }]}
                >
                    <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label={strings.description}
                    extra={strings.descriptionExtra}
                    labelCol={{ span: 6 }}
                    rules={[{ required: true }]}
                    name="description"
                    key="description"
                >
                    <Input.TextArea />
                </Form.Item>
                <Form.Item
                    label={strings.files}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    labelCol={{ span: 6 }}
                    name="files"
                    key="files"
                >
                    <Upload.Dragger
                        action={uploadUrl}
                        multiple
                        disabled={uploadEnabled}
                        headers={{ Authorization: 'Bearer ' + store.get('token') }}
                        onRemove={async (file: any) => {
                            boothId &&
                                moduleId &&
                                apiRequester
                                    .deleteFile(
                                        clientId,
                                        file?.response?.module?.event,
                                        file?.response?.module?.booth,
                                        file?.response?.module?._id,
                                        file?.response?._id,
                                    )
                                    .catch(handleError);
                        }}
                    >
                        <p className="ant-upload-drag-icon">
                            <UploadOutlined />
                        </p>
                        <p>{strings.uploadFile}</p>
                    </Upload.Dragger>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default NewLiveStreamSpeakRequestModal;
