import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { debounce } from 'lodash';
import Pubnub from 'pubnub';
import UIfx from 'uifx';

import notificationSound from '../../static/chat-notification.mp3';
import { ChatListContext } from '../context/ChatListContextProvider';
import { apiRequester, handleError, showNotification } from '../utility';
import { CHAT_ACTIONS, NOTIFIER_MESSAGE_IDS } from '../utility/NotifierMessages';
import { useBoothCommunication } from './useBoothCommunication';

let sound: UIfx;
if (typeof window !== 'undefined') sound = new UIfx(notificationSound);

export const useChatList = ({
    clientId,
    eventId,
    boothId,
    moduleId,
    refreshListOnLoad = true,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    refreshListOnLoad?: boolean;
}) => {
    const boothOperatorsChannel = `booth-operators.${boothId}`;
    const boothVisitorsChannel = `booth-visitors.${boothId}`;
    const boothVisitorsPresenceChannel = `booth-visitors.${boothId}-pnpres`;

    const {
        channels,
        addChannels,
        addListener,
        removeChannels,
        removeListener,
        hereNow,
        objects,
    } = useBoothCommunication();
    const chatListContext = useContext(ChatListContext);
    const { chatList, setChatList, channelMeta, setChannelMeta, onlineUserIds, setOnlineUserIds } = chatListContext;

    const chats = chatList
        .map(chat => {
            const lastVisitorMessageTime = channelMeta.find(channel => channel.id === chat._id)?.custom
                ?.lastVisitorMessageTime;
            const lastOperatorMessageTime = channelMeta.find(channel => channel.id === chat._id)?.custom
                ?.lastOperatorMessageTime;
            return {
                ...chat,
                isOnline: onlineUserIds.includes(chat.visitor._id!),
                lastVisitorMessageTime: lastVisitorMessageTime,
                lastOperatorMessageTime: lastOperatorMessageTime,
                operatorUnread:
                    lastVisitorMessageTime && !lastOperatorMessageTime
                        ? true
                        : lastVisitorMessageTime && lastOperatorMessageTime
                        ? parseInt(lastVisitorMessageTime) > parseInt(lastOperatorMessageTime)
                        : false,
            };
        })
        .sort((c1, c2) => {
            const c1LastMessageTime =
                c1.lastOperatorMessageTime && c1.lastVisitorMessageTime
                    ? parseInt(c1.lastOperatorMessageTime) > parseInt(c1.lastVisitorMessageTime)
                        ? parseInt(c1.lastOperatorMessageTime)
                        : parseInt(c1.lastVisitorMessageTime)
                    : c1.lastOperatorMessageTime
                    ? parseInt(c1.lastOperatorMessageTime)
                    : c1.lastVisitorMessageTime
                    ? parseInt(c1.lastVisitorMessageTime)
                    : 0;

            const c2LastMessageTime =
                c2.lastOperatorMessageTime && c2.lastVisitorMessageTime
                    ? parseInt(c2.lastOperatorMessageTime) > parseInt(c2.lastVisitorMessageTime)
                        ? parseInt(c2.lastOperatorMessageTime)
                        : parseInt(c2.lastVisitorMessageTime)
                    : c2.lastOperatorMessageTime
                    ? parseInt(c2.lastOperatorMessageTime)
                    : c2.lastVisitorMessageTime
                    ? parseInt(c2.lastVisitorMessageTime)
                    : 0;

            return c2LastMessageTime - c1LastMessageTime;
        });

    const refreshOnlineVisitorsList = async () => {
        console.info('Refreshing chat online user list');
        if (hereNow) {
            const response = await hereNow({ channels: [boothVisitorsChannel] });
            const channel = response?.channels[boothVisitorsChannel];
            const occupants = channel?.occupants;
            const onlineUserIds = occupants?.map(occupant => occupant.uuid);
            console.info('Online user ids', onlineUserIds);
            setOnlineUserIds(onlineUserIds);
        }
    };

    const presenceListener = (event: Pubnub.PresenceEvent) => {
        const { channel } = event;
        if (channel === boothVisitorsChannel) refreshOnlineVisitorsList();
    };

    const refreshChannelMeta = async (channelId?: string, allChats?: Modules.Chat[]) => {
        // Break channel IDs into chunks
        const channelChunks: string[][] = [];
        if (channelId) {
            console.log(`Refreshing channel meta-data for channel id ${channelId}`);
            channelChunks.push([channelId]);
        } else {
            console.log(`Refreshing channel meta-data for all`);
            const channelIds = (allChats ? allChats : chatList).map(chat => chat._id);
            const channelsInAChunkCount = 100;
            for (let i = 0; i < channelIds.length; i += channelsInAChunkCount) {
                const channelChunk = channelIds.slice(i, i + channelsInAChunkCount);
                channelChunks.push(channelChunk);
            }
        }

        // Create groups of chunks to make request in parallel
        const parallelRequestCount = 3;
        const requestGroups: string[][][] = [];
        for (let i = 0; i < channelChunks.length; i += parallelRequestCount) {
            const channelGroup = channelChunks.slice(i, i + parallelRequestCount);
            requestGroups.push(channelGroup);
        }

        const groupResponses = await Promise.all(
            requestGroups.map(channelChunks =>
                Promise.all(
                    channelChunks.map(async channelChunk => {
                        const channelFilter = channelChunk.map(cid => `id == "${cid}"`).join(' || ');
                        const channelMetaResponse = await objects?.getAllChannelMetadata<{
                            lastVisitorMessageTime: string;
                            lastOperatorMessageTime: string;
                        }>({
                            filter: channelFilter,
                            include: {
                                customFields: true,
                            },
                        });
                        const data = channelMetaResponse?.data || [];
                        return data;
                    }),
                ),
            ),
        );

        const channelMetaData = groupResponses.flat(3);
        if (channelId && channelMetaData.length) {
            setChannelMeta(oldMeta => {
                const existingRecordOfNewRecord = oldMeta.find(meta => meta.id === channelId);
                if (existingRecordOfNewRecord) {
                    const indexOfOldMeta = oldMeta.indexOf(existingRecordOfNewRecord);
                    oldMeta[indexOfOldMeta] = channelMetaData[0];
                }
                return [...oldMeta];
            });
        } else setChannelMeta(channelMetaData || []);
    };

    const refreshChatList = async (params?: { visitorId?: string }) => {
        const visitorChat = chats.find(chat => chat.visitor._id === params?.visitorId);
        if (!visitorChat) {
            console.info('Refreshing chat list for all');
            const chats = await apiRequester.getWebChats(clientId, eventId, boothId, moduleId);
            setChatList(chats);
            refreshChannelMeta(undefined, chats);
        } else {
            console.log(`Refreshing chat for visitor id: ${params?.visitorId}`);
            const chatId = visitorChat._id;
            refreshChannelMeta(chatId);
        }
    };

    const debouncedRefreshChatList = useCallback(
        debounce((visitorId: string) => refreshChatList({ visitorId: visitorId }), 5000),
        [clientId, eventId, boothId, moduleId, chatList, channelMeta, hereNow, onlineUserIds],
    );

    const messageListener = (messageEvent: Pubnub.MessageEvent) => {
        const { message, channel } = messageEvent;
        const { id } = message;
        if (id) console.log({ module: 'chat-module', id: id });
        if (channel === boothOperatorsChannel) {
            switch (id) {
                case NOTIFIER_MESSAGE_IDS.CHAT:
                    const visitorId = message?.sender?.id || message?.visitor?.id;
                    debouncedRefreshChatList(visitorId);
                    break;
                default:
                    break;
            }
        }
    };

    const listeners = { message: messageListener, presence: presenceListener };

    useEffect(() => {
        if (clientId && eventId && boothId && moduleId && refreshListOnLoad) {
            Promise.all([refreshChatList(), refreshOnlineVisitorsList()]).catch(handleError);
        }
    }, [clientId, eventId, boothId, moduleId, refreshListOnLoad]);

    useEffect(() => {
        if (clientId && eventId && boothId && moduleId && refreshListOnLoad) {
            console.log(`Entering chat module ${moduleId}`);
            addChannels([boothOperatorsChannel, boothVisitorsPresenceChannel]);
        }
    }, [clientId, eventId, boothId, moduleId]);

    useEffect(() => {
        if (clientId && eventId && boothId && moduleId && refreshListOnLoad) {
            addListener && addListener(listeners);
            return () => removeListener && removeListener(listeners)! && console.log(`Exiting chat module ${moduleId}`);
        }
    }, [clientId, eventId, boothId, moduleId, chatList, channelMeta, hereNow, onlineUserIds]);

    return {
        chats,
        refreshChatList,
        onlineUserIds,
        refreshOnlineVisitorsList,
        refreshChannelMeta,
    };
};
