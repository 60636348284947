import React, { useContext, useEffect, useState } from 'react';

import {
    DeleteOutlined,
    DownCircleFilled,
    DownloadOutlined,
    MenuOutlined,
    PlusOutlined,
    SaveOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons';
import { Button, Space, Input, Popover, Select, Form, List, Typography } from 'antd';
import download from 'downloadjs';
import { Parser } from 'json2csv';
import SortableTree, {
    TreeItem,
    changeNodeAtPath,
    addNodeUnderParent,
    removeNodeAtPath,
    getFlatDataFromTree,
    getTreeFromFlatData,
} from 'react-sortable-tree';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess } from '../utility';

/* eslint-disable react/prop-types */

export const BoothTree = ({ clientId, eventId }: { clientId: string; eventId: string }) => {
    const [treeData, setTreeData] = useState<TreeItem[]>([]);
    const [loading, setLoading] = useState(false);
    const context = useContext(GlobalContext);
    const booths = context.booths;

    const getNodeKey = ({ treeIndex }: any) => treeIndex;

    const getBoothName = (boothId: string) => {
        const booth = booths.find((booth: any) => booth._id === boothId);
        return booth ? (
            <Typography.Text>{booth.name}</Typography.Text>
        ) : (
            <Typography.Text>Unnamed Booth - {boothId}</Typography.Text>
        );
    };

    const getBoothNameString = (boothId: string) => {
        const booth = booths.find((booth: any) => booth._id === boothId);
        return booth ? booth.name : `Unnamed Booth - ${boothId}`;
    };

    const refreshTreeData = async () => {
        try {
            const event = await apiRequester.getEvent(clientId, eventId);
            const boothTree = event.boothTree;
            const treeData = getTreeFromFlatData({
                flatData: (boothTree || []).map((node: TreeItem) => ({ ...node, title: node.name, expanded: true })),
                getKey: (node: TreeItem) => node.id,
                getParentKey: (node: TreeItem) => node.parent,
                rootKey: -1,
            });
            setTreeData(treeData);
        } catch (err) {
            handleError(err);
        }
    };

    useEffect(() => {
        refreshTreeData();
    }, []);

    const saveData = async () => {
        try {
            setLoading(true);
            const flatTreeData = getFlatDataFromTree({
                treeData,
                getNodeKey: ({ node, treeIndex }) => treeIndex,
                ignoreCollapsed: false,
            }).map(({ node, path }, treeIndex) => ({
                id: treeIndex,
                name: node.name,
                parent: path.length > 1 ? path[path.length - 2] : -1,
                boothIds: node.boothIds,
            }));
            await apiRequester.editEvent(clientId, eventId, { boothTree: flatTreeData });
            handleSuccess('Booth tree has been saved successfully');
            await refreshTreeData();
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const AddBooth = ({ path, node }: { path: (string | number)[]; node: TreeItem }) => {
        const [form] = Form.useForm();

        const addBooth = (values: { [key: string]: string }) => {
            const boothId = values.boothId;
            setTreeData(treeData => {
                return changeNodeAtPath({
                    treeData: treeData,
                    path,
                    getNodeKey,
                    newNode: {
                        ...node,
                        boothIds: Array.isArray(node.boothIds)
                            ? [...new Set([...node.boothIds, boothId])]
                            : [...new Set([boothId])],
                    },
                });
            });
            form.resetFields();
        };

        const removeBooth = (boothId: string) => {
            setTreeData(treeData => {
                return changeNodeAtPath({
                    treeData: treeData,
                    path,
                    getNodeKey,
                    newNode: {
                        ...node,
                        boothIds: Array.isArray(node.boothIds)
                            ? node.boothIds.filter((id: string) => id !== boothId)
                            : [],
                    },
                });
            });
        };

        return (
            <Space direction="vertical">
                <Form form={form} onFinish={addBooth} style={{ display: 'flex', alignItems: 'center' }}>
                    <Form.Item name="boothId" style={{ marginBottom: 0 }}>
                        <Select size="small" style={{ width: '15rem' }}>
                            {booths.map((booth: any) => (
                                <Select.Option key={booth._id} value={booth._id}>
                                    {booth.name || `Unnamed Booth - ${booth._id}`}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Button type="primary" size="small" onClick={form.submit} style={{ marginLeft: '1rem' }}>
                        Add
                    </Button>
                </Form>
                <List
                    header={<Typography.Text strong>Added Booths</Typography.Text>}
                    dataSource={node.boothIds}
                    renderItem={boothId => (
                        <List.Item
                            extra={
                                <Button type="link" onClick={() => removeBooth(boothId as string)} danger>
                                    Remove
                                </Button>
                            }
                        >
                            {getBoothName(boothId as string)}
                        </List.Item>
                    )}
                />
            </Space>
        );
    };

    const getBoothParents = (
        arr: {
            id: number;
            name: any;
            parent: number | string;
            boothIds: any;
        }[],
        parentId: number | string,
    ): string[] => {
        const node = arr[parentId as number];
        const name = node.name;
        const newParentId = node.parent;
        if (newParentId === -1) {
            return [name];
        } else {
            return [...getBoothParents(arr, newParentId), name];
        }
    };

    const getBoothRows = (arr: { id: number; name: any; parent: number | string; boothIds: any }[]) => {
        const nodeCount = arr.length;
        const result = [];
        for (let i = 0; i < nodeCount; i++) {
            const node = arr[i];
            const boothIds = node.boothIds;
            const name = node.name;
            const parentId = node.parent;
            if (boothIds && boothIds.length) {
                result.push({
                    booths: boothIds.map((boothId: string) => getBoothNameString(boothId)),
                    parents: [...getBoothParents(arr, parentId), name],
                });
            }
        }
        return result;
    };

    const downloadBoothTree = () => {
        try {
            setLoading(true);
            const flatTreeData = getFlatDataFromTree({
                treeData,
                getNodeKey: ({ node, treeIndex }) => treeIndex,
                ignoreCollapsed: false,
            }).map(({ node, path }, treeIndex) => ({
                id: treeIndex,
                name: node.name,
                parent: path.length > 1 ? path[path.length - 2] : -1,
                boothIds: node.boothIds,
            }));
            const boothRows = getBoothRows(flatTreeData);
            const parentLength = boothRows.map(row => row.parents.length);
            const maxParentLength = Math.max(...parentLength);
            const fields = ['Category'];
            for (let i = 1; i < maxParentLength; i++) {
                fields.push(`Sub-${fields[i - 1]}`);
            }
            fields.push('Booths');
            const opts = { fields };
            const parser = new Parser(opts);
            const csv = parser.parse(
                boothRows.map(boothRow => {
                    const { booths, parents } = boothRow;
                    const result: { [key: string]: string } = { Category: parents[0] };
                    const fields = ['Category'];
                    for (let j = 1; j < parents.length; j++) {
                        result[`Sub-${fields[j - 1]}`] = parents[j];
                        fields.push(`Sub-${fields[j - 1]}`);
                    }
                    result['Booths'] = booths.join(', ');
                    return result;
                }),
            );
            download(csv, `booth-tree.csv`, 'text/csv');
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ flexGrow: 1, height: '75vh' }}>
            <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
                <Button
                    icon={<PlusOutlined />}
                    type="dashed"
                    onClick={() =>
                        setTreeData(treeData => {
                            return treeData.concat({
                                title: '',
                                boothIds: [],
                            });
                        })
                    }
                >
                    Add Category
                </Button>
                <Button type="dashed" loading={loading} icon={<DownloadOutlined />} onClick={downloadBoothTree} />
                <Button icon={<SaveOutlined />} type="primary" onClick={saveData} loading={loading}>
                    Save Tree
                </Button>
            </Space>

            <SortableTree
                treeData={treeData}
                onChange={treeData => setTreeData(treeData)}
                generateNodeProps={({ node, path }: { node: TreeItem; path: any[] }) => ({
                    title: (
                        <Input
                            size="small"
                            /* eslint-disable react/prop-types */
                            value={node.name}
                            onChange={event => {
                                const name = event.target.value;
                                setTreeData(treeData => {
                                    return changeNodeAtPath({
                                        treeData: treeData,
                                        path,
                                        getNodeKey,
                                        newNode: { ...node, name },
                                    });
                                });
                            }}
                        />
                    ),
                    buttons: [
                        <Space key="action-buttons">
                            {/* eslint-disable react/prop-types */}
                            {path.length <= 2 && (
                                <Button
                                    onClick={() =>
                                        setTreeData(treeData => {
                                            return addNodeUnderParent({
                                                treeData: treeData,
                                                /* eslint-disable react/prop-types */
                                                parentKey: path[path.length - 1],
                                                expandParent: true,
                                                getNodeKey,
                                                newNode: {
                                                    title: ``,
                                                },
                                                addAsFirstChild: false,
                                            }).treeData;
                                        })
                                    }
                                    size="small"
                                    icon={<PlusOutlined />}
                                    type="ghost"
                                >
                                    Category
                                </Button>
                            )}

                            <Button
                                onClick={() =>
                                    setTreeData(treeData => {
                                        return removeNodeAtPath({
                                            treeData: treeData,
                                            path,
                                            getNodeKey,
                                        });
                                    })
                                }
                                danger
                                size="small"
                                icon={<DeleteOutlined />}
                                shape="circle"
                                type="ghost"
                            />

                            <Popover content={<AddBooth path={path} node={node} />} trigger="click" title="Add Booth">
                                <Button icon={'B'} type="primary" shape="circle" size="small" />
                            </Popover>
                        </Space>,
                    ],
                })}
            />
        </div>
    );
};

/* eslint-enable react/prop-types */

export default BoothTree;
