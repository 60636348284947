import React, { EventHandler, useEffect, useState } from 'react';

import {
    AppstoreOutlined,
    DownloadOutlined,
    MailOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    RightOutlined,
    SettingOutlined,
    UpOutlined,
} from '@ant-design/icons';
import {
    Button,
    Card,
    Col,
    Descriptions,
    Drawer,
    Form,
    Input,
    Row,
    Select,
    Space,
    Table,
    Typography,
    Menu,
    Divider,
} from 'antd';
import download from 'downloadjs';
import { Parser } from 'json2csv';
import moment from 'moment';

import { apiRequester, handleError, handleSuccess } from '../utility';

const { Option } = Select;
const { SubMenu } = Menu;

export const SiemensFormsModule = ({
    clientId,
    eventId,
    moduleId,
}: {
    clientId: string;
    eventId: string;
    moduleId: string;
}) => {
    const [loading, setLoading] = useState(false);
    const [forms, setForms] = useState<Modules.ExtFormModuleResponse[]>([]);
    const [initialQuestions, setInitialQuestions] = useState<{ topics: Modules.SiemensFormQuestion[] }>({ topics: [] });
    const [selectedMenuItem, setSelectedMenuItem] = useState<string>('responses');
    const [form] = Form.useForm();

    const refreshResponses = async () => {
        try {
            setLoading(true);
            const responses = await apiRequester.getExtFormModulesResponses({ clientId, eventId, moduleId });
            setForms(responses);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const getSiemensQuestions = async () => {
        try {
            const questions = await apiRequester.getSiemensFormQuestions({ clientId, eventId, moduleId });
            if (questions) setInitialQuestions({ topics: questions });
        } catch (err) {
            handleError(err);
        }
    };

    useEffect(() => {
        refreshResponses();
        getSiemensQuestions();
    }, [moduleId]);

    const Responses = () => (
        <>
            {forms.map(form => (
                <Card
                    style={{ width: '100%', marginTop: '1.5rem' }}
                    key={form._id}
                    title={`Response ID: ${form._id}`}
                    size="small"
                >
                    <Descriptions bordered>
                        <Descriptions.Item label="Name">
                            {form.user?.firstName} {form.user?.lastName}
                        </Descriptions.Item>
                        <Descriptions.Item label="Email">{form.user?.emailId}</Descriptions.Item>
                        <Descriptions.Item label="Filled At">
                            {moment(form.createdAt).format('MMMM Do YYYY, h:mm a')}
                        </Descriptions.Item>
                    </Descriptions>
                    <div style={{ marginTop: '1.5rem' }}>
                        {form.responses?.map(response => (
                            <Row gutter={[24, 12]} key={response._id}>
                                <Col xs={24}>
                                    <Typography.Text strong>{response.question}</Typography.Text>
                                </Col>
                                <Col xs={24}>
                                    {response.answer_arr && response.answer_arr.length ? (
                                        <ul>
                                            {response.answer_arr.map((ans: string) => (
                                                <li key={`${response.questionId}-index`}>{ans}</li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <></>
                                    )}
                                    {response.answer_obj && (
                                        <ul>
                                            {Object.keys(response.answer_obj).map((key: string, index: number) => (
                                                <li key={`${response.questionId}-index`}>
                                                    {key}: {response.answer_obj![key]}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </Col>
                            </Row>
                        ))}
                    </div>
                </Card>
            ))}
        </>
    );

    const selectMenuItem = (key: string | number) => {
        setSelectedMenuItem(key.toString());
    };

    const onSave = async () => {
        try {
            setLoading(true);
            const formData = form.getFieldsValue();
            const topics = formData.topics || [];
            await apiRequester.saveSiemensFormQuestions({ clientId, eventId, moduleId, questions: topics });
            handleSuccess('Questions have been saved');
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const Questions = () => {
        return (
            <Form form={form} onFinish={onSave} initialValues={initialQuestions}>
                <Form.List name="topics">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, fieldKey, ...restField }) => (
                                <Card key={key} style={{ marginTop: '1.5rem' }} size="small">
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Space style={{ display: 'flex', width: '32%' }} align="baseline">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'topic']}
                                                fieldKey={[fieldKey, 'topic']}
                                                extra="Topic"
                                                rules={[{ required: true, message: 'Missing topic' }]}
                                            >
                                                <Input placeholder="Enter Topic" />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
                                            <Form.List name={[name, 'subTopics']}>
                                                {(fields, { add, remove }) => (
                                                    <>
                                                        {fields.map(({ key, name, fieldKey, ...restSubTopicField }) => (
                                                            <Space
                                                                key={key}
                                                                style={{ display: 'flex' }}
                                                                align="baseline"
                                                            >
                                                                <Form.Item
                                                                    {...restSubTopicField}
                                                                    name={[name]}
                                                                    fieldKey={[fieldKey, 'subTopic']}
                                                                    extra="Sub Topic"
                                                                >
                                                                    <Input placeholder="Enter Sub Topic" />
                                                                </Form.Item>
                                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                                            </Space>
                                                        ))}
                                                        <Form.Item>
                                                            <Button
                                                                type="link"
                                                                onClick={() => add()}
                                                                icon={<PlusOutlined />}
                                                            >
                                                                Add sub topic
                                                            </Button>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
                                            <Form.List name={[name, 'requirements']}>
                                                {(fields, { add, remove }) => (
                                                    <>
                                                        {fields.map(({ key, name, fieldKey, ...restSubTopicField }) => (
                                                            <Space
                                                                key={key}
                                                                style={{ display: 'flex' }}
                                                                align="baseline"
                                                            >
                                                                <Form.Item
                                                                    {...restSubTopicField}
                                                                    name={[name]}
                                                                    fieldKey={[fieldKey, 'requirements']}
                                                                    extra="Requirement"
                                                                >
                                                                    <Input placeholder="Enter requirement" />
                                                                </Form.Item>
                                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                                            </Space>
                                                        ))}
                                                        <Form.Item>
                                                            <Button
                                                                type="link"
                                                                onClick={() => add()}
                                                                icon={<PlusOutlined />}
                                                            >
                                                                Add requirements
                                                            </Button>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                        </div>
                                    </div>
                                </Card>
                            ))}
                            <Form.Item style={{ marginTop: '1.5rem' }}>
                                <Space>
                                    <Button type="primary" htmlType="submit" loading={loading}>
                                        Save
                                    </Button>
                                    <Button type="link" onClick={() => add()} icon={<PlusOutlined />}>
                                        Add Topic
                                    </Button>
                                </Space>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
        );
    };

    const downloadAllResponses = () => {
        try {
            const fields = ['Time', 'User ID', 'User First Name', 'User Last Name', 'User Email', 'Topic', 'Comments'];
            const opts = { fields };
            const parser = new Parser(opts);
            const csv = parser.parse(
                forms
                    .map(form => {
                        return form.responses?.map(response => {
                            return {
                                Time: moment(form.createdAt).toString(),
                                'User ID': typeof form.user === 'string' ? form.user : form.user?._id! || '',
                                'User First Name':
                                    typeof form.user === 'string' ? form.user : form.user?.firstName || '',
                                'User Last Name': typeof form.user === 'string' ? form.user : form.user?.lastName || '',
                                'User Email': typeof form.user === 'string' ? form.user : form.user?.emailId || '',
                                Topic: response?.answer_obj?.topic,
                                Comments: response?.answer_obj?.comments,
                            };
                        });
                    })
                    .flat(),
            );
            download(csv, `siemens-form-response.csv`, 'text/csv');
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Menu
                    onClick={e => selectMenuItem(e.key)}
                    mode="horizontal"
                    selectedKeys={[selectedMenuItem]}
                    style={{ background: 'transparent', flexGrow: 1 }}
                >
                    <Menu.Item key="responses" icon={<MailOutlined />}>
                        Responses
                    </Menu.Item>
                    <Menu.Item key="questions" icon={<AppstoreOutlined />}>
                        Questions
                    </Menu.Item>
                </Menu>
                {selectedMenuItem === 'responses' && (
                    <Button type="default" icon={<DownloadOutlined />} onClick={downloadAllResponses}>
                        Download
                    </Button>
                )}
            </div>
            {selectedMenuItem === 'questions' && <Questions />}
            {selectedMenuItem === 'responses' && <Responses />}
        </>
    );
};

export default SiemensFormsModule;
